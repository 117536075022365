import { Map } from 'immutable';
import { handleSuccess, mapForPayload } from '../lib/reduxUtils';
import { parseAssetToRound } from '../parsers/assets';
import {
	FETCH_ASSET_ROUNDS, SET_SELECTED_ASSET_ROUND_ID, CLEAR_SELECTED_ASSET_ROUND_ID,
	FETCH_PROJECT_ASSETS, FETCH_ASSET, CREATE_ASSET_FROM_DIRECT_UPLOAD,
	CREATE_ROUND_FROM_DIRECT_UPLOAD, DELETE_ASSET, DELETE_ASSETS,
	FETCH_SHARED_PROJECT, FETCH_PROJECT_ASSET_ROUNDS, DELETE_CURRENT_ROUND,
	SET_USER_IS_COMPARING_ROUNDS, RESET_USER_IS_COMPARING_ROUNDS, FETCH_FOLDER_ASSETS,
	FETCH_SHARED_FOLDER, DELETE_ASSETS_LAST_ROUND
} from '../actions/types';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';

export const assetRounds = ( state = new Map(), action ) => {
	switch ( action.type ) {
	case FETCH_ASSET_ROUNDS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { assetID } = action.meta;
				const roundsWithAssetID = action.payload
					.map( round => ( {
						...round,
						asset_id: assetID
					} ) );
				return prevState
					.replaceMatchingWithPayload( roundsWithAssetID, assetID, 'asset_id' );
			}
		);
	case FETCH_PROJECT_ASSET_ROUNDS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload
				.reduce(
					( res, assetRound ) => res.set( assetRound.id, assetRound ),
					prevState
				)
		);
	case FETCH_PROJECT_ASSETS:
	case FETCH_FOLDER_ASSETS:
		return handleSuccess(
			state,
			action,
			prevState => prevState
				.merge( mapForPayload( action.payload.response.map( parseAssetToRound ) ) )
		);
	case FETCH_ASSET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const assetRound = parseAssetToRound( action.payload );
				const prevAssetRound = prevState.get( assetRound.id, {} );

				return prevState.set( assetRound.id, { ...prevAssetRound, ...assetRound } );
			}
		);
	case FETCH_SHARED_PROJECT:
	case FETCH_SHARED_FOLDER:
		return handleSuccess(
			state,
			action,
			prevState => prevState
				.merge( mapForPayload( action.payload.assets.map( parseAssetToRound ) ) )
		);
	case CREATE_ASSET_FROM_DIRECT_UPLOAD:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				if ( !action.payload.matchesFilter ) return prevState;
				const round = parseAssetToRound( action.payload.asset );
				return prevState.set( round.id, round );
			}
		);
	case CREATE_ROUND_FROM_DIRECT_UPLOAD:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const round = action.payload;
				return prevState.set( round.id, round );
			}
		);
	case DELETE_ASSET:
		return handleSuccess(
			state,
			action,
			prevState => prevState.filter(
				round => round.asset_id !== action.payload
			)
		);
	case DELETE_ASSETS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.filter(
					round => round.asset_id !== deletedID
				),
				prevState
			)
		);
	case DELETE_CURRENT_ROUND:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const currentRoundID = action.payload;
				return prevState.delete( currentRoundID );
			}
		);
	case DELETE_ASSETS_LAST_ROUND:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const currentRoundIDs = action.payload.map( asset => asset.currentRoundID );
				return prevState.filter(
					round => !currentRoundIDs.includes( round.id )
				);
			}
		);
	case UPDATER_ACTION_TYPE.assets: {
		return state
			.merge( mapForPayload( action.payload.map( parseAssetToRound ) ) )
	}
	case UPDATER_ACTION_TYPE.createOrUpdateAsset: {
		return state
			.merge( mapForPayload( [ parseAssetToRound( action.payload ) ] ) )
	}
	default:
		return state;
	}
};

export const selectedAssetRoundID = ( state = null, action ) => {
	switch ( action.type ) {
	case SET_SELECTED_ASSET_ROUND_ID:
		return action.payload;
	case CLEAR_SELECTED_ASSET_ROUND_ID:
		return null;
	case CREATE_ROUND_FROM_DIRECT_UPLOAD:
		return handleSuccess( state, action, () => action.payload.id );
	case DELETE_CURRENT_ROUND:
		return handleSuccess( state, action, () => action.meta.prevAssetRound.id );
	default:
		return state;
	}
};

export const userIsViewingPreviousRound = ( state = false, action ) => {
	switch ( action.type ) {
	case SET_USER_IS_COMPARING_ROUNDS:
		return true;
	case RESET_USER_IS_COMPARING_ROUNDS:
		return false;
	default:
		return state;
	}
};
