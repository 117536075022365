import PropTypes from 'prop-types';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import { connectComponent } from '../../lib/connectComponent';
import Workspace from '../../entities/workspace';
import { getWorkspace } from '../../selectors/workspaces';
import AsyncApiActionPropType, { defaultProp as defaultAsyncApiAction } from '../../types/asyncApiAction';

import EditWorkspace from '../../components/EditWorkspace/EditWorkspace';
import Container from '../Container';
import { actionResultIsError } from '../../lib/reduxUtils';
import { isNotAllowedOrNotFound } from '../../errors/common';

export class EditWorkspaceContainer extends Container {
	constructor( props ) {
		super( props );

		this._onUpdateWorkspaceClicked = this._onUpdateWorkspaceClicked.bind( this );
	}

	_onUpdateWorkspaceClicked( data ) {
		const { workspaceID, editWorkspace } = this.props;
		const { showSuccessMessage, showErrorMessage } = SnackbarSystem.shared();
		const successMessage = { title: 'Success! Your changes have been saved!' };
		const errorMessage = { title: 'Oops, something went wrong! Try again.' };

		return editWorkspace( workspaceID, data )
			.then( ( result ) => {
				if ( actionResultIsError( result ) ) {
					throw result.payload;
				}

				showSuccessMessage( successMessage );
			} ).catch( error => !isNotAllowedOrNotFound( error ) && showErrorMessage( errorMessage ) );
	}

	render() {
		const { readOnly, workspace, editWorkspaceRequest } = this.props;

		return (
			<EditWorkspace
				initialLogo={workspace.logo}
				initialName={workspace.name}
				onUpdateClick={this._onUpdateWorkspaceClicked}
				updating={editWorkspaceRequest.sending}
				readOnly={readOnly}
			/>
		);
	}
}

EditWorkspaceContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	workspace: PropTypes.instanceOf( Workspace ).isRequired,
	editWorkspaceRequest: AsyncApiActionPropType,
	editWorkspace: PropTypes.func,
	readOnly: PropTypes.bool
};

EditWorkspaceContainer.defaultProps = {
	editWorkspaceRequest: defaultAsyncApiAction,
	editWorkspace: () => {},
	readOnly: true
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	editWorkspaceRequest: state.editWorkspaceRequest
} ) )( EditWorkspaceContainer );
