import uuid from 'uuid/v1';
import AssetFilters from 'src/new_arch/modules/projectView/models/filtersAndSorts/assetFilters';
import {
	CHANGE_ASSET_FILE_NAME,
	CREATE_ASSET_FROM_DIRECT_UPLOAD,
	CREATE_ROUND_FROM_DIRECT_UPLOAD,
	DELETE_ASSET,
	DELETE_ASSETS,
	DOWNLOAD_ASSET,
	DOWNLOAD_ASSETS,
	FETCH_ASSET,
	FETCH_FOLDER_ASSETS,
	FETCH_PROJECT_ASSETS,
	MARK_ASSETS_AS_APPROVED,
	RESET_DELETE_ASSET,
	RESET_MARK_ASSETS_AS_APPROVED,
	RESET_UNMARK_ASSETS_AS_APPROVED,
	SAVE_ASSETS_PAGINATION_DATA,
	SET_ASSET_RUSH_PRIORITY,
	UNMARK_ASSETS_AS_APPROVED,
	UNSET_ASSET_RUSH_PRIORITY,
	UPDATE_ASSET_LAST_SEEN,
	CLEAR_ASSETS,
	DELETE_ASSETS_LAST_ROUND
} from '../actions/types';
import {
	ASSETS_URL, FOLDERS_URL, PROJECTS_URL
} from '../config/urls';
import { AssetsDownload } from '../entities/assetsDownload';
import { extractAssetsIDsWithApproval } from '../lib/changeAssetsMapApproval';
import toggleItemRushPriority from '../lib/toggleItemRushPriority';
import { createURLWithParams } from '../lib/urlUtils';
import { parseAsset, parseAssets } from '../parsers/assets';
import { getAsset, getAssets } from '../selectors/assets';
import { getProject } from '../selectors/projects';
import events from '../services/tracker/events';
import { trackAssetDeletion, trackDownloadedAnAsset, trackDownloadedMultipleAssets } from '../services/tracker/trackers/assets';
import { getSelectedFiltersForProject } from '../selectors/assetsFilterAndSortOptions';
import Asset from '../entities/asset';
import defaultAssetDownloadService from '../services/AssetDownloadService';
import { getPreviousAssetRoundFromSelectedAssetRound } from '../selectors/assetRounds';
import QueryClientUpdater from '../new_arch/services/QueryClientUpdater';

export default class AssetSystem {
	constructor( dispatch, assetDownloadService = defaultAssetDownloadService ) {
		this.dispatch = dispatch;
		this.assetDownloadService = assetDownloadService;
	}

	fetchAssetsForProject = ( {
		project, paginationParams = {}, selectedSortOption, sortDirection, selectedFilters, deep
	} ) => {
		const projectID = project.id;
		return this._fetchOrganizerAssets( {
			actionType: FETCH_PROJECT_ASSETS,
			organizersURL: PROJECTS_URL,
			organizerID: projectID,
			meta: { projectID, page: paginationParams.page },
			paginationParams,
			parsePayload: payload => ( {
				...payload,
				response: parseAssets( payload.response, projectID )
			} ),
			selectedSortOption,
			sortDirection,
			selectedFilters,
			deep
		} );
	}

	fetchAssetsForFolder = ( {
		folder, paginationParams = {},
		selectedSortOption, sortDirection, selectedFilters, deep
	} ) => {
		const { projectID } = folder;
		const folderID = folder.id;
		return this._fetchOrganizerAssets( {
			actionType: FETCH_FOLDER_ASSETS,
			organizersURL: FOLDERS_URL,
			organizerID: folderID,
			meta: { projectID, folderID },
			paginationParams,
			parsePayload: payload => ( {
				...payload,
				response: parseAssets( payload.response, projectID )
			} ),
			selectedSortOption,
			sortDirection,
			selectedFilters,
			deep
		} );
	}

	markAssetsAsApproved( projectID, assetsIDs ) {
		return this._toggleAssetsApprovalStatus( projectID, assetsIDs, true );
	}

	unmarkAssetsAsApproved( projectID, assetsIDs ) {
		return this._toggleAssetsApprovalStatus( projectID, assetsIDs, false );
	}

	resetMarkAssetsAsApproved() {
		return this.dispatch( ( {
			type: RESET_MARK_ASSETS_AS_APPROVED
		} ) );
	}

	resetUnmarkAssetsAsApproved() {
		return this.dispatch( ( {
			type: RESET_UNMARK_ASSETS_AS_APPROVED
		} ) );
	}

	fetchAsset( assetID, projectID ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: FETCH_ASSET,
			promise: api
				.get( `${ASSETS_URL}/${assetID}` )
				.then( response => parseAsset( response.response, projectID ) ),
			meta: { assetID }
		} ) );
	}

	deleteAsset = asset => this.dispatch( ( dispatch, _, api ) => {
		trackAssetDeletion( 1 );

		return dispatch( {
			type: DELETE_ASSET,
			promise: api
				.delete( `${PROJECTS_URL}/${asset.projectID}/assets`, { ids: [ asset.id ] } )
				.then( () => asset.id )
		} );
	} )

	deleteAssets( { projectID, assetIDs } ) {
		return this.dispatch( ( dispatch, _, api ) => {
			trackAssetDeletion( assetIDs.length );

			return dispatch( {
				type: DELETE_ASSETS,
				promise: api
					.delete( `${PROJECTS_URL}/${projectID}/assets`, { ids: assetIDs } )
					.then( () => {
						QueryClientUpdater.assetsWereDeleted( { assetIDs, projectID } );
						return assetIDs;
					} )
			} );
		} );
	}

	createAssetFromDirectUpload( uploadID, signedID, projectID, folderID, parentUploadsData ) {
		return this.dispatch( (	dispatch, getState, api ) => {
			const filters = getSelectedFiltersForProject( getState(), { projectID } );
			return dispatch( {
				type: CREATE_ASSET_FROM_DIRECT_UPLOAD,
				meta: { upload_id: uploadID },
				promise: api
					.post( ASSETS_URL, { file: signedID, project_id: projectID, folder_id: folderID } )
					.then( ( response ) => {
						QueryClientUpdater.createdOrUpdatedAsset( {
							assetJson: { ...response.response, project_id: projectID },
							projectID,
							parentUploadsData
						} );

						const parsedAsset = parseAsset( response.response, projectID, folderID );
						return {
							matchesFilter: filters.match( Asset.fromJSON( parsedAsset ) ),
							asset: parsedAsset
						};
					} )
			} );
		} );
	}

	createRoundFromDirectUpload( uploadID, signedID, assetID, projectID, folderID ) {
		return this.dispatch( (	dispatch, _, api ) => dispatch( {
			type: CREATE_ROUND_FROM_DIRECT_UPLOAD,
			meta: { upload_id: uploadID },
			promise: api
				.post( `${ASSETS_URL}/${assetID}/versions`, { file: signedID } )
				.then( ( response ) => {
					QueryClientUpdater.createdAssetRound( {
						newRoundJson: response.response,
						assetID,
						projectID,
						folderID
					} );
					return response.response;
				} )
		} ) );
	}

	changeAssetFileName( assetID, projectID, newFileName ) {
		return this.dispatch( (	dispatch, getState, api	) => {
			const originalAsset = getAsset( getState(), { assetID } );
			const originalFileName = originalAsset ? originalAsset.name : undefined;

			if ( originalFileName === newFileName ) { return Promise.resolve( undefined ); }

			return dispatch( {
				type: CHANGE_ASSET_FILE_NAME,
				promise: api
					.patch( `${ASSETS_URL}/${assetID}/update_filename`, { filename: newFileName } )
					.then( response => parseAsset( response.response, projectID ) ),
				meta: { assetID, originalFileName, newFileName }
			} );
		} );
	}

	resetDeleteAssetsRequest = () => this.dispatch( {
		type: RESET_DELETE_ASSET
	} )

	setAssetRushPriority( assetID ) {
		return this._toggleAssetRushPriority( assetID, true );
	}

	unsetAssetRushPriority( assetID ) {
		return this._toggleAssetRushPriority( assetID, false );
	}

	downloadSharedProjectAssets( {
		projectID, assetIDs, withRoundNumber, lowRes, shareRef
	} ) {
		const url = `${shareRef.apiUrl}/download`;

		return this.dispatch( ( dispatch, getState, api ) => {
			const downloadID = uuid();

			return dispatch( {
				type: DOWNLOAD_ASSETS,
				promise: api
					.post(
						url,
						{
							assets_ids: assetIDs,
							low_res: lowRes,
							with_round_number: withRoundNumber
						}
					)
					.then( ( downloadJson ) => {
						const download = AssetsDownload.handle( downloadJson, this.assetDownloadService );

						const project = getProject( getState(), { projectID } );
						if ( assetIDs && project ) trackDownloadedMultipleAssets( project, assetIDs.length );

						return download;
					} ),
				meta: { downloadID }
			} );
		} );
	}

	updateAssetLastSeen( assetID ) {
		return this.dispatch( ( dispatch, _, api ) => dispatch( {
			type: UPDATE_ASSET_LAST_SEEN,
			promise: api
				.post( `${ASSETS_URL}/${assetID}/last_seen` ),
			meta: { assetID }
		} ) );
	}

	downloadAsset( {
		asset, withRoundNumber, allRounds, lowRes
	} ) {
		trackDownloadedAnAsset( asset );
		return this.dispatch( ( dispatch, _, api ) => {
			const downloadID = uuid();
			return dispatch( {
				type: DOWNLOAD_ASSET,
				promise: api
					.post(
						asset.fileURL,
						{
							with_round_number: withRoundNumber,
							all_rounds: allRounds,
							low_res: lowRes
						}
					)
					.then( downloadJson => AssetsDownload.handle( downloadJson, this.assetDownloadService ) ),
				meta: { downloadID, isZip: allRounds }
			} );
		} );
	}

	downloadSharedProjectAsset( {
		asset, withRoundNumber, allRounds, lowRes, shareHash
	} ) {
		trackDownloadedAnAsset( asset );
		return this.dispatch( ( dispatch, _, api ) => {
			const downloadID = uuid();
			return dispatch( {
				type: DOWNLOAD_ASSET,
				promise: api
					.post(
						asset.fileURL,
						{
							with_round_number: withRoundNumber,
							all_rounds: allRounds,
							low_res: lowRes,
							share_hash: shareHash
						}
					)
					.then( downloadJson => AssetsDownload.handle( downloadJson, this.assetDownloadService ) ),
				meta: { downloadID, isZip: allRounds }
			} );
		} );
	}

	toggleApproval( { assets, approvalTracker, disapprovalTracker } ) {
		if ( assets.every( asset => asset.isApproved ) ) {
			disapprovalTracker?.();
			this.unmarkAssetsAsApproved( assets.first.projectID, assets.map( asset => asset.id ) );
		} else {
			approvalTracker?.();
			this.markAssetsAsApproved( assets.first.projectID, assets.map( asset => asset.id ) );
		}
	}

	togglePriority( asset ) {
		if ( asset.hasRushPriority ) return this.unsetAssetRushPriority( asset.id );
		return this.setAssetRushPriority( asset.id );
	}

	_fetchOrganizerAssets( {
		actionType, organizersURL, organizerID, meta, parsePayload,
		paginationParams = {}, selectedSortOption, sortDirection, selectedFilters,
		deep
	} ) {
		// In mobile, the old AssetsViewerContainer is still used, which ends up
		// calling this method (indirectly) with the old AssetFilters model from
		// src/entities, which responds to the "encode" message instead of "toJsonString".
		const encodedFilters = selectedFilters instanceof AssetFilters
			? selectedFilters.toJsonString()
			: selectedFilters?.encode( { omitDefaults: false } );
		return this.dispatch(
			( dispatch, _, api ) => dispatch( {
				type: actionType,
				promise: api
					.get( createURLWithParams(
						`${organizersURL}/${organizerID}/assets`,
						{
							...paginationParams,
							sort_by: selectedSortOption?.id,
							sort_order: sortDirection,
							filters: encodedFilters,
							deep
						}
					) )
					.then( parsePayload ),
				meta
			} )
		);
	}

	_toggleAssetsApprovalStatus( projectID, assetsID, toApprovalStatus ) {
		return this.dispatch( (	dispatch, getState, api	) => {
			const assets = getAssets( getState() );
			const assetIDsToUpdate = extractAssetsIDsWithApproval( assets, assetsID, !toApprovalStatus );
			const assetsToUpdate = assetIDsToUpdate.map( id => assets.get( id ) );

			const { type, path } = toApprovalStatus
				? { type: MARK_ASSETS_AS_APPROVED, path: 'mark_as_approved' }
				: { type: UNMARK_ASSETS_AS_APPROVED, path: 'unmark_as_approved' };

			return dispatch( {
				type,
				promise: api
					.post( `${PROJECTS_URL}/${projectID}/assets/${path}`, { ids: assetIDsToUpdate } )
					.then( () => {
						QueryClientUpdater.changedAssetsApprovalStatus( {
							assets: assetsToUpdate,
							toApproved: toApprovalStatus
						} );
						return assetIDsToUpdate;
					} )
			} );
		} );
	}

	_toggleAssetRushPriority( assetID, hasRushPriority ) {
		return this.dispatch( toggleItemRushPriority(
			getAsset,
			assetID,
			hasRushPriority,
			ASSETS_URL,
			'assetID',
			SET_ASSET_RUSH_PRIORITY,
			UNSET_ASSET_RUSH_PRIORITY,
			'trackAssetAction',
			events.assets.ASSET_FLAGGED,
			events.assets.ASSET_UNFLAGGED,
			'name'
		) );
	}

	savePaginationData = paginationData => this.dispatch( {
		type: SAVE_ASSETS_PAGINATION_DATA,
		payload: paginationData
	} )

	clearAssets = () => this.dispatch( {
		type: CLEAR_ASSETS
	} )

	deleteAssetsLastRound( projectID, assets ) {
		const assetIDs = assets.map( asset => asset.id );

		return this.dispatch( ( dispatch, getState, api ) => {
			const prevAssetRounds = assets.map(
				asset => getPreviousAssetRoundFromSelectedAssetRound( getState(), { asset } )
			);
			return dispatch( {
				type: DELETE_ASSETS_LAST_ROUND,
				promise: api
					.delete( `${PROJECTS_URL}/${projectID}/asset_versions/current`, { ids: assetIDs } )
					.then( () => {
						QueryClientUpdater.deletedAssetsLastRound( { assets } );
						return assets;
					} ),
				meta: { prevAssetRounds }
			} );
		} );
	}
}
