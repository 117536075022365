import { get, set, del, createStore, clear, keys } from 'idb-keyval';

function createIndexDBStorage( dbName: string ) {
	const store = createStore( dbName, dbName );

	return {
		setItem(
			key: string,
			value: string,
			callback?: ( error?: Error ) => void
		): Promise<void> {
			return set( key, value, store )
				.then( () => callback?.() )
				.catch( error => callback?.( error ) );
		},

		getItem(
			key: string,
			callback?: ( error?: Error, result?: string ) => void
		): Promise<string | undefined> {
			return get<string>( key, store )
				.then( ( value ) => {
					callback?.( undefined, value );
					return value;
				} )
				.catch( ( error ) => {
					callback?.( error );
					return undefined;
				} )
		},

		removeItem(
			key: string,
			callback?: ( error?: Error ) => void
		): Promise<undefined> {
			return del( key, store )
				.then( () => callback?.() )
				.catch( error => callback?.( error ) )
				.then( () => undefined );
		},

		getAllKeys(
			callback?: ( error?: Error, keys?: Array<string> ) => void
		): Promise<string[] | undefined> {
			return keys( store )
				.then( ( allKeys ) => {
					callback?.( undefined, allKeys as string[] );
					return allKeys as string[];
				} )
				.catch( ( error ) => {
					callback?.( error );
					return undefined;
				} );
		},

		clear( callback?: ( error?: Error ) => void ): Promise<boolean> {
			return clear( store )
				.then( () => { callback?.(); return true; } )
				.catch( ( error ) => { callback?.( error ); return false; } );
		}
	};
}

export default createIndexDBStorage;
