/* eslint-disable react/no-danger */
import { createElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@ui/components/Icon/Icon';

import { SECONDARY_BASE } from '../../../styles/colors';

import IconButton from '../IconButton/IconButton';
import Checkbox from '../Checkbox/Checkbox';
import Button, { types as buttonTypes } from '../../../new_arch/components/Button/Button';
import RadioGroup from '../RadioGroup/RadioGroup';
import { removeTags } from '../../../lib/textUtils';

import './Popup.scss';

const descriptionAlignments = [ 'center', 'end', 'justify', 'left', 'right', 'start' ];

const Popup = ( {
	title,
	bigTitle,
	description,
	descriptionAlignment,
	checkboxDescription,
	actions,
	headerIcon,
	headerIconColor,
	onCrossClick,
	radioOptions,
	onRadioOptionSelect,
	renderCustomPopup,
	name
} ) => {
	const hasHeaderIcon = !!headerIcon;
	const hasCheckbox = !!checkboxDescription;
	const hasRadioOptions = radioOptions && radioOptions.length > 0;

	const [ checked, setChecked ] = useState( false );

	const titleComponent = createElement( bigTitle ? 'h1' : 'h4', {}, title );

	const parseBoldTags = aDescription => (
		removeTags( aDescription ).replaceAll( '*startBold*', '<span>' ).replaceAll( '*endBold*', '</span>' )
	);

	const history = useHistory();

	useEffect( () => {
		if ( !name ) return () => {};

		const { pathname, state, search } = history.location;
		const queryParams = new URLSearchParams( search );
		queryParams.append( 'modal', name );
		history.replace( { pathname, state, search: queryParams.toString() } );

		return () => history.replace( { pathname, state, search } );
	}, [ name ] );

	return (
		<div className="Popup">
			{renderCustomPopup?.()
				|| (
					<div className={classNames( 'dialog', { bigTitle } )}>
						<IconButton
							icon="clear"
							buttonClassName="close-icon"
							onClick={onCrossClick}
							size={24}
						/>
						<div className={classNames( 'header', { withIcon: hasHeaderIcon } )}>
							{hasHeaderIcon && (
								<Icon
									className="header-icon"
									icon={headerIcon}
									size={32}
									color={headerIconColor}
								/>
							)}
							<div className="title">
								{titleComponent}
							</div>
						</div>
						{description && (
							<p
								className={classNames( 'description', descriptionAlignment, { bigTitle } )}
								dangerouslySetInnerHTML={{
									__html: parseBoldTags( description )
								}}
							/>
						)}
						{hasCheckbox && (
							<Checkbox
								label={checkboxDescription}
								onChange={setChecked}
								value={checked}
							/>
						)}
						{hasRadioOptions
							&& <RadioGroup options={radioOptions} onOptionSelect={onRadioOptionSelect} />}
						<div className={classNames( 'buttons', { bigTitle } )}>
							{
								actions
									.map( ( {
										text, color, onClick, enabledByCheckbox, buttonType
									} ) => (
										<Button
											key={text}
											type={buttonType}
											color={color}
											onClick={onClick}
											disabled={enabledByCheckbox && !checked}
										>
											{text}
										</Button>
									) )
							}
						</div>
					</div>
				)}
		</div>
	);
};

Popup.propTypes = {
	title: PropTypes.string.isRequired,
	bigTitle: PropTypes.bool,
	description: PropTypes.string,
	checkboxDescription: PropTypes.string,
	actions: PropTypes.arrayOf( PropTypes.shape( {
		text: PropTypes.string.isRequired,
		type: PropTypes.oneOf( buttonTypes ),
		onClick: PropTypes.func.isRequired,
		buttonType: PropTypes.string.isRequired
	} ) ),
	headerIcon: PropTypes.string,
	headerIconColor: PropTypes.string,
	onCrossClick: PropTypes.func.isRequired,
	descriptionAlignment: PropTypes.oneOf( descriptionAlignments ),
	radioOptions: PropTypes.arrayOf(
		{ id: PropTypes.string, title: PropTypes.string, subtitle: PropTypes.string }
	),
	onRadioOptionSelect: PropTypes.func,
	renderCustomPopup: PropTypes.func,
	name: PropTypes.string
};

Popup.defaultProps = {
	bigTitle: false,
	description: undefined,
	checkboxDescription: undefined,
	actions: [],
	headerIcon: undefined,
	headerIconColor: SECONDARY_BASE,
	descriptionAlignment: 'center',
	radioOptions: [],
	onRadioOptionSelect: () => { },
	renderCustomPopup: undefined,
	name: undefined
};

export default Popup;
