import QueryClientUpdater from 'src/new_arch/services/QueryClientUpdater';
import { ASSETS_URL, PROJECTS_URL } from '../config/urls';
import {
	FETCH_ASSET_ROUNDS, FETCH_PROJECT_ASSET_ROUNDS,
	SET_SELECTED_ASSET_ROUND_ID, CLEAR_SELECTED_ASSET_ROUND_ID,
	DELETE_CURRENT_ROUND, RESET_USER_IS_COMPARING_ROUNDS, SET_USER_IS_COMPARING_ROUNDS
} from './types';
import { trackCompareRoundsOpened, trackMovedToAPreviousRound } from '../services/tracker/trackers/assets';
import { getAsset } from '../selectors/assets';
import { getPreviousAssetRoundFromSelectedAssetRound } from '../selectors/assetRounds';

export const fetchRoundsForAsset = assetID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_ASSET_ROUNDS,
	promise: api
		.get( `${ASSETS_URL}/${assetID}/versions` )
		.then( response => response.response ),
	meta: { assetID }
} );

export const fetchAssetRoundsForProject = ( projectID, roundsIDs ) => ( dispatch, _, api ) => {
	if ( !roundsIDs || roundsIDs.length === 0 ) { return Promise.resolve( [] ); }

	const idsParameter = roundsIDs
		.map( id => `ids[]=${id}` )
		.join( '&' );

	return dispatch( {
		type: FETCH_PROJECT_ASSET_ROUNDS,
		promise: api
			.get( `${PROJECTS_URL}/${projectID}/asset_versions?${idsParameter}` )
			.then( ( response ) => {
				QueryClientUpdater.forFetchedAssetRounds( response.response );

				return response.response;
			} ),
		meta: { projectID }
	} );
};

export const setSelectedAssetRound = ( assetID, roundID ) => ( dispatch, getState ) => {
	const asset = getAsset( getState(), { assetID } );
	if ( asset && asset.currentRoundID !== roundID ) {
		trackMovedToAPreviousRound( asset );
	}

	return dispatch( {
		type: SET_SELECTED_ASSET_ROUND_ID,
		payload: roundID
	} );
};

export const clearSelectedAssetRound = () => ( {
	type: CLEAR_SELECTED_ASSET_ROUND_ID
} );

export const deleteCurrentRound = asset => ( dispatch, getState, api ) => {
	const { id: assetID, currentRoundID } = asset;
	const prevAssetRound = getPreviousAssetRoundFromSelectedAssetRound( getState(), { asset } );
	return dispatch( {
		type: DELETE_CURRENT_ROUND,
		promise: api.delete( `${ASSETS_URL}/${assetID}/versions/last` )
			.then( () => currentRoundID ),
		meta: { prevAssetRound }
	} );
};

export const setUserIsViewingPreviousRound = () => {
	trackCompareRoundsOpened();
	return { type: SET_USER_IS_COMPARING_ROUNDS };
};

export const resetUserIsViewingPreviousRound = () => (
	{ type: RESET_USER_IS_COMPARING_ROUNDS }
);
