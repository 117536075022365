/* eslint-disable camelcase */
export const parseProject = project => (
	{
		id: project.id,
		name: project.name,
		description: project.description,
		client_name: project.client_name,
		primary_contact: project.primary_contact,
		cover_url: project.cover_url,
		cover_id: project.cover_id,
		status: project.status,
		share_hash: project.share_hash,
		share_hash_downloadable: project.share_hash_downloadable,
		unresolved_comments_count: project.unresolved_comments_count,
		rush_priority: !!project.rush_priority_set_at,
		current_user_role: project.current_user_role,
		start_date: project.start_date,
		due_date: project.due_date,
		created_at: project.created_at,
		updated_at: project.updated_at,
		workspace_id: project.workspace_id,
		last_activity_at: project.last_activity_at,
		labels: project.labels,
		label: project.assigned_label,
		storage_used_in_bytes: project.storage_used_in_bytes,
		total_assets_count: project.total_assets_count,
		owner: project.owner,
		last_activity_by: project.last_activity_by,
		guest_hash: project.guest_hash,
		notifications_muted: project.notifications_muted,
		unresolved_mentions_count: project.unresolved_mentions_count
	}
);

export const parseProjectMetrics = project => (
	{
		id: project.id,
		is_new_for_current_user: project.is_new_for_current_user,
		has_new_activity: project.has_new_activity,
		has_new_assets: project.has_new_assets,
		label: project.assigned_label
	}
);

export const parseProjects = projects => projects
	.map( project => parseProject( project ) );

export const parseProjectsMetrics = projects => projects
	.map( project => parseProjectMetrics( project ) );
