import { useHistory } from 'react-router';
import useFetchProjectCountsQuery from '@projects/queries/useFetchProjectCountsQuery';
import IconButton from 'src/components/UI/IconButton/IconButton';
import { TEXT_GREY } from 'src/styles/colors';
import NavigationPanelButton from '../NavigationPanelButton/NavigationPanelButton';
import EmptyPlaceholder from './EmptyPlaceholder/EmptyPlaceholder';
import SkeletonLoader from './SkeletonLoader/SkeletonLoader';
import Section from './Section/Section';

import './NavigationPanel.scss';

const NavigationPanel = () => {
	const history = useHistory();
	const { data: projectCounts } = useFetchProjectCountsQuery();

	const navigateToDashboard = () => history.replace( '/projects' );

	const renderPanelContent = () => {
		if ( !projectCounts ) return <SkeletonLoader />;

		const hasActiveProjects = projectCounts.active > 0;
		const hasHiddenProjects = projectCounts.archived > 0;

		if ( !hasActiveProjects && !hasHiddenProjects ) return <EmptyPlaceholder />;

		return (
			<>
				{hasActiveProjects && <Section name="Active" projectStatus="active" />}
				{hasHiddenProjects && <Section name="Hidden" projectStatus="hidden" />}
			</>
		);
	}

	return (
		<div className="NavigationPanel">
			<div className="header">
				<div className="logo">
					<IconButton
						icon="vb-dark"
						size={24}
						onClick={navigateToDashboard}
						testID="vb-logo"
					/>
					<button onClick={navigateToDashboard}>
						<h3>My projects</h3>
					</button>
				</div>
				<NavigationPanelButton action="close" color={TEXT_GREY} />
			</div>
			<div className="content">
				{renderPanelContent()}
			</div>
		</div>
	);
}

export default NavigationPanel;
