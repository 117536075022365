export default {
	collaboration: {
		INVITED_PROJECT_MEMBERS: 'Invited project members',
		CLICKED_LOGIN_FOR_SHARED_PROJECT: 'Clicked login for shared project',
		CLICKED_REGISTER_FOR_SHARED_PROJECT: 'Clicked register for shared project',
		PROJECT_ROLE_EDITED: 'Changed user role'
	},
	subscription: {
		SUBSCRIPTION_REACTIVATED: 'Reactivated a cancelled subscription'
	},
	workspace: {
		WORKSPACE_DELETED: 'Deleted a workspace',
		WORKSPACE_OWNERSHIP_TRANSFERRED: 'Transferred ownership of a workspace',
		WORKSPACE_MEMBER_INVITED: 'Workspace member invited',
		WORKSPACE_ROLE_EDITED: 'Edited the role of a workspace member',
		WORKSPACE_BILLING_EDITED: 'Edited billing information',
		INVOICE_OPENED: 'Invoice opened'
	},
	project: {
		PROJECT_CREATED: 'Project created',
		PROJECT_DOWNLOADED: 'Downloaded a project',
		PROJECT_DELETED: 'Deleted a project',
		PROJECT_HIDDEN: 'Hid a project',
		PROJECT_UNHIDDEN: 'Unhid a project',
		PROJECT_FILTERED: 'Filter applied to projects',
		PROJECT_SEARCHED: 'Searched projects',
		PROJECT_CUSTOM_SORTED: 'Custom sorted projects',
		PROJECT_SORTED: 'Changed projects sort',
		PROJECT_FLAGGED: 'Added red flag to a project',
		PROJECT_UNFLAGGED: 'Removed red flag from a project',
		PROJECT_LINK_SHARED: 'Project share link copied',
		PROJECT_NOTIFICATIONS_MUTED_STATUS_CHANGED: 'Project notification on/off',
		PROJECT_PINNED: 'Pinned project in side navigation panel',
		PROJECT_UNPINNED: 'Unpinned project in side navigation panel'
	},
	assets: {
		ASSETS_UPLOADED: 'Assets uploaded',
		ASSET_FLAGGED: 'Added red flag to an asset',
		ASSET_UNFLAGGED: 'Removed red flag from an asset',
		ASSET_APPROVED_ASSET_VIEW: 'Approved an asset from Assets View',
		ASSET_DISAPPROVED_ASSET_VIEW: 'Disapproved an asset from Asset View',
		ASSET_APPROVED_MARKUP_VIEW: 'Approved an asset from Markup View',
		ASSET_DISAPPROVED_MARKUP_VIEW: 'Disapproved an asset from Markup View',
		ASSET_DOWNLOADED: 'Downloaded an asset image',
		ASSETS_DOWNLOADED: 'Downloaded multiple asset images',
		ASSET_DELETED: 'Deleted an asset',
		ASSETS_SORTED: 'Changed assets sort',
		ASSETS_FILTERED: 'Changed assets filter',
		ASSETS_SEARCHED: 'Searched assets',
		ASSETS_CUSTOM_SORTED: 'Custom sorted assets',
		ASSET_COMPARE_ROUNDS_SIDE_BY_SIDE: 'Compare rounds side by side',
		ASSET_SHOW_PREVIOUS_ROUND: 'Show previous round',
		ITEMS_MOVED_WITH_FOLDER_TREE: 'Moved items with folder tree',
		ASSETS_FLAT_VIEW_TOGGLED: 'Clicked view all assets mode'
	},
	commentsAndMarkups: {
		COMMENT_CREATED: 'Created comment',
		COMMENT_DELETED: 'Deleted comment',
		COMMENT_EDITED: 'Edited comment',
		COMMENT_APPROVED: 'Approved comment',
		COMMENTS_FILTERED: 'Filtered comments',
		BATCH_COMMENT_CREATED: 'Created batch comment'
	},
	imageTools: {
		COMPARE_ROUNDS_OPENED: 'Opened compare rounds',
		MOVED_TO_PREVIOUS_ROUND: 'Moved to a previous round',
		ASSET_IMAGE_ZOOMED: 'Asset image zoomed'
	},
	notification: {
		NOTIFICATIONS_PANEL_OPENED: 'Opened notifications panel',
		NOTIFICATION_PROJECT_OPENED: 'Opened project for a particular notification',
		NOTIFICATION_FOLDER_OPENED: 'Opened folder for a particular notification',
		NOTIFICATION_OPENED: 'Opened a particular notification',
		NOTIFICATION_SETTINGS_OPENED_FROM_PANEL: 'Opened notification settings from notifications panel'
	},
	task: {
		TASK_ASSIGNED_TO_USER: 'Assigned task to user',
		TASK_ASSIGNED_TO_MYSELF: 'Assigned task to myself',
		TASK_COMPLETED: 'Marked a task as completed'
	},
	access: {
		LOGGED_IN: 'Logged in',
		LOGGED_OUT: 'Logged out',
		SIGNED_UP: 'Signed up',
		SIGNED_UP_FROM_INVITATION: 'Signed Up from Email Invitation'
	},
	navigation: {
		NAVIGATED_TO_PRICING_MODAL: 'Navigated to Pricing Modal',
		NAVIGATED_TO_CHECKOUT_MODAL: 'Navigated to Checkout Modal',
		NAVIGATED_TO_MY_PROFILE: 'Navigated to MyProfile',
		NAVIGATED_TO_WORKSPACE_SETTINGS: 'Navigated to Workspace Settings',
		NAVIGATED_TO_WORKSPACE_PROJECTS: 'Navigated to Workspace Projects',
		NAVIGATED_TO_WORKSPACE_USERS: 'Navigated to Workspace Users',
		NAVIGATED_TO_WORKSPACE_BILLING: 'Navigated to Workspace Billing',
		NAVIGATED_TO_PROJECTS_VIEW: 'Navigated to Projects View',
		NAVIGATED_TO_CREATE_PROJECT_MODAL: 'Navigated to Create Project Modal',
		NAVIGATED_TO_PROJECT: 'Navigated to Project',
		NAVIGATED_TO_PROJECT_SETTINGS: 'Navigated to Project Settings',
		NAVIGATED_TO_PROJECT_INVITE_MODAL: 'Navigated to Project Invite Modal',
		NAVIGATED_TO_ASSET: 'Navigated to Asset',
		NAVIGATED_TO_NOTIFICATIONS_PANEL: 'Navigated to Notifications Panel',
		NAVIGATED_TO_TASKS_PANEL: 'Navigated to Tasks Panel',
		NAVIGATED_TO_ACTIVITY_LOG: 'Navigated to Activity Log',
		NAVIGATED_TO_FOLDER: 'Navigated to Folder',
		TOGGLED_NAVIGATION_PANEL: 'Toggled Navigation Panel',
		NAVIGATED_THROUGH_NAVIGATION_PANEL: 'Navigated through Navigation Panel'
	},
	folder: {
		FOLDER_CREATED: 'Folder created'
	},
	overview: {
		OVERVIEW_PANEL_OPENED: 'Opened overview panel',
		OVERVIEW_PANEL_TAB_CHANGED: 'Changed overview panel tab',
		OVERVIEW_LABEL_CLICKED: 'Clicked overview label'
	},
	sharing: {
		OPENED_SHARED_PROJECT: 'Opened shared project',
		OPENED_SHARED_FOLDER: 'Opened shared folder'
	},
	nac: {
		GUEST_LINK_SETTINGS_CHANGED: 'Guest link settings changed',
		GUEST_LINK_OPENED: 'Guest link opened',
		CREDENTIALS_PROMPT_COMPLETED: 'Credentials prompt completed'
	},
	addOns: {
		ADD_ON_BOUGHT: 'Bought member or storage add on'
	},
	onboardingThumbnails: {
		MAIN_DASHBOARD_ONBOARDING_THUMBNAILS_CLOSED: 'Closed main dashboard thumbnails',
		MAIN_DASHBOARD_ONBOARDING_THUMBNAIL_CLICKED: 'Clicked main dashboard thumbnail',
		MAIN_DASHBOARD_ONBOARDING_THUMBNAIL_LINK_CLICKED: 'Clicked main dashboard thumbnail link',
		PROJECT_ONBOARDING_THUMBNAILS_CLOSED: 'Closed project view thumbnails',
		PROJECT_ONBOARDING_THUMBNAIL_CLICKED: 'Clicked project dashboard thumbnail',
		PROJECT_ONBOARDING_THUMBNAIL_LINK_CLICKED: 'Clicked project dashboard thumbnail link'
	},
	uploads: {
		ADVANCE_UPLOADS_LEARN_MORE_CLICKED: 'Clicked learn more in Advanced Uploads modal',
		ADVANCE_UPLOADS_CANCEL_CLICKED: 'Cancel Upload in Advanced Uploads modal',
		ADVANCE_UPLOADS_CONTINUE_CLICKED: 'Continue Upload in Advanced Uploads modal'
	},
	labels: {
		ADDED_LABEL: 'Added label',
		REMOVED_LABEL: 'Removed label'
	}
};
