import { useCallback } from 'react';
import { QueryClient, useQuery } from '@tanstack/react-query';
import Project, { ProjectJSON, ProjectStatus } from '@projects/models/project';
import { queryClient as globalQueryClient } from 'src/store/store';
import useProjectSystem from '../systems/useProjectSystem';
import { QueryKeys } from './queryKeys';
import { DEFAULT_QUERY_OPTIONS } from './constants';

type UseFetchProjectsQueryParams<TSelect> = {
	status: ProjectStatus,
	select: ( projects: Project[] ) => TSelect
}

const useFetchProjectsQuery = <TSelect>( { status, select }: UseFetchProjectsQueryParams<TSelect> ) => {
	const system = useProjectSystem();
	const rawSelect = useCallback(
		( data: ProjectJSON[] ) => select( Project.allFromJson( data ) ),
		[ select ]
	);

	return useQuery(
		QueryKeys.forProjects( { status } ),
		() => system.fetchProjects( { status } ),
		{
			...DEFAULT_QUERY_OPTIONS,
			select: rawSelect
		}
	);
};

export const invalidateProjectsQueries = () => {
	[ 'active', 'hidden' ].forEach( ( status ) => {
		globalQueryClient.invalidateQueries( {
			queryKey: QueryKeys.forProjects( { status: status as ProjectStatus } ),
			refetchType: 'active'
		} );
	} );
}

export const getProjectFromProjectsQueries = ( queryClient: QueryClient, projectID: number ) => {
	const getFromProjectsWithStatus = ( status: ProjectStatus ) => {
		const projects = (
			queryClient.getQueryData( QueryKeys.forProjects( { status } ) )
		) as ProjectJSON[] | undefined;
		const project = projects?.find( p => p.id === projectID );
		return project ? Project.fromJson( project ) : undefined;
	}
	return getFromProjectsWithStatus( 'active' ) || getFromProjectsWithStatus( 'hidden' );
}

export default useFetchProjectsQuery;
