import { Dispatch } from 'redux';
import { UserJson } from '../models/user/user';
import { AssetJSON } from '../modules/projectView/models/asset';
import { FolderJSON, OrganizerType } from '../modules/projectView/models/folder';

export const UPDATER_ACTION_TYPE = {
	currentUser: '__UPDATE_CURRENT_USER',
	assets: '__UPDATE_ASSETS',
	folders: '__UPDATE_FOLDERS',
	asset: '__UPDATE_ASSET',
	assetsWith: '__UPDATE_ASSETS_WITH',
	foldersWith: '__UPDATE_FOLDERS_WITH',
	workspaceOnboardingMarkedAsClosed: '__MARK_WORKSPACE_ONBOARDING_AS_CLOSED',
	createOrUpdateAsset: '__CREATE_OR_UPDATE_ASSET'
} as const

/*
* This class is meant to update the redux store when a change with react query is made.
*/

export default class ReduxStoreUpdater {
	dispatch: Dispatch;

	constructor( dispatch: Dispatch ) {
		this.dispatch = dispatch;
	}

	updateCurrentUser( user: UserJson ) {
		this.dispatch( { type: UPDATER_ACTION_TYPE.currentUser, payload: { user } } );
	}

	replaceProjectAssets(
		assets: AssetJSON[], { projectID, replaceAll = false }: { projectID: number, replaceAll?: boolean }
	) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.assets,
			payload: assets,
			meta: { projectID, replaceAll }
		} );
	}

	updateAssets( assetIDs: number[], updater: ( asset: AssetJSON ) => AssetJSON ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.assetsWith,
			payload: updater,
			meta: { assetIDs }
		} );
	}

	updateAsset( assetID: number, updater: ( asset: AssetJSON ) => AssetJSON ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.asset,
			payload: updater,
			meta: { assetID }
		} );
	}

	createOrUpdateAsset( asset: AssetJSON, projectID: number ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.createOrUpdateAsset,
			payload: asset,
			meta: { assetID: asset.id, projectID }
		} );
	}

	replaceOrganizerFolders(
		folders: FolderJSON[],
		{ organizerType, organizerID }: { organizerType: OrganizerType, organizerID: number }
	) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.folders,
			payload: folders,
			meta: { organizerType, organizerID }
		} );
	}

	updateFolders( folderIDs: number[], updater: ( folder: FolderJSON ) => FolderJSON ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.foldersWith,
			payload: updater,
			meta: { folderIDs }
		} );
	}

	markWorkspaceOnboardingAsClosed( workspaceID: number ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.workspaceOnboardingMarkedAsClosed,
			meta: { workspaceID }
		} );
	}
}
