import { lazy, Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ModalContainer } from 'react-router-modal';
import Intercom from 'react-intercom';
import Dropzone from 'react-dropzone';
import { isMobile } from './lib/isMobile';
import withHotjar from './components/withHotjar/withHotjar';
import './newRelic';

import 'react-router-modal/css/react-router-modal.css';

import {
	withAuthentication,
	withoutAuthentication
} from './navigation/authentication';

import { connectComponent } from './lib/connectComponent';

import SiteNav from './components/navBar/SiteNav/SiteNav';
import AssetDetailsNav from './components/navBar/AssetDetailsNav/AssetDetailsNav';
import SharedViewNav from './components/navBar/SharedViewNav/SharedViewNav';

import Popup from './components/UI/Popup/Popup';

import User from './entities/user';
import { getAssetActiveUploadsCount } from './selectors/assetUploads';
import AssetsDropFilesIndicatorBoxContainer from './containers/AssetsDropFilesIndicatorBoxContainer/AssetsDropFilesIndicatorBoxContainer';
import { getLocationSearchParam, matchesWithSomePath } from './lib/urlUtils';
import { INTERCOM_ENABLED, ON_MAINTENANCE, RESPONSIVE_PATHS } from './config/global';
import Overlay from './components/Overlay/Overlay';
import OverlaySystem from './systems/OverlaySystem';
import AssetUploadPresenter from './presenters/AssetUploadPresenter/AssetUploadPresenter';
import PopupSystem from './systems/PopupSystem';
import openEmailClient from './services/openEmailClient';
import { getProjects } from './selectors/projects';
import { getFolders } from './selectors/folders';
import Project from './entities/project';
import MobileWarningLogin from './components/MobileWarning/MobileWarningLogin';
import MobileWarningSignup from './components/MobileWarning/MobileWarningSignup';
import RotateMobileScreenSystem from './systems/RotateMobileScreenSystem';
import RotateMobileScreen from './components/RotateMobileScreen/RotateMobileScreen';
import { getCurrentUserMemberWorksapces, getCurrentUserOwnedWorkspaces } from './selectors/workspaces';
import Workspace from './entities/workspace';
import { WORKSPACE_PATH, DEFAULT_PATH, PROFILE_PATH } from './screens/UserSettings/UserSettings';
import Splashscreen from './screens/SplashScreen/SplashScreen';
import Folder from './entities/folder';
import SubscriptionModalContainer from './containers/SubscriptionModalContainer/SubscriptionModalContainer';
import MaintenanceScreen from './screens/MaintenanceScreen/MaintenanceScreen';
import { sharedTracker } from './services/tracker/Tracker';
import FileSelection from './services/FileSelector/FileSelection';
import { getActivePopup } from './selectors/popups';
import withCurrentUser from './new_arch/modules/authentication/queries/withCurrentUser';
import withGlobalModals from './new_arch/modules/authentication/queries/withGlobalModals';
import AssetDownloadBoxDisplayer from './components/AssetDownloadBox/AssetDownloadBoxDisplayer';
import ShareRef from './structures/shareRef';
import ProjectGuestViewGateway from './screens/ProjectGuestViewGateway/ProjectGuestViewGateway';
import GuestHashHeaderSetter from './screens/GuestHashHeaderSetter/GuestHashHeaderSetter';
import Metadata from './new_arch/components/Metadata/Metadata';
import WithNavigationPanel from './new_arch/modules/navigationPanel/components/WithNavigationPanel/WithNavigationPanel';

const UserSettings = lazy( () => import( './screens/UserSettings/UserSettings' ) );
const Invitation = lazy( () => import( './new_arch/modules/authentication/Invitation/screen/Invitation' ) );
const ProjectDetails = lazy( () => import( './screens/ProjectDetails/ProjectDetails' ) );
const AssetDetailsWithDropzone = lazy( () => import( './screens/AssetDetails/AssetDetailsWithDropzone' ) );
const Projects = lazy( () => import( './screens/Projects/Projects' ) );
const SignIn = lazy( () => import( './new_arch/modules/authentication/SignIn/screen/SignIn' ) );
const CreateAccount = lazy( () => import( './new_arch/modules/authentication/CreateAccount/screen/CreateAccount' ) );
const EmailVerification = lazy( () => import( './new_arch/modules/authentication/EmailVerification/screen/EmailVerification' ) );
const ResetPassword = lazy( () => import( './new_arch/modules/authentication/ResetPassword/screen/ResetPassword' ) );
const ForgotPassword = lazy( () => import( './new_arch/modules/authentication/ForgotPassword/screen/ForgotPassword' ) );
const SharedViewAssetDetails	= lazy( () => import( './screens/SharedViewAssetDetails/SharedViewAssetDetails' ) );
const SharedViewDetails = lazy( () => import( './screens/SharedViewDetails/SharedViewDetails' ) );

const getID = ( match, key ) => parseInt( match.params[ key ], 10 ) || undefined;
const getProjectID = match => getID( match, 'projectID' );
const getFolderID = match => getID( match, 'folderID' );
const getAssetID = match => getID( match, 'assetID' );
const getProjectShareRef = match => ShareRef.forProject( match.params.shareHash );
const getFolderShareRef = match => ShareRef.forFolder( match.params.shareHash );
const getInvitationHash = match => match.params.invitationHash;
const getGuestHash = match => match.params.guestHash;

const getEncodedEmailFromSearchParams = location => getLocationSearchParam( location, 'em' );
const getInvitationTypeFromParams = location => getLocationSearchParam( location, 'type' );
const getWorkspaceID = match => (
	match.params.workspaceID ? parseInt( match.params.workspaceID, 10 ) : undefined
);

const workspacesIDsOrMiddleDash = workspaces => (
	workspaces.length > 0 ? workspaces.map( workspace => workspace.id ) : '-'
);

const getPaidActiveWorkspaces = workspaces => (
	workspaces.filter( workspace => workspace.hasAPaidPlan && workspace.isActive )
);

const currentUserIntercomProps = ( { currentUser, ownedWorkspaces, memberWorkspaces } ) => (
	currentUser
		? {
			user_id: currentUser.id,
			email: currentUser.email,
			name: currentUser.name,
			user_hash: currentUser.intercomHash,
			has_own_workspace: ownedWorkspaces?.length > 0,
			has_paid_active_workspace: getPaidActiveWorkspaces( ownedWorkspaces )?.length > 0,
			is_member_on_another_workspace: memberWorkspaces?.length > 0,
			workspaces_ids: workspacesIDsOrMiddleDash( ownedWorkspaces.concat( memberWorkspaces ) ).toString(),
			own_workspace_id: ownedWorkspaces?.[ 0 ]?.id || null,
			is_guest: currentUser.isGuest,
			UserRole: currentUser.role,
			intended_usage: currentUser.intendedUsage,
			referral_source: currentUser.referralSource
		} : {}
);

const UnauthenticatedSignIn = withoutAuthentication( SignIn );
const UnauthenticatedCreateAccount = withoutAuthentication( CreateAccount );
const UnauthenticatedResetPassword = withoutAuthentication( ResetPassword );
const UnauthenticatedForgot = withoutAuthentication( ForgotPassword );
const UnauthenticatedEmailVerification = withoutAuthentication( EmailVerification );

const AuthenticatedAssets = withAuthentication( AssetDetailsWithDropzone );
const AuthenticatedProjects = withAuthentication( Projects, { disableForGuests: true } );
const AuthenticatedProjectDetails = withAuthentication( ProjectDetails );
const AuthenticatedUserSettings = withAuthentication( UserSettings, { disableForGuests: true } );

export class App extends Component {
	constructor( props ) {
		super( props );

		this._onBeforePageUnload = this._onBeforePageUnload.bind( this );
		this._hideOverlay = this._hideOverlay.bind( this );
		this._windowPortraitOrientation = window.matchMedia( '(orientation: portrait)' );

		this.state = {
			isLandscapeOrientation: !this._windowPortraitOrientation.matches,
			isMaintenanceScreenOpen: this.props.onMaintenance
		};
	}

	componentDidMount() {
		window.addEventListener( 'beforeunload', this._onBeforePageUnload );
		this._unlistenToHistory = this.props.history.listen( this._hideOverlay );
		this._windowPortraitOrientation.addEventListener( 'change', ( event ) => {
			this.setState( () => ( { isLandscapeOrientation: !event.currentTarget.matches } ) );
		} );

		if ( this.props.currentUser ) { this._trackUser() }
	}

	componentDidUpdate( prevProps ) {
		if ( this.props.currentUser && this._currentUserChanged( prevProps ) ) {
			this._trackUser();
		}
	}

	componentWillUnmount() {
		window.removeEventListener( 'beforeunload', this._onBeforePageUnload );
		this._unlistenToHistory?.();
		this._windowPortraitOrientation.removeEventListener( 'change', ( event ) => {
			this.setState( () => ( { isLandscapeOrientation: !event.currentTarget.matches } ) );
		} );
	}

	get _onResponsiveScreen() {
		return matchesWithSomePath( {
			paths: RESPONSIVE_PATHS,
			location: this.props.history.location
		} );
	}

	get _onMobileOnLandscape() {
		return isMobile && this.state.isLandscapeOrientation;
	}

	get _onDevelopment() {
		return process.env.NODE_ENV === 'development';
	}

	get _redirections() {
		return [
			[ '/', '/projects' ],
			[ '/login', '/sign-in' ],
			[ '/signup', '/create-account' ]
		]
	}

	get _defaultDocumentHead() {
		return {
			title: 'verybusy.io'
		};
	}

	_currentUserChanged( prevProps ) {
		const { currentUser: currentUserBefore } = prevProps;
		const { currentUser: currentUserNow } = this.props;
		const anyPropChanged = ( ...props ) => currentUserBefore && props.some(
			prop => currentUserBefore[ prop ] !== currentUserNow[ prop ]
		);
		return currentUserBefore !== currentUserNow || anyPropChanged( 'isGuest' );
	}

	_trackUser = () => {
		const { id, isGuest, name, email, role, intendedUsage, referralSource } = this.props.currentUser;
		sharedTracker.setUser( { id, isGuest, name, email, role, intendedUsage, referralSource } );
	}

	_renderRotateMobileScreen = () => {
		if ( this.props.showRotateMobileScreen && this._onMobileOnLandscape ) {
			return <RotateMobileScreen />;
		}
		return null;
	}

	_closeMaintenanceScreen = () => {
		if ( !this._onDevelopment ) return null;
		return this.setState( { isMaintenanceScreenOpen: false } );
	};

	_redirectRoute = ( [ path, to ] ) => (
		<Route
			exact
			path={path}
			render={() => (
				<Redirect
					to={{
						pathname: to,
						state: this.props.history.location?.state
					}}
				/>
			)}
		/>
	)

	_renderRedirections = () => this._redirections.map( this._redirectRoute )

	_renderMaintenanceScreen = () => (
		<Suspense fallback={<Splashscreen />}>
			<MaintenanceScreen
				showCloseButton={this._onDevelopment}
				onClose={this._closeMaintenanceScreen}
			/>
		</Suspense>
	)

	_renderProjectDetailsScreen = ( { match, history, location } ) => (
		<Dropzone
			noClick
			onDrop={
				( acceptedFiles ) => {
					new AssetUploadPresenter( {
						fileSelection: FileSelection.fromDragAndDrop( acceptedFiles ),
						project: this.props.projects.get( getProjectID( match ) ),
						folder: this.props.folders.get( getFolderID( match ) ),
						createFileUploads: this.props.createFileUploads,
						createFolderUploads: this.props.createFolderUploads,
						checkAssetFilenamesMatches: this.props.checkAssetFilenamesMatches,
						popupSystem: this.props.popupSystem,
						openEmailClient,
						history
					} ).trigger();
				}
			}
		>
			{( { getRootProps, getInputProps, isDragActive } ) => {
				const projectID = getProjectID( match );
				const folderID = getFolderID( match );

				return (
					<GuestHashHeaderSetter projectID={projectID}>
						<div className="dropzone-container" {...getRootProps()}>
							<input {...getInputProps()} />
							{!!this.props.currentUser && (
								<SiteNav
									projectID={projectID}
									folderID={folderID}
									history={history}
									currentUser={this.props.currentUser}
								/>
							)}
							<AuthenticatedProjectDetails
								projectID={projectID}
								folderID={folderID}
								history={history}
								location={location}
							/>
							{
								isDragActive && (
									<AssetsDropFilesIndicatorBoxContainer projectID={projectID} folderID={folderID} />
								)
							}
						</div>
					</GuestHashHeaderSetter>
				);
			}}
		</Dropzone>
	);

	_hideOverlay() {
		if ( this.props.isShowingOverlay ) { this.props.overlaySystem.hideOverlay(); }
	}

	_onBeforePageUnload( event ) {
		const { props: { isUploadingAssets } } = this;

		if ( isUploadingAssets ) {
			event.preventDefault();
			event.returnValue = 'You have uncompleted uploads and they will be cancel if you close this page. Are you sure you want to continue?';
		}
	}

	_setShowingOverlayCSSVar() {
		return { '--showing-overlay': this.props.isShowingOverlay ? 1 : 0 };
	}

	render() {
		const {
			props: { currentUser, popupProps }
		} = this;

		if ( this.state.isMaintenanceScreenOpen ) return this._renderMaintenanceScreen();

		return (
			<div style={this._setShowingOverlayCSSVar()}>
				{this._renderRotateMobileScreen()}
				<Metadata title={this._defaultDocumentHead.title} />
				<WithNavigationPanel childrenContainerClassName="routes-container">
					<Switch>
						<Suspense fallback={<Splashscreen />}>
							{this._renderRedirections()}
							{isMobile && !!currentUser && !this._onResponsiveScreen
							&& (
								<Route
									path="/*"
									render={( { location } ) => (
										location?.state?.signUp
											? <MobileWarningSignup />
											: <MobileWarningLogin />
									)}
								/>
							)}
							<Route
								path="/sign-in"
								render={( { location, history } ) => (
									<UnauthenticatedSignIn history={history} location={location} />
								)}
							/>
							<Route
								path="/create-account"
								render={( { location, history } ) => (
									<UnauthenticatedCreateAccount
										history={history}
										location={location}
										type={getInvitationTypeFromParams( location )}
									/>
								)}
							/>
							<Route
								path="/email-verification"
								render={( { location, history } ) => (
									<UnauthenticatedEmailVerification history={history} location={location} />
								)}
							/>
							<Route
								path="/reset-password"
								render={( { location, history } ) => (
									<UnauthenticatedResetPassword history={history} location={location} />
								)}
							/>
							<Route
								path="/forgot"
								render={( { location, history } ) => (
									<UnauthenticatedForgot history={history} location={location} />
								)}
							/>
							<Route
								exact
								path="/workspaces/invitations/:invitationHash"
								render={
									( { match, history, location } ) => (
										<Invitation
											hash={getInvitationHash( match )}
											encodedEmail={getEncodedEmailFromSearchParams( location )}
											type="workspace"
											history={history}
										/>
									)
								}
							/>
							<Route
								exact
								path="/projects/invitations/:invitationHash"
								render={
									( { match, history, location } ) => (
										<Invitation
											hash={getInvitationHash( match )}
											encodedEmail={getEncodedEmailFromSearchParams( location )}
											type="project"
											history={history}
										/>
									)
								}
							/>
							<Route
								exact
								path="/projects/shared_view/:shareHash"
								render={
									( { match, history } ) => (
										<>
											<SharedViewNav shareRef={getProjectShareRef( match )} />
											<SharedViewDetails
												shareRef={getProjectShareRef( match )}
												history={history}
											/>
										</>
									)
								}
							/>
							<Route
								exact
								path="/folders/shared_view/:shareHash"
								render={
									( { match, history } ) => (
										<>
											<SharedViewNav shareRef={getFolderShareRef( match )} />
											<SharedViewDetails
												shareRef={getFolderShareRef( match )}
												history={history}
											/>
										</>
									)
								}
							/>
							<Route
								exact
								path="/projects/shared_view/:shareHash/assets/:assetID"
								render={
									( { match, history } ) => (
										<>
											<SharedViewNav
												shareRef={getProjectShareRef( match )}
												assetID={getAssetID( match )}
											/>
											<SharedViewAssetDetails
												shareRef={getProjectShareRef( match )}
												assetID={getAssetID( match )}
												history={history}
											/>
										</>
									)
								}
							/>
							<Route
								exact
								path="/folders/shared_view/:shareHash/assets/:assetID"
								render={
									( { match, history } ) => (
										<>
											<SharedViewNav
												shareRef={getFolderShareRef( match )}
												assetID={getAssetID( match )}
											/>
											<SharedViewAssetDetails
												shareRef={getFolderShareRef( match )}
												assetID={getAssetID( match )}
												history={history}
											/>
										</>
									)
								}
							/>
							<Route
								exact
								path="/projects/:projectID/assets/:assetID"
								render={
									( { match, location, history } ) => (
										<GuestHashHeaderSetter projectID={getProjectID( match )}>
											{!!currentUser
											&& (
												<AssetDetailsNav
													projectID={getProjectID( match )}
													assetID={getID( match, 'assetID' )}
													history={history}
													location={location}
												/>
											)}
											<AuthenticatedAssets
												projectID={getProjectID( match )}
												assetID={getAssetID( match )}
												history={history}
												location={location}
											/>
										</GuestHashHeaderSetter>
									)
								}
							/>
							<Route
								path="/projects"
								render={() => (
									<Switch>
										<Route
											exact
											path="/projects"
											render={
												( { history, location } ) => (
													<div>
														{!!currentUser && <SiteNav history={history} />}
														<AuthenticatedProjects
															history={history}
															location={location}
															viewingStatus="active"
														/>
													</div>
												)
											}
										/>
										<Route
											exact
											path={[ '/projects/:projectID', '/projects/:projectID/folders/:folderID' ]}
											render={this._renderProjectDetailsScreen}
										/>
									</Switch>
								)}
							/>
							<Route
								exact
								path="/hidden_projects"
								render={
									( { history, location } ) => (
										<div>
											{!!currentUser && <SiteNav history={history} />}
											<AuthenticatedProjects
												history={history}
												location={location}
												viewingStatus="archived"
											/>
										</div>
									)
								}
							/>
							<Route
								exact
								path={[ DEFAULT_PATH, PROFILE_PATH, WORKSPACE_PATH ]}
								render={
									( { history, match, location } ) => (
										<div>
											{!!currentUser && <SiteNav history={history} />}
											<AuthenticatedUserSettings
												history={history}
												location={location}
												selectedWorkspaceID={getWorkspaceID( match )}
											/>
										</div>
									)
								}
							/>
							<Route
								exact
								path="/p/:guestHash"
								render={
									( { match } ) => (
										<ProjectGuestViewGateway guestHash={getGuestHash( match )} />
									)
								}
							/>
						</Suspense>
					</Switch>
				</WithNavigationPanel>
				<Overlay />
				<AssetDownloadBoxDisplayer />
				<ModalContainer />
				<SubscriptionModalContainer currentUser={currentUser} />
				{popupProps && (
					<Popup
						{...popupProps}
					/>
				)}
				<Route
					render={() => ( INTERCOM_ENABLED && (
						<Intercom
							appID="k25u1x5y"
							{...currentUserIntercomProps( {
								currentUser,
								ownedWorkspaces: this.props.ownedWorkspaces,
								memberWorkspaces: this.props.memberWorkspaces
							} )}
						/>
					) )}
				/>
			</div>
		);
	}
}

App.propTypes = {
	currentUser: PropTypes.instanceOf( User ),
	popupProps: PropTypes.shape( Popup.propTypes ),
	hidePopup: PropTypes.func,
	createFileUploads: PropTypes.func,
	createFolderUploads: PropTypes.func,
	checkAssetFilenamesMatches: PropTypes.func,
	isUploadingAssets: PropTypes.bool,
	history: PropTypes.any,
	overlaySystem: PropTypes.instanceOf( OverlaySystem ).isRequired,
	isShowingOverlay: PropTypes.bool,
	popupSystem: PropTypes.instanceOf( PopupSystem ).isRequired,
	projects: PropTypes.arrayOf( Project ),
	folders: PropTypes.arrayOf( Folder ),
	showRotateMobileScreen: PropTypes.bool,
	rotateMobileScreenSystem: PropTypes.instanceOf( RotateMobileScreenSystem ).isRequired,
	ownedWorkspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	memberWorkspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	onMaintenance: PropTypes.bool
};

App.defaultProps = {
	currentUser: null,
	popupProps: null,
	hidePopup: () => {},
	createFileUploads: () => {},
	createFolderUploads: () => {},
	isUploadingAssets: false,
	history: undefined,
	isShowingOverlay: false,
	projects: [],
	folders: [],
	showRotateMobileScreen: false,
	ownedWorkspaces: [],
	memberWorkspaces: [],
	onMaintenance: ON_MAINTENANCE
};

const AppWithHotjar = withHotjar( App );

export default withGlobalModals( withCurrentUser( withRouter( connectComponent( state => ( {
	popupProps: getActivePopup( state ),
	isUploadingAssets: getAssetActiveUploadsCount( state ) > 0,
	isShowingOverlay: state.isShowingOverlay,
	projects: getProjects( state ),
	folders: getFolders( state ),
	showRotateMobileScreen: state.showRotateMobileScreen,
	ownedWorkspaces: getCurrentUserOwnedWorkspaces( state ),
	memberWorkspaces: getCurrentUserMemberWorksapces( state )
} ) )( AppWithHotjar ) ) ) );
