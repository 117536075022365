import ColorView from '../../../../structures/colorView';

export type LabellableType = 'Project' | 'Workspace';

type LabelAttributes = {
	id: number;
	name: string;
	labellableID: number;
	labellableType: LabellableType;
	baseColor: string;
	hoverColor: string;
	order?: number;
}

export type LabelJSON = {
	id: number;
	name: string;
	labellable_id: number;
	labellable_type: LabellableType;
	base_color: string;
	hover_color: string;
	order?: number;
}

export default class Label {
	id: number;
	name: string;
	labellableID: number;
	labellableType: LabellableType;
	baseColor: string;
	hoverColor: string;
	order?: number;

	constructor( {
		id, name, labellableID, labellableType, baseColor, hoverColor, order
	}: LabelAttributes ) {
		this.id = id;
		this.name = name;
		this.baseColor = baseColor;
		this.hoverColor = hoverColor;
		this.labellableID = labellableID;
		this.labellableType = labellableType;
		this.order = order;
	}

	static get type() {
		return { project: 'Project', workspace: 'Workspace' };
	}

	get color() {
		return new ColorView( { base: this.baseColor, hovered: this.hoverColor } );
	}

	static allFromJson( jsons: LabelJSON[] ) {
		return jsons.map( ( json, index ) => Label.fromJSON( {
			...json,
			order: json.order !== undefined ? json.order : index
		} ) );
	}

	static fromJSON( properties: LabelJSON ) {
		return new Label( {
			id: properties.id,
			name: properties.name,
			labellableID: properties.labellable_id,
			labellableType: properties.labellable_type,
			baseColor: properties.base_color,
			hoverColor: properties.hover_color,
			order: properties.order
		} );
	}

	toJSON = (): LabelJSON => ( {
		id: this.id,
		name: this.name,
		labellable_id: this.labellableID,
		labellable_type: this.labellableType,
		base_color: this.baseColor,
		hover_color: this.hoverColor,
		order: this.order
	} );
}
