import eskape from 'eskape';

export const htmlForMentions = text => (
	text
		.replace(
			/@\[(.+?)\]\(.+?\)/g,
			( _, user ) => eskape`<strong class="user-mention">@${user}</strong>`
		)
		.replace( /(?:\r\n|\r|\n)/g, '<br />' )
);
