import type FilterOption from './filterOption';

export type SelectedLabelID = number | null;
export type LabelFilterJSON = { ids: SelectedLabelID[]; match_all: boolean };
export type FilterJSON = Record<string, LabelFilterJSON | string | number | boolean | null>;

export type FilterType = 'image' | 'document';

export type AssetFilterMandatoryParams = {
	type: FilterType;
	searchText: string;
	filterOptions: FilterOption[];
};

export type SortDirection = 'asc' | 'desc';

export const isLabelFilterJSON = ( json: FilterJSON ): json is { label: LabelFilterJSON } => (
	typeof json === 'object' && json !== null && 'label' in json
);
