import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import ProjectTree from '@navigationPanel/components/ProjectTree/ProjectTree';
import useNavigationPanelStore from '@navigationPanel/hooks/useNavigationPanelStore';
import useOrganizerFromPath from '@navigationPanel/hooks/useOrganizerFromPath';
import IconButton from 'src/components/UI/IconButton/IconButton';
import { trackNavigatedThroughNavigationPanel } from 'src/services/tracker/trackers/navigation';
import { type OrganizerAttributes } from '@navigationPanel/types';
import { type ProjectStatus } from '@projects/models/project';

import './Section.scss';

type SectionProps = {
	name: string,
	projectStatus: ProjectStatus
}

const Section = ( { name, projectStatus }: SectionProps ) => {
	const history = useHistory();
	const onSectionControlClicked = () => {
		trackNavigatedThroughNavigationPanel( { navigatedTo: projectStatus } );
		if ( projectStatus === 'hidden' ) {
			history.push( '/hidden_projects' );
		} else {
			history.push( '/projects' );
		}
	}

	const navigationPanelStore = useNavigationPanelStore();
	const isExpanded = navigationPanelStore.sectionIsExpanded( projectStatus );
	const onExpanderClicked = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
		event.stopPropagation();
		navigationPanelStore.setSectionIsExpanded( projectStatus, !isExpanded );
	}

	const organizerFromPath = useOrganizerFromPath();
	const onOrganizerClick = useCallback(
		( { projectID, folderID }: OrganizerAttributes ) => {
			const navigatedTo = folderID ? 'folder' : 'project';
			trackNavigatedThroughNavigationPanel( { navigatedTo } );

			const pathname = folderID
				? `/projects/${projectID}/folders/${folderID}`
				: `/projects/${projectID}`;
			history.push( { pathname, search: history.location.search } );
		},
		[]
	);

	const expanderIcon = isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right';

	return (
		<div className="Section">
			<div className="control-outer">
				<div className="control-inner" onClick={onSectionControlClicked}>
					<span className="label">{name}</span>
					<IconButton
						icon={expanderIcon}
						size={18}
						color="black"
						buttonClassName="expander"
						onClick={onExpanderClicked}
						testID={`${projectStatus}-section-expander`}
					/>
				</div>
			</div>
			{isExpanded && (
				<ProjectTree
					status={projectStatus}
					initiallyExpandedProjectIDs={navigationPanelStore.expandedProjects}
					initiallyExpandedFolderIDs={navigationPanelStore.expandedFolders}
					onProjectExpandedChanged={navigationPanelStore.setProjectIsExpanded}
					onFolderExpandedChanged={navigationPanelStore.setFolderIsExpanded}
					selectedOrganizer={organizerFromPath}
					onOrganizerClick={onOrganizerClick}
					pinning={projectStatus === 'active' ? 'available' : 'none'}
				/>
			)}
		</div>
	)
}

export default observer( Section );
