import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSnackbarSystem from '@ui/systems/useSnackbarSystem';

import { getWorkspace, getWorkspaceMembersForWorkspace } from '../../selectors/workspaces';
import { connectComponent } from '../../lib/connectComponent';
import Workspace from '../../entities/workspace';
import WorkspaceMember from '../../entities/workspaceMember';
import TransferWorkspaceOwnershipModal from '../../modals/TransferWorkspaceOwnershipModal/TransferWorkspaceOwnershipModal';
import { actionResultIsError } from '../../lib/reduxUtils';
import { isNotAllowedOrNotFound } from '../../errors/common';

export const TransferWorkspaceOwnershipModalContainer = ( {
	workspace, members, fetchWorkspaceMembers, onClose, transferWorkspaceOwnership,
	showDefaultConfirmationDialog, hideConfirmationDialog, showDialog
} ) => {
	const { showErrorMessage } = useSnackbarSystem();
	useEffect( () => {
		fetchWorkspaceMembers( workspace.id );
	}, [] );
	const membersWithoutOwner = () => (
		members.filter( member => member.user.id !== workspace.owner.id )
	);

	const onOwnershipTransferred = ( newOwner, message ) => {
		showDefaultConfirmationDialog(
			'Transfer Workspace',
			'Are you sure you want to transfer your ownership of this workspace?',
			'Transfer Workspace',
			'Cancel',
			'center'
		)
			.then( ( confirm ) => {
				if ( confirm ) {
					const { id, owner } = workspace;
					transferWorkspaceOwnership( id, owner.id, newOwner, message )
						.then( ( result ) => {
							if ( actionResultIsError( result ) ) {
								throw result.payload;
							}

							showDialog(
								'Ownership Successfully Transferred',
								'You are no longer the owner of this Workspace.\nYou are now a Member.'
							);
						} )
						.catch( ( error ) => {
							if ( !isNotAllowedOrNotFound( error ) ) {
								showErrorMessage( { title: 'Oops, something went wrong! Try again.' } );
							}
						} )
						.finally( onClose );
				}
				hideConfirmationDialog();
			} );
	};
	return (
		<TransferWorkspaceOwnershipModal
			workspaceName={workspace.name}
			onClose={onClose}
			members={membersWithoutOwner()}
			onOwnershipTransfer={onOwnershipTransferred}
			disableButton={!workspace.isActive}
		/>
	);
};

TransferWorkspaceOwnershipModalContainer.propTypes = {
	workspace: PropTypes.instanceOf( Workspace ),
	members: PropTypes.arrayOf( WorkspaceMember ),
	fetchWorkspaceMembers: PropTypes.func,
	onClose: PropTypes.func,
	transferWorkspaceOwnership: PropTypes.func,
	showDefaultConfirmationDialog: PropTypes.func,
	hideConfirmationDialog: PropTypes.func,
	showErrorMessage: PropTypes.func,
	showDialog: PropTypes.func
};

TransferWorkspaceOwnershipModalContainer.defaultProps = {
	workspace: undefined,
	members: [],
	fetchWorkspaceMembers: () => {},
	onClose: () => {},
	transferWorkspaceOwnership: () => {},
	showDefaultConfirmationDialog: () => {},
	hideConfirmationDialog: () => {},
	showErrorMessage: () => {},
	showDialog: () => {}
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	members: getWorkspaceMembersForWorkspace( state, props )
} ) )( TransferWorkspaceOwnershipModalContainer );
