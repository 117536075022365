/* eslint-disable max-classes-per-file */
import { ProjectStatus } from '@projects/models/project';
import { type QueryFilters as TanstackQueryFilters } from '@tanstack/react-query';

class QueryKeys {
	static forProjects( { status }: { status: ProjectStatus } ) {
		return [ 'projects', { status } ];
	}

	static forProjectCounts() {
		return [ 'projects', 'counts' ];
	}

	static forProjectMembers( { projectID }: { projectID: number } ) {
		return [ 'projects', projectID, 'members' ];
	}

	static forIncludedFeatures( projectID: number ) {
		return [ 'projects', projectID, 'included_features' ];
	}
}

class QueryFilters {
	static forProjectLists(): TanstackQueryFilters {
		return {
			type: 'active',
			predicate: ( { queryKey: key } ) => (
				key[ 0 ] === 'projects'
				&& ( key[ 1 ] === 'active' || key[ 2 ] === 'hidden' )
			)
		};
	}
}

export { QueryKeys, QueryFilters };
