import ColorView from '../../../../structures/colorView';
import { LIGHT_GREY } from '../../../../styles/colors';

export default class NullLabel {
	name: string;

	constructor( name = 'No Label' ) {
		this.name = name;
	}

	get id() {
		return null;
	}

	get isCustom() {
		return false;
	}

	get labellableID() {
		return undefined;
	}

	get labellableType() {
		return undefined;
	}

	get order() {
		return undefined;
	}

	get color() {
		return new ColorView( { base: LIGHT_GREY } );
	}
}
