import { memo } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CompareIcon from '@material-ui/icons/Compare';
import CommentIcon from '@material-ui/icons/Comment';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import LensIcon from '@material-ui/icons/Lens';
import UndoIcon from '@material-ui/icons/Undo';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import FlagIcon from '@material-ui/icons/Flag';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ReplyIcon from '@material-ui/icons/Reply';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearIcon from '@material-ui/icons/Clear';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinkIcon from '@material-ui/icons/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import Filter1Icon from '@material-ui/icons/Filter1';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BugReportIcon from '@material-ui/icons/BugReport';
import TimelineIcon from '@material-ui/icons/Timeline';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CancelIcon from '@material-ui/icons/Cancel';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PersonIcon from '@material-ui/icons/Person';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Launch from '@material-ui/icons/Launch';
import Chat from '@material-ui/icons/Chat';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Folder from '@material-ui/icons/Folder';
import FolderOutlined from '@material-ui/icons/FolderOutlined';
import Business from '@material-ui/icons/Business';
import CollectionsIcon from '@material-ui/icons/Collections';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SendIcon from '@material-ui/icons/Send';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonAddOutlined from '@material-ui/icons/PersonAddOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import Public from '@material-ui/icons/Public';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AddCommentOutlined from '@material-ui/icons/AddCommentOutlined';
import AddPhotoAlternateOutlined from '@material-ui/icons/AddPhotoAlternateOutlined';
import Videocam from '@material-ui/icons/Videocam';
import OpenInNew from '@material-ui/icons/OpenInNew';
import GroupAdd from '@material-ui/icons/GroupAdd';
import WorkOutline from '@material-ui/icons/WorkOutline';
import BookOutlined from '@material-ui/icons/BookOutlined';
import VerticalSplit from '@material-ui/icons/VerticalSplit';
import RemoveIcon from '@material-ui/icons/Remove';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import Apps from '@material-ui/icons/Apps';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import NordEastArrow from '@material-ui/icons/CallMade';
import CommentOutlined from '@material-ui/icons/CommentOutlined';

export const MATERIAL_ICONS = {
	'close': CloseIcon,
	'close-rounded': CloseRoundedIcon,
	'add': AddIcon,
	'get_app': GetAppIcon,
	'get_app_outlined': GetAppOutlinedIcon,
	'check_circle': CheckCircleIcon,
	'fullscreen': FullscreenIcon,
	'fullscreen_exit': FullscreenExitIcon,
	'info': InfoIcon,
	'info_outlined': InfoOutlinedIcon,
	'search': SearchIcon,
	'remove_red_eye': RemoveRedEyeIcon,
	'done': DoneIcon,
	'clear': ClearIcon,
	'error_outline': ErrorOutlineIcon,
	'error': ErrorIcon,
	'notifications': NotificationsIcon,
	'arrow_drop_down': ArrowDropDownIcon,
	'flag': FlagIcon,
	'check': CheckIcon,
	'check_circle_outline': CheckCircleOutlineIcon,
	'cloud_download_outlined': CloudDownloadOutlinedIcon,
	'keyboard_arrow_up': KeyboardArrowUpIcon,
	'keyboard_arrow_down': KeyboardArrowDownIcon,
	'keyboard_arrow_right': KeyboardArrowRightIcon,
	'keyboard_arrow_left': KeyboardArrowLeftIcon,
	'camera_alt': CameraAltIcon,
	'reply': ReplyIcon,
	'more_vert': MoreVertIcon,
	'help': HelpIcon,
	'settings': SettingsIcon,
	'settings_outlined': SettingsOutlinedIcon,
	'date_range': DateRangeIcon,
	'refresh': RefreshIcon,
	'add_circle': AddCircleIcon,
	'remove_circle': RemoveCircleIcon,
	'mode_comment': ModeCommentIcon,
	'panorama_fish_eye': PanoramaFishEyeIcon,
	'filter_1': Filter1Icon,
	'link': LinkIcon,
	'arrow_back': ArrowBackIcon,
	'exit_to_app': ExitToAppIcon,
	'undo': UndoIcon,
	'lens': LensIcon,
	'delete': DeleteIcon,
	'delete_sweep_outlined': DeleteSweepOutlinedIcon,
	'delete_outlined': DeleteOutlinedIcon,
	'compare': CompareIcon,
	'comment': CommentIcon,
	'brightness_1': Brightness1Icon,
	'radio_button_checked': RadioButtonCheckedIcon,
	'radio_button_unchecked': RadioButtonUncheckedIcon,
	'check_box_outline_blank': CheckBoxOutlineBlankIcon,
	'check_box': CheckBoxIcon,
	'bug_report': BugReportIcon,
	'timeline': TimelineIcon,
	'assignment_turned_in': AssignmentTurnedInIcon,
	'share': ShareIcon,
	'expand_more': ExpandMoreIcon,
	'expand_less': ExpandLessIcon,
	'cancel': CancelIcon,
	'access_time': AccessTimeIcon,
	'edit': EditIcon,
	'edit_outlined': EditOutlinedIcon,
	'archive': ArchiveIcon,
	'unarchive': UnarchiveIcon,
	'person': PersonIcon,
	'people-outlined': PeopleOutlinedIcon,
	'person-outlined': PersonOutlineIcon,
	'help_outline': HelpOutlineIcon,
	'photo_library': PhotoLibraryIcon,
	'credit_card': CreditCardIcon,
	'launch': Launch,
	'chat': Chat,
	'file': InsertDriveFile,
	'file_outlined': InsertDriveFileOutlined,
	'dot': FiberManualRecordIcon,
	'eye': VisibilityOutlinedIcon,
	'crossed_out_eye': VisibilityOffOutlinedIcon,
	'folder': Folder,
	'folder-outlined': FolderOutlined,
	'business': Business,
	'collections': CollectionsIcon,
	'arrow-downward': ArrowDownwardIcon,
	'arrow-upward': ArrowUpwardIcon,
	'send': SendIcon,
	'more_horiz': MoreHorizIcon,
	'person-add-outlined': PersonAddOutlined,
	'warning': WarningIcon,
	'label_outlined': LabelOutlinedIcon,
	'publish_icon': PublishIcon,
	'chat_outlined': ChatOutlined,
	'public': Public,
	'trending-up': TrendingUpIcon,
	'trending-down': TrendingDownIcon,
	'apps-outlined': AppsOutlinedIcon,
	'notifications-outlined': NotificationsOutlinedIcon,
	'notifications-off-outlined': NotificationsOffOutlinedIcon,
	'add-circle-outlined': AddCircleOutlineIcon,
	'drag-indicator-outlined': DragIndicatorOutlinedIcon,
	'add-box-outlined': AddBoxOutlinedIcon,
	'add-comment-outlined': AddCommentOutlined,
	'add-photo-alternated-outlined': AddPhotoAlternateOutlined,
	'open-in-new': OpenInNew,
	'videocam': Videocam,
	'group-add': GroupAdd,
	'work-outline': WorkOutline,
	'book-outlined': BookOutlined,
	'vertical-split': VerticalSplit,
	'remove': RemoveIcon,
	'email-outlined': EmailOutlined,
	'apps': Apps,
	'menu-open': MenuOpen,
	'menu': Menu,
	'nord-east-arrow': NordEastArrow,
	'comment-outlined': CommentOutlined
};

export type MaterialIconName = keyof typeof MATERIAL_ICONS;
type MaterialIconProps = { icon: MaterialIconName; size: number | string; color?: string; className?: string; }

const MaterialIcon = memo( ( { icon, size, color, className } : MaterialIconProps ) => {
	const Component = MATERIAL_ICONS[ icon ] || MATERIAL_ICONS.bug_report;
	return <Component className={className} style={{ fontSize: size, color }} />;
} );

export default MaterialIcon;
