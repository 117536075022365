import Label from '@labels/models/label';
import NullLabel from '@labels/models/nullLabel';

export type OverviewData = Array<[ label: Label | NullLabel, assetCount: number ]>;

export default class OrganizerOverview {
	overviewData: OverviewData;
	constructor( overviewData: OverviewData ) {
		this.overviewData = overviewData;
	}
}
