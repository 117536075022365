import NullLabel from '@labels/models/nullLabel';
import FilterOption from './filterOption';

export default class LabelFilterOption extends FilterOption {
	constructor( label ) {
		super(
			label.id,
			label.name,
			{ field: 'labelID', value: label.id, type: 'equal' }
		);

		this._label = label;
	}

	get isLabelOption() { return true; }

	get filter() { return 'label'; }

	get labelName() {
		return this._label.name;
	}

	get labelColor() {
		return this._label.color;
	}

	get isNullLabel() {
		return this._label instanceof NullLabel;
	}
}
