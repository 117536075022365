import { observer } from 'mobx-react-lite';
import LabelsColorsGrid from '@labels/components/LabelsColorsGrid/LabelsColorsGrid';

import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';
import FormInput from '../../new_arch/components/FormInput/FormInput';
import Button from '../../new_arch/components/Button/Button';
import useCreateOrEditLabelGroupModalPresenter from '../../presenters/CreateOrEditLabelGroupModalPresenter/useCreateOrEditLabelGroupModalPresenter';
import ColorLabel from '../../components/UI/ColorLabel/ColorLabel';
import type LabelGroup from '../../entities/labelGroup';
import { preventDefaultAndCall } from '../../new_arch/lib/eventUtils';
import type LabelModalPresenter from '../../presenters/CreateOrEditLabelGroupModalPresenter/utils/LabelModalPresenter';

import './CreateOrEditLabelGroupModal.scss';
import IconButton from '../../components/UI/IconButton/IconButton';

type CreateOrEditLabelGroupModalProps = {
	workspaceID: number,
	mode: 'create' | 'edit',
	labelGroup?: LabelGroup,
	onClose: () => void
};

const CreateOrEditLabelGroupModal = ( {
	workspaceID,
	mode,
	labelGroup,
	onClose
}: CreateOrEditLabelGroupModalProps ) => {
	const presenter = useCreateOrEditLabelGroupModalPresenter( { workspaceID, mode, labelGroup, onClose } );

	const renderCreateLabel = ( labelModal: LabelModalPresenter ) => (
		<form onSubmit={labelModal.onSubmit}>
			<FormInput
				value={labelModal.name}
				error={labelModal.nameError}
				onChange={labelModal.onNameChanged}
				label="Label Name"
				name="label-name"
				placeholder="Name your label"
				autoFocus
			/>
			<LabelsColorsGrid
				initialColor={labelModal.color}
				onColorClick={labelModal.onColorChanged}
				asRow
			/>
			<div className="actions">
				<Button type="text" onClick={presenter.backArrowClicked}>Cancel</Button>
				<Button disabled={labelModal.isSubmitButtonDisabled}>
					Done
				</Button>
			</div>
		</form>
	);

	const renderCreateGroup = () => (
		<form onSubmit={preventDefaultAndCall( presenter.onSubmit )}>
			<FormInput
				value={presenter.name}
				error={presenter.error}
				onChange={presenter.onGroupNameChanged}
				label="Group Name"
				name="group-name"
				placeholder="Enter your group name..."
				autoFocus
			/>
			<div className="label-groups">
				<h5>Labels</h5>
				<Button
					className="add-group-button"
					type="text"
					onClick={presenter.addLabelWasClicked}
				>
					+Add label
				</Button>
			</div>
			<div className="labels">
				{presenter.labels.map( ( label, index ) => (
					<div className="label">
						<ColorLabel
							key={label.name}
							text={label.name}
							backgroundColor={{
								base: label.baseColor,
								hovered: label.hoverColor
							}}
						/>
						<div className="actions">
							<IconButton
								icon="edit"
								testID="edit-row-icon"
								onClick={() => presenter.editLabelWasClicked( index )}
							/>
							<IconButton
								icon="delete"
								testID="delete-label-icon"
								onClick={() => presenter.deleteLabelWasClicked( index )}
							/>
						</div>
					</div>
				) )}
			</div>
			<div className="actions">
				<Button type="text" onClick={onClose}>Cancel</Button>
				<Button disabled={presenter.isSubmitButtonDisabled} buttonType="submit">
					{presenter.submitButtonLabel}
				</Button>
			</div>
		</form>
	);

	return (
		<CloseableModalWithTitle
			headerBorderType="small"
			onClose={onClose}
			name="label-group-form"
			title={presenter.title}
			onBack={presenter.isCreatingLabel ? presenter.backArrowClicked : null}
		>
			<div className="CreateOrEditLabelGroupModal">
				{presenter.isCreatingLabel && renderCreateLabel( presenter.labelModal! )}
				{!presenter.isCreatingLabel && renderCreateGroup()}
			</div>
		</CloseableModalWithTitle>
	);
}

export default observer( CreateOrEditLabelGroupModal );
