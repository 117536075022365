import Label, { LabelJSON, LabellableType } from '@labels/models/label';

/* eslint-disable no-use-before-define */
export type OrganizerType = 'Folder' | 'Project';

type FolderParams = {
	id: number,
	name: string,
	organizerID: number,
	organizerType: OrganizerType,
	projectID: number,
	currentUserIsOwner: boolean,
	coverUrl: string,
	shareHash: string,
	shareHashDownloadable: string,
	createdAt: Date,
	labels: Label[],
	lastActivityAt: Date,
	hasNewActivity: boolean,
	subfoldersWithNewActivityCount: number,
	imagesCount: number,
	documentsCount: number,
	filteredAssetsCount: number,
	unresolvedCommentsCount: number,
	unresolvedMentionsCount: number
};

export type FolderJSON = {
	id: number,
	name: string,
	organizer_id: number,
	organizer_type: string,
	project_id: number,
	current_user_is_owner: boolean,
	cover_url: string,
	share_hash: string,
	share_hash_downloadable: string,
	created_at: Date,
	labels?: LabelJSON[],
	last_activity_at: Date,
	has_new_activity: boolean,
	subfolders_with_new_activity_count: number,
	images_count: number,
	documents_count: number,
	filtered_assets_count: number,
	unresolved_comments_count: number,
	unresolved_mentions_count?: number
};

interface FolderOverviewFetcher { fetchOverviewForFolder: ( folder: Folder ) => Promise<void> }
interface FolderInformationFetcher { fetchInformationForFolder: ( folder: Folder ) => Promise<void> }

export default class Folder {
	id: number;
	name: string;
	organizerID: number;
	organizerType: string;
	projectID: number;
	currentUserIsOwner: boolean;
	coverUrl: string;
	shareHash: string;
	shareHashDownloadable: string;
	createdAt: Date;
	labels: Label[];
	lastActivityAt: Date;
	hasNewActivity: boolean;
	subfoldersWithNewActivityCount: number;
	imagesCount: number;
	documentsCount: number;
	filteredAssetsCount: number;
	unresolvedCommentsCount: number;
	unresolvedMentionsCount: number;

	constructor( params: FolderParams ) {
		this.id = params.id;
		this.name = params.name;
		this.organizerID = params.organizerID;
		this.organizerType = params.organizerType;
		this.projectID = params.projectID;
		this.currentUserIsOwner = params.currentUserIsOwner;
		this.coverUrl = params.coverUrl;
		this.shareHash = params.shareHash;
		this.shareHashDownloadable = params.shareHashDownloadable;
		this.createdAt = params.createdAt;
		this.labels = params.labels;
		this.lastActivityAt = params.lastActivityAt;
		this.hasNewActivity = params.hasNewActivity;
		this.subfoldersWithNewActivityCount = params.subfoldersWithNewActivityCount;
		this.imagesCount = params.imagesCount;
		this.documentsCount = params.documentsCount;
		this.filteredAssetsCount = params.filteredAssetsCount;
		this.unresolvedCommentsCount = params.unresolvedCommentsCount;
		this.unresolvedMentionsCount = params.unresolvedMentionsCount;
	}

	static fromJson( json: FolderJSON ) {
		return new Folder( {
			id: json.id,
			name: json.name,
			organizerID: json.organizer_id,
			organizerType: json.organizer_type as OrganizerType,
			projectID: json.project_id,
			currentUserIsOwner: json.current_user_is_owner,
			coverUrl: json.cover_url,
			shareHash: json.share_hash,
			shareHashDownloadable: json.share_hash_downloadable,
			createdAt: json.created_at,
			labels: Label.allFromJson( json.labels || [] ),
			lastActivityAt: json.last_activity_at,
			hasNewActivity: json.has_new_activity,
			subfoldersWithNewActivityCount: json.subfolders_with_new_activity_count,
			imagesCount: json.images_count,
			documentsCount: json.documents_count,
			filteredAssetsCount: json.filtered_assets_count,
			unresolvedCommentsCount: json.unresolved_comments_count,
			unresolvedMentionsCount: json.unresolved_mentions_count || 0
		} );
	}

	static allFromJson( jsons: FolderJSON[] ) {
		return jsons.map( Folder.fromJson );
	}

	toJson(): FolderJSON {
		return {
			id: this.id,
			name: this.name,
			organizer_id: this.organizerID,
			organizer_type: this.organizerType,
			project_id: this.projectID,
			current_user_is_owner: this.currentUserIsOwner,
			cover_url: this.coverUrl,
			share_hash: this.shareHash,
			share_hash_downloadable: this.shareHashDownloadable,
			created_at: this.createdAt,
			labels: this.labels.map( label => label.toJSON() ),
			last_activity_at: this.lastActivityAt,
			has_new_activity: this.hasNewActivity,
			subfolders_with_new_activity_count: this.subfoldersWithNewActivityCount,
			images_count: this.imagesCount,
			documents_count: this.documentsCount,
			filtered_assets_count: this.filteredAssetsCount,
			unresolved_comments_count: this.unresolvedCommentsCount,
			unresolved_mentions_count: this.unresolvedMentionsCount
		}
	}

	get hasNewActivityItselfOrInSubfolders() {
		return this.hasNewActivity || this.subfoldersWithNewActivityCount > 0;
	}

	get containsMatchingAssets() {
		return this.filteredAssetsCount > 0;
	}

	get assetsCount() {
		return this.imagesCount + this.documentsCount;
	}

	get isFolder() { return true; }

	get selectionID() { return `folder-${this.id}`; }

	async fetchOverviewWith( fetcher: FolderOverviewFetcher ) {
		return fetcher.fetchOverviewForFolder( this );
	}

	async fetchInformationWith( fetcher: FolderInformationFetcher ) {
		return fetcher.fetchInformationForFolder( this );
	}

	assetsCountDescription( { useFilteredCount = false } = {} ) {
		const assetsCount = useFilteredCount ? this.filteredAssetsCount : this.imagesCount;
		return `${assetsCount} ${'asset'.pluralize( assetsCount )}`;
	}

	shareLink( { withDownloads = false }: { withDownloads?: boolean } = {} ) {
		const shareHash = withDownloads ? this.shareHashDownloadable : this.shareHash;
		return `folders/shared_view/${shareHash}`;
	}

	get selectionKey() {
		return `folder-${this.id}`;
	}

	get folderID() {
		return this.organizerType === 'Folder' ? this.organizerID : null;
	}

	get labellableID() { return this.projectID; }

	get labellableType(): LabellableType { return 'Project'; }
}
