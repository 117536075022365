import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-router-modal';
import HistoryPropType, { LocationPropType } from '../../types/history';

export class CloseableModal extends Component {
	constructor( props ) {
		super( props );

		this._history = props.history;
		this._location = props.location;
		this._name = props.name;

		this._onEscPressed = this._onEscPressed.bind( this );
		this.tryClose = this.tryClose.bind( this );
		this._addModalNameToQueryParams = this._addModalNameToQueryParams.bind( this );
		this._removeModalNameFromQueryParams = this._removeModalNameFromQueryParams.bind( this );
		this._canUpdateQueryParams = this._canUpdateQueryParams.bind( this );
		this._originalQueryParams = this._originalQueryParams.bind( this );
		this._replaceHistoryWith = this._replaceHistoryWith.bind( this );
	}

	componentDidMount() {
		document.addEventListener( 'keydown', this._onEscPressed );
		document.body.parentNode.style.overflowY = 'hidden';
		this._addModalNameToQueryParams();
	}

	componentWillUnmount() {
		document.removeEventListener( 'keydown', this._onEscPressed );
		document.body.parentNode.style.overflowY = 'auto';
		this._removeModalNameFromQueryParams();
	}

	_addModalNameToQueryParams() {
		if ( this._canUpdateQueryParams() && this._modalNameIsNotAlreadyInQueryParams() ) {
			const queryParams = this._originalQueryParams();
			queryParams.append( 'modal', this._name );
			this._replaceHistoryWith( queryParams.toString() );
		}
	}

	_removeModalNameFromQueryParams() {
		if ( this._canUpdateQueryParams() ) {
			const queryParams = this._originalQueryParams();
			if ( queryParams.get( 'modal' ) === this._name ) queryParams.delete( 'modal' );
			this._replaceHistoryWith( queryParams.toString() );
		}
	}

	_canUpdateQueryParams() {
		return this._name && this._history && this._location && this.locationHasNotChanged();
	}

	locationHasNotChanged() {
		return this._history.location.pathname === this._location.pathname;
	}

	_modalNameIsNotAlreadyInQueryParams() {
		return this._originalQueryParams().get( 'modal' ) !== this._name;
	}

	_originalQueryParams() {
		return new URLSearchParams( this._location.search );
	}

	_replaceHistoryWith( newQueryParams ) {
		this._history.replace( {
			pathname: this._location.pathname,
			state: this._location.state,
			search: newQueryParams
		} );
	}

	_onEscPressed( event ) {
		if ( event.keyCode === 27 ) {
			this.tryClose();
		}
	}

	tryClose() {
		const { onClose, canClose } = this.props;

		if ( canClose ) {
			onClose();
		}
	}

	render() {
		const { className, children } = this.props;
		return (
			<Modal
				className={className}
				onBackdropClick={this.tryClose}
			>
				{children}
			</Modal>
		);
	}
}

CloseableModal.propTypes = {
	className: PropTypes.node,
	onClose: PropTypes.func,
	children: PropTypes.node,
	canClose: PropTypes.bool,
	name: PropTypes.string,
	history: HistoryPropType,
	location: LocationPropType
};

CloseableModal.defaultProps = {
	className: null,
	onClose: () => {},
	children: null,
	canClose: true,
	name: '',
	history: null,
	location: null
};

export default CloseableModal;
