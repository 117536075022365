import { Loader } from '@mantine/core';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';
import IconButton from 'src/components/UI/IconButton/IconButton';
import { BLACK } from 'src/styles/colors';

import { hasFailed, iconConfigFor, isUploading, statusConfigFor } from './utils';
import { Upload } from './types';

import styles from './UploadItem.module.scss';

type UploadItemProps = {
	upload: Upload,
	onCancelClick?: ( id: number ) => void
}

const UploadStatusIcon = ( { upload }: { upload: Upload } ) => {
	const iconConfig = iconConfigFor( upload );

	return iconConfig
		? <Icon icon={iconConfig.icon} color={iconConfig.color} className="status" />
		: <Loader size={18} className="status" />;
}
const UploadStatusText = ( { upload }: { upload: Upload } ) => {
	const { text, className } = statusConfigFor( upload );

	return (
		<p className={className}>{text}</p>
	);
}

const UploadItem = ( { upload, onCancelClick }: UploadItemProps ) => (
	<div className={classNames( styles.UploadItem, { folder: upload.isFolderUpload } )}>
		<div className={styles.UploadItem__content}>
			<div className={classNames( styles.UploadItem__title, { folder: upload.isFolderUpload } )}>
				{upload.isFolderUpload && ( <Icon icon="folder" color="secondary.4" /> )}
				<p>{upload.name}</p>
			</div>
			<div className={styles.UploadItem__details}>
				<UploadStatusText upload={upload} />
				<div className={classNames( styles.UploadItem__icon, { uploading: isUploading( upload ) } )}>
					<UploadStatusIcon upload={upload} />
					{isUploading( upload ) && (
						<IconButton
							icon="clear"
							onClick={() => onCancelClick?.( upload.id )}
							buttonClassName="cancel"
							color={BLACK}
						/>
					)}
				</div>
			</div>
		</div>
		{hasFailed( upload ) && <p className={styles.UploadItem__error}>{upload.errorMessage}</p>}
	</div>
);

export default UploadItem;
