import { memo, createElement } from 'react';
import { ReactComponent as HideIcon } from 'src/assets/img/icons/hide-icon.svg';
import { ReactComponent as UnhideIcon } from 'src/assets/img/icons/unhide-icon.svg';
import { ReactComponent as AttachIcon } from 'src/assets/img/icons/attach-icon.svg';
import { ReactComponent as DisabledAttachIcon } from 'src/assets/img/icons/disabled-attach-icon.svg';
import { ReactComponent as ChromaticCircle } from 'src/assets/img/icons/chromatic-circle.svg';
import { ReactComponent as Leave } from 'src/assets/img/icons/leave.svg';
import { ReactComponent as Add } from 'src/assets/img/icons/add.svg';
import { ReactComponent as PriorityFlag } from 'src/assets/img/icons/priority-flag.svg';
import { ReactComponent as PriorityFlagShadow } from 'src/assets/img/icons/priority-flag-shadow.svg';
import { ReactComponent as ApprovedIcon } from 'src/assets/img/icons/approved.svg';
import { ReactComponent as WrenchIcon } from 'src/assets/img/icons/wrench.svg';
import { ReactComponent as FilterIcon } from 'src/assets/img/icons/filter-icon.svg';
import { ReactComponent as BrushIcon } from 'src/assets/img/icons/brush-icon.svg';
import { ReactComponent as BrushOffIcon } from 'src/assets/img/icons/brush-off-icon.svg';
import { ReactComponent as FileUploadIcon } from 'src/assets/img/icons/file-upload.svg';
import { ReactComponent as FolderUploadIcon } from 'src/assets/img/icons/folder-upload.svg';
import { ReactComponent as DriveFileMoveOutline } from 'src/assets/img/icons/drive-file-move-outline.svg';
import { ReactComponent as DriveFileRenameOutline } from 'src/assets/img/icons/drive-file-rename-outline.svg';
import { ReactComponent as UnapproveIcon } from 'src/assets/img/icons/unapprove.svg';
import { ReactComponent as NorthWestIcon } from 'src/assets/img/icons/north-west.svg';
import { ReactComponent as PointerIcon } from 'src/assets/img/icons/pointer.svg';
import { ReactComponent as ArrowOutwardIcon } from 'src/assets/img/icons/arrow-outward.svg';
import { ReactComponent as LineIcon } from 'src/assets/img/icons/line.svg';
import { ReactComponent as SquareIcon } from 'src/assets/img/icons/square.svg';
import { ReactComponent as FolderCoverIcon } from 'src/assets/img/icons/folder-cover.svg';
import { ReactComponent as SquareEditOutlinedIcon } from 'src/assets/img/icons/square-edit-outlined.svg';
import { ReactComponent as SideBySideIcon } from 'src/assets/img/icons/side-by-side.svg';
import { ReactComponent as VerybusyDarkIcon } from 'src/assets/img/icons/vb-icon-dark.svg';
import { ReactComponent as PinIcon } from 'src/assets/img/icons/pin-icon.svg';
import { ReactComponent as AutoFixHigh } from 'src/assets/img/icons/auto-fix-high.svg';
import { ReactComponent as Mentions } from 'src/assets/img/icons/mentions.svg';
import { ReactComponent as GridView } from 'src/assets/img/icons/grid-view.svg';
import { ReactComponent as ListView } from 'src/assets/img/icons/list-view.svg';
import { ReactComponent as ExpandView } from 'src/assets/img/icons/expand-view.svg';

export const SVG_ICONS = {
	'hide': HideIcon,
	'unhide': UnhideIcon,
	'attach': AttachIcon,
	'disabled_attach': DisabledAttachIcon,
	'chromatic-circle': ChromaticCircle,
	'leave': Leave,
	'add': Add,
	'priority-flag': PriorityFlag,
	'priority-flag-shadow': PriorityFlagShadow,
	'approved': ApprovedIcon,
	'wrench': WrenchIcon,
	'filter-icon': FilterIcon,
	'brush': BrushIcon,
	'brush-off': BrushOffIcon,
	'file-upload': FileUploadIcon,
	'folder-upload': FolderUploadIcon,
	'drive-file-move-outline': DriveFileMoveOutline,
	'drive-file-rename-outline': DriveFileRenameOutline,
	'unapprove': UnapproveIcon,
	'north-west': NorthWestIcon,
	'pointer': PointerIcon,
	'arrow-outward': ArrowOutwardIcon,
	'line': LineIcon,
	'square': SquareIcon,
	'folder-cover': FolderCoverIcon,
	'square-edit-outlined': SquareEditOutlinedIcon,
	'side-by-side': SideBySideIcon,
	'vb-dark': VerybusyDarkIcon,
	'pin': PinIcon,
	'auto-fix-high': AutoFixHigh,
	'mentions': Mentions,
	'grid-view': GridView,
	'list-view': ListView,
	'expand-view': ExpandView
};

export type SvgIconName = keyof typeof SVG_ICONS;

export const isSvgIcon = ( icon: string ): icon is SvgIconName => Object.keys( SVG_ICONS ).includes( icon );

type SvgIconProps = { icon: SvgIconName; size: number | string; color?: string; className?: string; }

const SvgIcon = memo( ( { icon, size, className, color }: SvgIconProps ) => createElement(
	SVG_ICONS[ icon ],
	{
		className,
		style: { 'width': size, 'height': size, '--color': color }
	}
) );

export default SvgIcon;
