import { makeAutoObservable } from 'mobx';
import Asset from '../../../../../entities/asset';
import Label from '../../models/label';
import PopupFactory from '../../../../../factories/PopupFactory';

export default class LabelFloatingMenuPresenter {
	constructor( {
		labelAssignables,
		labels,
		labelSystem,
		popupSystem
	} ) {
		this.labelAssignables = labelAssignables;
		this.labels = labels;
		this._labelSystem = labelSystem;
		this._popupSystem = popupSystem;

		this.isCreatingLabel = false;
		this.isSubmissionInProgress = false;
		this.isAddingLabelGroup = false;
		this.editingLabel = null;
		this._showDropdown = true;
		this._draggedLabelID = null;

		makeAutoObservable( this, { _labelSystem: false, _popupSystem: false, assetHasLabel: false } );
	}

	get showDropdown() {
		return this._showDropdown;
	}

	get hasLabels() {
		return this.labels.length > 0;
	}

	get labelIDs() {
		return this.labels.map( label => label.id );
	}

	get isEditingLabel() {
		return !!this.editingLabel;
	}

	get lastLabel() {
		return this.labels.last;
	}

	get labelFloatingMenuButtonProps() {
		if ( this._labelAssignablesHaveTheSameLabel ) {
			return { label: this._firstLabelAssignablesLabel };
		}
		if ( !this._labelAssignablesHaveTheSameLabellable ) return { text: null };
		if ( this._labelAssignablesHaveNoLabel ) return { text: 'Add label' };
		return { text: 'Change labels' };
	}

	get _labelAssignablesHaveTheSameLabellable() {
		return this.labelAssignables.every(
			labelAssignable => labelAssignable.labellableID === this._firstLabelAssignable.labellableID
		);
	}

	get disabled() {
		return !this._firstLabelAssignable.currentUserCanAssignLabels;
	}

	get labelListTitle() {
		return `${this._labellableType} labels`;
	}

	get showAddFromLabelGroupsButton() {
		return this._labelAssignableIsAnAsset;
	}

	get _currentUserCanAssignLabels() {
		return this.labelAssignables.every(
			labelAssignable => labelAssignable.currentUserCanAssignLabels );
	}

	get draggedLabel() {
		return this.labels.find( label => label.id === this._draggedLabelID );
	}

	get draggedLabelIndex() {
		return this.labelIDs.indexOf( this._draggedLabelID );
	}

	setShowDropdown( showDropdown ) {
		this._showDropdown = showDropdown;
	}

	labelAssignablesHaveLabel( label ) {
		return this.labelAssignables.every( labelAssignable => labelAssignable.hasLabel( label ) );
	}

	labelWasClicked = ( label ) => {
		if ( this._labelAssignableIsAnAsset ) {
			this._labelSystem.toggleLabelOnAssets( { assets: this.labelAssignables, label } );
		} else {
			this._labelSystem.toggleLabelOnProjects( { projects: this.labelAssignables, label } );
		}
	}

	labelWasCreated = async ( name, color ) => {
		this.isSubmissionInProgress = true;
		await this._labelSystem.createLabel( {
			name,
			baseColor: color.baseColor,
			hoverColor: color.hoverColor,
			labellableType: this._labellableType,
			labellableID: this._labellableID
		} );
		this.isCreatingLabel = false;
		this.isSubmissionInProgress = false;
	}

	labelWasEdited = ( name, color ) => {
		this._labelSystem.editLabel( { name, color, label: this.editingLabel } );
		this.editingLabel = null;
	}

	deleteLabelWasClicked( label ) {
		this._showDeleteLabelConfirmationDialog( label )
			.then( ( confirm ) => {
				if ( confirm ) {
					this._labelSystem.deleteLabel( { label } );
				}
				this._popupSystem.hideConfirmationDialog();
			} );
	}

	backArrowClicked = () => {
		this.isCreatingLabel = false;
		this.editingLabel = null;
		this.isAddingLabelGroup = false;
	}

	labelGroupWasAdded = () => {
		this.isAddingLabelGroup = false;
		this.setShowDropdown( true );
	}

	editLabelWasClicked( label ) {
		this.editingLabel = label;
	}

	createLabelWasClicked = () => {
		this.isCreatingLabel = true;
	}

	renderLabelsGroupsModal = ( { projectID } ) => {
		PopupFactory.labelGroupsPopup( {
			popupSystem: this._popupSystem,
			onClose: () => this.setShowDropdown( true ),
			projectID
		} );
	}

	addLabelGroupsWasClicked = () => {
		this.setShowDropdown( false );
		this.renderLabelsGroupsModal( { projectID: this._labellableID } );
	}

	dropdownWasToggled() {
		this.isCreatingLabel = false;
		this.editingLabel = null;
		this.isAddingLabelGroup = false;
	}

	setDraggedLabelID( draggedLabelID ) {
		this._draggedLabelID = draggedLabelID;
	}

	dragEnded = ( { atLabelID } ) => {
		if ( this._draggedLabelID && atLabelID && this._draggedLabelID !== atLabelID ) {
			const sourceIndex = this.draggedLabelIndex;
			const destinationIndex = this.labelIDs.indexOf( atLabelID );
			const [ reorderedLabel ] = this.labels.splice( sourceIndex, 1 );
			this.labels.splice( destinationIndex, 0, reorderedLabel );
			this._saveLabelsOrder();
		}
		this.setDraggedLabelID( null );
	}

	_saveLabelsOrder() {
		if ( this._labelAssignableIsAnAsset ) {
			this._labelSystem.setProjectLabelsOrder( {
				projectID: this._labellableID,
				labels: this.labels
			} );
		} else {
			this._labelSystem.setWorkspaceLabelsOrder( {
				workspaceID: this._labellableID,
				labels: this.labels
			} );
		}
	}

	_showDeleteLabelConfirmationDialog( label ) {
		return this._popupSystem.showDangerConfirmationDialog( {
			title: 'Delete label',
			description: `${label.name} label will be removed from all your ${this._labelAssignableIsAnAsset ? 'assets' : 'projects'}.\nAre you sure you want to delete it?`,
			acceptText: 'Delete',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} );
	}

	get _labelAssignablesHaveTheSameLabel() {
		return this.labelAssignablesHaveLabel( this._firstLabelAssignable.label );
	}

	get _labelAssignablesHaveNoLabel() {
		return this.labelAssignables.every( labelAssignable => !labelAssignable.hasAnyLabel );
	}

	get _labellableID() {
		return this._firstLabelAssignable.labellableID;
	}

	get _firstLabelAssignable() {
		return this.labelAssignables.first;
	}

	get _firstLabelAssignablesLabel() {
		return this._firstLabelAssignable.label;
	}

	get _labelAssignableIsAnAsset() {
		return this._labellableType === Label.type.project;
	}

	get _labellableType() {
		return this._firstLabelAssignable instanceof Asset
			? 'Project'
			: 'Workspace';
	}
}
