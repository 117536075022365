import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import Label from '@labels/models/label';

const getRawLabels = state => state.labels;

export const getLabels = createSelector(
	getRawLabels,
	rawLabels => rawLabels?.map( Label.fromJSON )
);

const labelsHaveOrder = labels => labels.some( label => label.order !== undefined );
const getLabelsDefaultOrder = labels => labels
	.sort(
		firstBy( label => label.isCustom )
			.thenBy( label => label.id )
	);

export const sortLabels = ( labels ) => {
	if ( !labels || labels.some( label => !label ) ) return undefined;
	if ( labelsHaveOrder( labels ) ) {
		return labels.sort(
			firstBy( label => label.order ?? Infinity )
		);
	}
	return getLabelsDefaultOrder( labels );
};
