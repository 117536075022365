import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState } from 'react';
import { Tooltip } from '@mantine/core';
import Text from '@ui/components/Text/Text';
import IconButton from '@ui/components/IconButton/IconButton';

import Select from '../UI/Select/Select';
import SortOption from '../../entities/sortOption';
import SortSelectorMultiOptionType from '../../types/sortSelectorMultiOption';

import './SortSelector.scss';

const SortSelector = ( {
	options, selected, onChange, ascending
} ) => {
	// This hovered state has been added to avoid the flickering of the button when the user clicks on it.
	// The flickering happens because the button changes when its clicked, so the hover animation triggers again.
	const [ isHovered, setIsHovered ] = useState( false );
	const onOptionChange = newSelected => onChange( newSelected, ascending );
	const onDirectionButtonClick = () => onChange( selected, !ascending );
	const directionTooltipText = ascending ? 'Ascending' : 'Descending';

	return (
		<div className="SortSelector">
			<Select
				selected={selected}
				placeholder="No Sort"
				options={options}
				onChange={onOptionChange}
				showSelectedDot
				selectedOptionType="Sort:"
			/>
			<Tooltip
				label={<Text variant="footnote">{directionTooltipText}</Text>}
				position="bottom"
			>
				<IconButton
					className={classNames( 'sort-direction-button', {
						'is-hovered': isHovered
					} )}
					color="gray.8"
					icon={ascending ? 'arrow-upward' : 'arrow-downward'}
					onMouseEnter={() => setIsHovered( true )}
					onMouseLeave={() => setIsHovered( false )}
					onClick={onDirectionButtonClick}
					size={16}
				/>
			</Tooltip>
		</div>
	);
};

SortSelector.propTypes = {
	options: PropTypes.oneOfType( [
		PropTypes.arrayOf(
			SortSelectorMultiOptionType
		),
		PropTypes.arrayOf(
			PropTypes.instanceOf( SortOption )
		)
	] ),
	selected: PropTypes.instanceOf( SortOption ),
	onChange: PropTypes.func,
	ascending: PropTypes.bool
};

SortSelector.defaultProps = {
	options: [],
	selected: undefined,
	onChange: () => {},
	ascending: true
};

export default SortSelector;
