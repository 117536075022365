import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import PricingSubscriptionPresenter from './PricingSubscriptionPresenter';
import {
	SUCCESS_700, PRIMARY_BASE, WARNING_700, ERROR,
	WARNING_200, ERROR_LIGHT, STRONG_ERROR
} from '../../styles/colors';
import { actionResultIsError } from '../../lib/reduxUtils';
import PopupFactory from '../../factories/PopupFactory';
import SnackbarFactory from '../../factories/SnackbarFactory';
import { PRICING_PLANS_AVAILABLE } from '../../config/global';

const COMMON_PLAN_BUTTON_DATA = { disabled: !PRICING_PLANS_AVAILABLE };
const PAID_PLAN_BUTTON_DATA = { ...COMMON_PLAN_BUTTON_DATA, text: 'Change Plan' };
const FREE_TRIAL_BUTTON_DATA = { ...COMMON_PLAN_BUTTON_DATA, type: 'filled', text: 'Upgrade' };
const SUSPENDED_WORKSPACE_BUTTON_DATA = { ...COMMON_PLAN_BUTTON_DATA, text: 'See Plans' };
const MARKED_FOR_CANCELLATION_WORKSPACE_BUTTON_DATA = { ...COMMON_PLAN_BUTTON_DATA, text: 'Reactivate Subscription' };

export default class PlanSettingsPresenter {
	constructor( {
		popupSystem,
		cancelSubscription,
		reactivateSubscription,
		createSubscription,
		workspace,
		fetchWorkspaces,
		snackbarSystem,
		downgradeSubscription,
		cancelDowngradeSubscription,
		history
	} ) {
		this._popupSystem = popupSystem;
		this.cancelSubscription = cancelSubscription;
		this.reactivateSubscription = reactivateSubscription;
		this.createSubscription = createSubscription;
		this.workspace = workspace;
		this._fetchWorkspaces = fetchWorkspaces;
		this.snackbarSystem = snackbarSystem;
		this.downgradeSubscription = downgradeSubscription;
		this.cancelDowngradeSubscription = cancelDowngradeSubscription;
		this._history = history;

		this.subscriptionModalIsOpen = this.showSubscriptionModal;
		makeAutoObservable( this, { _popupSystem: false } );
	}

	cancelSubscriptionAndShowConfirmation = () => {
		this.cancelSubscription( this.workspace?.id, this.workspace.subscription?.id )
			.then( () => {
				this._popupSystem.showDialog( {
					title: 'Subscription Cancelled',
					description: 'Your workspace will be suspended at end of the current billing period.'
				} );
			} );
	};

	onSubscriptionCancelClicked = () => {
		window.Intercom( 'trackEvent', 'cancelled subscription' );
		this._popupSystem.showDangerConfirmationDialog( {
			title: 'Cancel Subscription',
			description: 'Your workspace will be suspended and all collaborative features will be blocked at the end of the billing period (you will still be able to download your assets after that).',
			checkboxDescription: 'I understand what will happen and I still want to proceed',
			acceptText: 'Cancel Subscription',
			descriptionAlignment: 'center'
		} )
			.then( ( confirm ) => {
				if ( confirm ) {
					this.cancelSubscriptionAndShowConfirmation();
				}
				this._popupSystem.hideConfirmationDialog();
			} );
	};

	reactivateSubscriptionAndShowConfirmation = () => {
		this.reactivateSubscription( this.workspace?.id, this.workspace.subscription?.id )
			.then( () => {
				this._popupSystem.showDialog( {
					title: 'Subscription Reactivated',
					description: 'Your subscription will be renewed at the end\nof your current billing period.'
				} );
			} );
	};

	onReactivateSubscriptionClicked = () => {
		this._popupSystem.showDefaultConfirmationDialog( {
			title: 'Reactivate Subscription',
			description: "Your subscription will be automatically renewed at the end of the billing period and you will be able to continue using all VeryBusy's features inside your workspace.",
			acceptText: 'Reactivate Subscription',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			if ( confirm ) {
				this.reactivateSubscriptionAndShowConfirmation();
			}
			this._popupSystem.hideConfirmationDialog();
		} );
	};

	downgradeSubscriptionAndShowSuccessMessage = () => {
		this.downgradeSubscription( this.workspace )
			.then( () => {
				SnackbarFactory.forDowngradeSubscriptionSuccess( this.snackbarSystem );
			} );
	};

	onDowngradeClicked = () => {
		PopupFactory.downgradeSubscriptionConfirmationPopup( this._popupSystem )
			.then( ( confirm ) => {
				if ( confirm ) {
					this.downgradeSubscriptionAndShowSuccessMessage();
				}
				this._popupSystem.hideConfirmationDialog();
				this.closeSubscriptionModal();
			} );
	};

	openSubscriptionModal = () => {
		this.subscriptionModalIsOpen = true;
	};

	closeSubscriptionModal = () => {
		this.subscriptionModalIsOpen = false;
	};

	onCancelDowngradeClicked = () => {
		this.cancelDowngradeSubscription( this.workspace.id )
			.then( () => {
				SnackbarFactory.forCancelDowngradeSubscriptionSuccess( this.snackbarSystem );
				this.closeSubscriptionModal();
			} );
	};

	onDowngradeWarningButtonClicked = () => (
		this.isSuspendedForDowngrade ? this.openSubscriptionModal() : this.onCancelDowngradeClicked()
	);

	createFreeSubscription( freePricingPlanPrice ) {
		if ( !freePricingPlanPrice?.isFree ) throw Error( 'A free pricing plan price must be provided.' );

		this.createSubscription( this.workspace.id, freePricingPlanPrice ).then( ( result ) => {
			if ( actionResultIsError( result ) ) {
				PopupFactory.defaultErrorDialog( this._popupSystem );
				return;
			}

			this._popupSystem.showDialog( {
				title: 'Workspace Reactivated',
				description: 'You can now use your workspace projects with collaboration features for free.'
			} );
			this._fetchWorkspaces();
		} );
	}

	onUpgradePlanButtonClicked = () => {
		if ( this.isSubscriptionMarkedForCancellation ) {
			return this.onReactivateSubscriptionClicked();
		}
		return this.openSubscriptionModal();
	}

	onGoToBillingClicked = () => {
		this._history.replace( `/user/workspaces/${this.workspace.id}/billing` );
	}

	openSubscriptionModalBasedOnQueryParams = () => {
		const queryParams = new URLSearchParams( this._history.location.search );
		if ( queryParams.get( 'modal' ) === 'subscription-selection' ) {
			this.openSubscriptionModal();
		}
	}

	get pricingSubscriptionPresenter() {
		return new PricingSubscriptionPresenter( { workspace: this.workspace } );
	}

	get projectsCount() {
		return this.workspace.projectsCount;
	}

	get isAPaidPlan() {
		return !!this.workspace?.hasAPaidPlan;
	}

	get isDowngradePending() {
		return this.workspace?.hasADowngradePending;
	}

	get isSuspendedForDowngrade() {
		return this.workspace.isSuspendedForDowngrade;
	}

	get shouldRenderDowngradeWarning() {
		return this.isSuspendedForDowngrade && this.isDowngradePending;
	}

	get shouldRenderCancelSubscriptionButton() {
		return this.workspace.isSubscriptionActive && !this.workspace.isOnFreeTrial;
	}

	get shouldRenderAddOnsDescription() {
		return this.workspace.hasMembersAddOns || this.workspace.hasStorageAddOns;
	}

	get downgradeWarningButtonText() {
		return this.isSuspendedForDowngrade ? 'See Plans' : 'Restore Team';
	}

	get downgradeWarningText() {
		return this.isSuspendedForDowngrade ? 'To reactivate your workspace, please select a plan.'
			: `Reminder: You've elected to downgrade your plan. On ${this.formatedBillingPeriodEnd} your subscription will not be renewed, and your workspace will become suspended. On this day, sign up for any plan to reactivate it.`;
	}

	get downgradeWarningIconColor() {
		return this.isSuspendedForDowngrade ? ERROR : WARNING_700;
	}

	get downgradeWarningColor() {
		return this.isSuspendedForDowngrade ? ERROR_LIGHT : WARNING_200;
	}

	get downgradeWarningTooltipText() {
		return this.isSuspendedForDowngrade ? 'You must be within the limits of the plan to which you want to subscribe.'
			: 'You will need to be within the storage and member limits of the new plan to be able to downgrade.';
	}

	get planName() {
		return this.workspace.subscriptionPlanName?.capitalize();
	}

	get isSubscriptionActive() {
		return this.workspace.isSubscriptionActive;
	}

	get isOnActiveFreeTrial() {
		return this.workspace.isOnActiveFreeTrial;
	}

	get upgradeButtonData() {
		if ( this.workspace.isOnActiveFreeTrial ) return FREE_TRIAL_BUTTON_DATA;
		if ( !this.workspace.isSubscriptionActive ) return SUSPENDED_WORKSPACE_BUTTON_DATA;
		if ( this.isSubscriptionMarkedForCancellation ) return MARKED_FOR_CANCELLATION_WORKSPACE_BUTTON_DATA;
		return PAID_PLAN_BUTTON_DATA;
	}

	get isSubscriptionMarkedForCancellation() {
		return this.workspace.isSubscriptionMarkedForCancellation;
	}

	get titleColor() {
		return PRIMARY_BASE;
	}

	get formatedBillingPeriodEnd() {
		return moment.utc( this.workspace.billingPeriodEndsAt ).format( 'MMM DD, YYYY' );
	}

	get showSubscriptionModal() {
		return this._history.location?.state?.showSubscriptionModal;
	}

	get expiredColor() {
		return STRONG_ERROR;
	}

	get freeTrialColor() {
		return SUCCESS_700;
	}

	get daysLeftOnFreeTrial() {
		return this.workspace.daysLeftOnFreeTrial;
	}

	get subscriptionPriceWithDiscount() {
		return `$${this.workspace.subscription.priceWithDiscountForDisplay}`;
	}
}
