import type Asset from '../asset';
import { type FilterJSON } from './types';
import FilterOption from './filterOption';
import LabelFilter from './labelFilter';
import { FilterType } from './types';

export type AssetFilterParams = {
	type?: FilterType,
	searchText?: string,
	filterOptions?: FilterOption[]
}

export default class AssetFilters {
	type: FilterType;
	searchText: string;
	filterOptions: FilterOption[];

	constructor( { type, searchText, filterOptions }: AssetFilterParams ) {
		this.type = type || 'image';
		this.searchText = searchText || '';
		this.filterOptions = filterOptions || [];
	}

	// Meant to be used as a dependency for React (or React-like) hooks
	get stringOfFilterOptionIds() {
		return this.filterOptions.map( option => option.id ).toString();
	}

	get usesSearchTextOrFilterOption() {
		return !!this.searchText || this.filterOptions?.length > 0;
	}

	get isSearching() {
		return !!this.searchText;
	}

	get filterAmount() {
		return this.filterOptions.reduce( ( sum, option ) => sum + option.filterAmount, 0 );
	}

	get isFiltering() {
		return this.filterAmount > 0;
	}

	get isFilteringOrSearching() {
		return this.isFiltering || this.isSearching;
	}

	get filteredLabelIds() {
		return this.labelFilterOption?.ids || [];
	}

	get labelMatchAllOption() {
		return this.labelFilterOption?.matchAll || false;
	}

	isFilteringBy( filterOption: FilterOption ) {
		return this.filterOptions.some( option => option.id === filterOption.id );
	}

	filterAssets( assets: Asset[] ) {
		return assets.filter( asset => (
			this.passesFilterType( asset )
			&& this.passesSearchFilter( asset )
			&& this.passesFilterOption( asset )
		) );
	}

	toJson() {
		let json: FilterJSON = {};

		if ( this.type ) json.type = this.type;
		if ( this.searchText ) json.name = this.searchText;
		if ( this.filterOptions && this.filterOptions.length > 0 ) {
			json = this.filterOptions.reduce(
				( acc, filterOption ) => ( { ...acc, ...filterOption.toJson() } ), json
			);
		}

		return json;
	}

	toJsonString() {
		return JSON.stringify( this.toJson() );
	}

	private passesFilterType( asset: Asset ) {
		return !this.type || ( this.type === 'image' ? asset.isImage : asset.isDocument );
	}

	private passesSearchFilter( asset: Asset ) {
		return !this.searchText || asset.name.toLowerCase().includes( this.searchText.toLocaleLowerCase() );
	}

	private passesFilterOption( asset: Asset ) {
		return this.filterOptions.every( filterOption => filterOption.isPassedBy( asset ) );
	}

	private get labelFilterOption() {
		return this.filterOptions.find( option => option instanceof LabelFilter ) as LabelFilter;
	}
}
