import { forwardRef, useState } from 'react';
import useDeepEffect from '../../../hooks/useDeepEffect';
import ColorView from '../../../structures/colorView';

import './ColorLabel.scss';

type ColorLabelProps = {
	text: string;
	backgroundColor: ColorView;
	textColor?: ColorView;
	borderColor?: ColorView;
	className?: string;
	withBorder?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};

const ColorLabel = forwardRef<HTMLDivElement, ColorLabelProps>( ( {
	text, backgroundColor, textColor, className, withBorder, borderColor = textColor, onMouseEnter, onMouseLeave
}, ref ) => {
	const [ isHovering, setIsHovering ] = useState( false );
	useDeepEffect( () => setIsHovering( false ), [ backgroundColor, textColor ] );

	const currentBackgroundColor = isHovering ? backgroundColor.hovered : backgroundColor?.base;
	const currentTextColor = isHovering ? textColor?.hovered : textColor?.base;
	const currentBorderColor = isHovering ? borderColor?.hovered : borderColor?.base;

	return (
		<div
			ref={ref}
			className={`ColorLabel ${className}`}
			data-testid="ColorLabel"
			style={{
				backgroundColor: currentBackgroundColor,
				color: currentTextColor,
				width: 'fit-content',
				border: `1px solid ${withBorder ? currentBorderColor : 'transparent'}`
			}}
			onMouseEnter={() => { setIsHovering( true ); onMouseEnter?.(); }}
			onMouseLeave={() => { setIsHovering( false ); onMouseLeave?.(); }}
		>
			{text}
		</div>
	);
} );

export default ColorLabel;
