import { QueryClient } from '@tanstack/react-query';
import { LabelsUpdates } from '@labels/types';
import { LabelAssignableJSON } from '@labels/models/labelAssignable';
import QueryClientUpdater, { ReduxStore } from './QueryClientUpdater';
import ReduxStoreUpdater from './ReduxStoreUpdater';
import { UserJson } from '../models/user/user';
import { AssetJSON } from '../modules/projectView/models/asset';
import { FolderJSON, OrganizerType } from '../modules/projectView/models/folder';
import { idsFrom } from '../lib/modelsUtils';

const updateLabelsFor = <T extends LabelAssignableJSON>( item: T, updates: LabelsUpdates ): T => {
	const labels = ( item.labels || [] )
		.filter( label => !idsFrom( updates.delete ).includes( label.id ) )

	updates.add.forEach( ( label ) => {
		if ( idsFrom( labels ).includes( label.id ) ) { return; }
		labels.push( label.toJSON() );
	} );
	return ( {
		...item,
		labels
	} );
}

export default class ReduxTanStackSync {
	reduxStoreUpdater: ReduxStoreUpdater;
	queryClientUpdater: QueryClientUpdater;

	constructor( { store, queryClient } : { store: ReduxStore, queryClient: QueryClient } ) {
		this.reduxStoreUpdater = new ReduxStoreUpdater( store.dispatch );
		this.queryClientUpdater = new QueryClientUpdater( { store, queryClient } );

		this.queryClientUpdater.start();
	}

	currentUser( currentUser: UserJson ) {
		this.reduxStoreUpdater.updateCurrentUser( currentUser );
	}

	asset( asset: AssetJSON ) {
		this.reduxStoreUpdater.createOrUpdateAsset( asset, asset.project_id );
	}

	assets( assets: AssetJSON[], { projectID, replaceAll = false }: { projectID: number, replaceAll?: boolean } ) {
		this.reduxStoreUpdater.replaceProjectAssets( assets, { projectID, replaceAll } );
	}

	assetPrioritySet( assetID: number ) {
		this.reduxStoreUpdater.updateAsset(
			assetID,
			asset => ( { ...asset, rush_priority: true } )
		);
	}

	assetPriorityUnset( assetID: number ) {
		this.reduxStoreUpdater.updateAsset(
			assetID,
			asset => ( { ...asset, rush_priority: false } )
		);
	}

	assetsLabelsUpdated(
		{ assetIDs, folderIDs, updates }: {
			assetIDs: number[], folderIDs: number[], updates: LabelsUpdates
		}
	) {
		this.reduxStoreUpdater.updateAssets(
			assetIDs,
			asset => updateLabelsFor( asset, updates )
		);

		this.reduxStoreUpdater.updateFolders(
			folderIDs,
			folder => updateLabelsFor( folder, updates )
		);
	}

	folders(
		folders: FolderJSON[],
		{ organizerID, organizerType }: { organizerID: number, organizerType: OrganizerType }
	) {
		this.reduxStoreUpdater.replaceOrganizerFolders( folders, { organizerID, organizerType } );
	}

	markWorkspaceOnboardingAsClosed( workspaceID: number ) {
		this.reduxStoreUpdater.markWorkspaceOnboardingAsClosed( workspaceID );
	}
}
