import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import NullLabel from '@labels/models/nullLabel';

import Filters from '../entities/filters';
import { getCurrentUserID } from './currentUser';
import { getUsers } from './users';
import { getCurrentUserOwnWorkspace, getRawWorkspaces } from './workspaces';

import Project from '../entities/project';
import ProjectWithMetrics from '../entities/projectWithMetrics';
import ProjectRole from '../entities/projectRole';
import ProjectMember from '../entities/projectMember';
import Workspace from '../entities/workspace';
import { getProjectsInvitations } from './projectInvitations';
import { getSubscriptions } from './subscriptions';
import { getLabels, sortLabels } from './labels';
import { getTotalMembersCountByWorkspaceID } from './workspaceMembers';
import { isMobile } from '../lib/isMobile';
import SortOption from '../entities/sortOption';
import OrganizerOverview from '../entities/organizerOverview';
import { compareAsc } from '../lib/comparators';

const DEFAULT_PROJECTS_SORT_OPTION = SortOption.byRushPriority;

const getFilters = state => state.filters;
const getSort = state => state.sort;
const getSortAscending = state => state.sortAscending;
const getSelectedProjectIDs = ( _, props ) => props.selectedProjectIDs;
const getProjectMemberID = ( _, props ) => props.memberID;

export const getRawProjects = state => state.projects;
export const getRawProjectsMetrics = state => state.projectsMetrics;
const getRawActiveProjects = state => state.activeProjects;
const getRawProjectOverviews = state => state.projectOverviews;

const createProjectsFrom = (
	{ serializedProjects, serializedWorkspaces, subscriptions, labels }
) => serializedProjects
	.map( ( serializedProject ) => {
		const serializedWorkspace = serializedWorkspaces.get( serializedProject.workspace_id );
		if ( !serializedWorkspace ) { return null; }
		const subscription = subscriptions.get( serializedWorkspace.subscription );
		const workspace = Workspace.fromJSON( serializedWorkspace, subscription );
		const projectLabels = sortLabels( serializedProject.labels?.map( id => labels?.get( id ) ) );
		return Project.fromJSON( serializedProject, workspace, projectLabels );
	} )
	.filter( project => !!project );

export const getProjects = createSelector(
	[ getRawProjects, getRawWorkspaces, getSubscriptions, getLabels ],
	(
		projectsSerialization,
		workspacesSerialization,
		subscriptions,
		labels
	) => createProjectsFrom( {
		serializedProjects: projectsSerialization,
		serializedWorkspaces: workspacesSerialization,
		subscriptions,
		labels
	} )
);

export const getActiveProjects = createSelector(
	[ getRawActiveProjects, getRawWorkspaces, getSubscriptions, getLabels ],
	(
		activeProjectsSerialization,
		workspacesSerialization,
		subscriptions,
		labels
	) => createProjectsFrom( {
		serializedProjects: activeProjectsSerialization,
		serializedWorkspaces: workspacesSerialization,
		subscriptions,
		labels
	} )
);

export const getHomeProjects = createSelector(
	[
		getRawProjects, getRawProjectsMetrics, getRawWorkspaces,
		getTotalMembersCountByWorkspaceID, getSubscriptions, getLabels
	],
	(
		projectsSerialization, projectsMetricsSerialization, workspacesSerialization,
		totalMembersCountByWorkspaceID, subscriptions, labels
	) => projectsSerialization.map(
		( properties ) => {
			const workspaceProperties = workspacesSerialization.get( properties.workspace_id );
			if ( !workspaceProperties ) { return null; }
			const projectMetricsProperties = projectsMetricsSerialization.get( properties.id, {} );
			return ProjectWithMetrics.fromJSON(
				properties,
				projectMetricsProperties,
				Workspace.fromJSON(
					workspaceProperties,
					subscriptions.get( workspaceProperties.subscription ),
					totalMembersCountByWorkspaceID.get( workspaceProperties.id ),
					sortLabels( workspaceProperties.labels?.map( id => labels?.get( id ) ) )
				)
			);
		}
	).filter( project => !!project )
);

export const getProjectID = ( _, props ) => props.projectID;

export const getProject = createSelector(
	[ getProjects, getProjectID ],
	( projects, projectID ) => projects.get( projectID )
);

export const getLabelsForProject = createSelector(
	[ getProject ],
	project => project?.labels ?? []
);

const noLabelID = 'no_label';

export const getProjectOverview = createSelector(
	[ getProjectID, getRawProjectOverviews, getLabelsForProject ],
	( projectID, rawProjectOverviews, labels ) => {
		const rawOverview = rawProjectOverviews.get( projectID );
		labels = new Map( labels.map( label => [ label.id, label ] ) );

		if ( !rawOverview ) return undefined;

		let projectOverview;
		projectOverview = Object.keys( rawOverview ).filter( labelID => labelID !== noLabelID )
			.map( ( labelID ) => {
				labelID = parseInt( labelID, 10 );
				const label = labels?.get( labelID );
				const assetCount = rawOverview[ labelID ];

				return [ label, assetCount ]
			} )

		if ( rawOverview[ noLabelID ] ) {
			const noLabelOverview = [ new NullLabel(), rawOverview[ noLabelID ] ];
			projectOverview = [ ...projectOverview, noLabelOverview ];
		}

		projectOverview.sort( ( a, b ) => compareAsc( b[ 1 ], a[ 1 ] ) );

		return new OrganizerOverview( projectOverview );
	}
);

export const getActiveProject = createSelector(
	[ getActiveProjects, getProjectID ],
	( activeProjects, projectID ) => activeProjects.get( projectID ) ?? null
);

export const getHomeProject = createSelector(
	[ getHomeProjects, getProjectID ],
	( projects, projectID ) => projects.get( projectID )
);

export const getFilteredProjects = createSelector(
	[ getRawProjects, getFilters ],
	( projects, filters ) => new Filters( filters ).apply( projects )
);

export const getFilteredAndSortedProjects = createSelector(
	[ getFilteredProjects, getSort, getSortAscending ],
	( projects, sort, ascending ) => {
		const sortDirection = isMobile || ascending;
		const sortOption = isMobile ? DEFAULT_PROJECTS_SORT_OPTION : sort;

		const sortedProjects = projects.sort( sortOption && sortOption.comparator );
		return sortDirection ? sortedProjects : sortedProjects.reverse();
	}
);

export const getRawProjectsMembers = state => state.projectsMembers;

export const getProjectsMembers = createSelector(
	[ getRawProjectsMembers, getUsers ],
	( projectMembers, users ) => projectMembers
		.map( member => ProjectMember.fromJSON(
			member,
			users.get( member.user )
		) )
		.filter( member => !!member.user )
);

export const getProjectMembersMapForProject = createSelector(
	[ getProjectID, getProjectsMembers ],
	( projectID, projectMembers ) => projectMembers
		.filter( member => member.projectID === projectID )
		.sortBy( member => member.user.name )
);

export const getProjectMembersForProject = createSelector(
	[ getProjectMembersMapForProject ],
	projectMembersMap => projectMembersMap.toArray()
);

export const getProjectMembersForSelectedProjects = createSelector(
	[ getSelectedProjectIDs, getProjectsMembers ],
	( selectedProjectIDs, projectMembers ) => projectMembers
		.filter( member => selectedProjectIDs.includes( member.projectID ) )
		.toArray()
);

export const getCurrentUserMemberForProject = createSelector(
	[ getProjectMembersForProject, getCurrentUserID ],
	( projectsMembers, currentUserID ) => projectsMembers
		.find( member => member.userID === currentUserID )
);

export const getCurrentUserMembersForSelectedProjects = createSelector(
	[ getProjectMembersForSelectedProjects, getCurrentUserID ],
	( projectsMembers, currentUserID ) => projectsMembers
		.filter( member => member.userID === currentUserID )
);

export const getCommentAuthorMember = createSelector(
	[
		( _, props ) => props.comment,
		getProjectMembersForProject
	],
	( comment, projectsMembers ) => projectsMembers
		.find( member => member && comment && member.userID === comment.authorID )
);

export const getProjectMembersAsUsers = createSelector(
	[ getProjectMembersForProject ],
	members => members.map( member => member.user )
);

export const getProjectOwner = createSelector(
	[ getProjectMembersForProject ],
	( members ) => {
		const owner = members.find( member => member.isOwner );
		return owner;
	}
);

export const getProjectMember = createSelector(
	[ getProjectMembersForProject, getProjectMemberID ],
	( members, memberID ) => members.find( member => member.id === memberID )
);
export const getProjectInvitations = createSelector(
	[ getProjectID, getProjectsInvitations ],
	( projectID, projectInvitations ) => projectInvitations.valueSeq().toArray()
		.filter( invitation => invitation.projectID === projectID )
);

export const getCurrentUser = state => state.currentUser;

export const getCurrentUserCanManageProjectMembers = createSelector(
	[ getProjectMembersForProject, getCurrentUser ],
	( members, currentUser ) => {
		const currentUserMember = members.find( member => member.userID === currentUser.userID );
		return !!currentUserMember
			&& ( currentUserMember.isOwner || currentUserMember.isProjectCollaborator );
	}
);

export const getCurrentUserCanDeleteProject = getCurrentUserCanManageProjectMembers;

export const getRoleFromProject = project => (
	( !!project && project.currentUserRole )
	|| ProjectRole.projectCollaborator()
);

export const getCurrentUserRoleForProject = createSelector(
	[ getProject ],
	getRoleFromProject
);

export const getSelectedProjects = createSelector(
	[ getSelectedProjectIDs, getHomeProjects ],
	( selectedProjectIDs, projects ) => selectedProjectIDs
		.map( ID => projects.get( ID ) )
		.filter( project => !!project )
);

export const getProjectName = createSelector(
	[ getProject ],
	project => ( project ? project.name : undefined )
);

export const getSortedProjectMembers = createSelector(
	[ getProjectMembersForProject ],
	projectMembers => projectMembers
		.sort(
			firstBy( member => member.role.level )
				.thenBy( member => member.user.name, { ignoreCase: true } )
		)
);

export const getSortedProjectInvitations = createSelector(
	[ getProjectInvitations ],
	projectInvitations => projectInvitations
		.sort(
			firstBy( invitation => invitation.role && invitation.role.level )
				.thenBy( 'email', { ignoreCase: true } )
		)
);

const doesProjectBelongToWorkspace = ( { project, workspace } ) => (
	!!project && !!workspace && project.workspaceID === workspace.id
);

export const getProjectBelongsToOwnWorkspace = createSelector(
	[ getCurrentUserOwnWorkspace, getProject ],
	( workspace, project ) => doesProjectBelongToWorkspace( { project, workspace } )
);

export const getActiveProjectBelongsToOwnWorkspace = createSelector(
	[ getCurrentUserOwnWorkspace, getActiveProject ],
	( workspace, activeProject ) => doesProjectBelongToWorkspace( { project: activeProject, workspace } )
);

export const getFolderProject = ( state, props ) => (
	getProject( state, { projectID: props.folder.projectID } )
);
