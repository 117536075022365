import { useQuery } from '@tanstack/react-query';
import { queryClient as globalQueryClient } from 'src/store/store';
import useProjectSystem from '../systems/useProjectSystem';
import { QueryKeys } from './queryKeys';
import { DEFAULT_QUERY_OPTIONS } from './constants';

const useFetchProjectCountsQuery = () => {
	const system = useProjectSystem();

	return useQuery(
		QueryKeys.forProjectCounts(),
		() => system.fetchProjectCounts(),
		DEFAULT_QUERY_OPTIONS
	);
};

export const invalidateProjectCountsQuery = () => {
	globalQueryClient.invalidateQueries( {
		queryKey: QueryKeys.forProjectCounts(),
		refetchType: 'active'
	} );
}

export default useFetchProjectCountsQuery;
