import { ProjectJSON, ProjectStatus } from '@projects/models/project';
import { ProjectMemberJSON } from '@projects/models/projectMember';
import ReduxTanStackSync from 'src/new_arch/services/ReduxTanStackSync';
import { FOLDERS_URL, PROJECTS_URL } from '../../../../config/urls';
import Api from '../../../../services/Api';

import AssetFilters from '../../projectView/models/filtersAndSorts/assetFilters';
import ItemSorting from '../../projectView/models/filtersAndSorts/itemSorting';
import FolderTreeJson from '../../projectView/models/folderTreeJson';

type ItemsCountFetchParams = {
	projectID: number,
	folderID: number | null,
	filters?: AssetFilters,
	sorting?: ItemSorting
};

type ItemsCountFetchResult = {
	count: number
}

type ProjectCountsJSON = {
	active: number,
	archived: number
}

export default class ProjectSystem {
	private api: Api;
	private sync: ReduxTanStackSync;

	constructor( { api, sync }: { api: Api, sync: ReduxTanStackSync } ) {
		this.api = api;
		this.sync = sync;
	}

	async fetchProjectCounts(): Promise<ProjectCountsJSON> {
		const projectCountsUrl = `${PROJECTS_URL}/counts`;
		const { response } = await ( this.api.get( projectCountsUrl ) as Promise<{ response: ProjectCountsJSON}> );
		return response;
	}

	async fetchProjects( { status }: { status: ProjectStatus } ): Promise<ProjectJSON[]> {
		const projectsUrl = `${PROJECTS_URL}/${status === 'hidden' ? 'archived' : 'active'}`;
		const { response } = await ( this.api.get( projectsUrl ) as Promise<{ response: ProjectJSON[] }> );
		return response;
	}

	async fetchItemsCount( {
		projectID,
		folderID,
		filters
	}: ItemsCountFetchParams ): Promise<ItemsCountFetchResult> {
		const baseUrl = this.baseUrlFor( { projectID, folderID } );
		const queryParams = this.itemsCountQueryParamsFor( { forFolders: !!folderID, filters } );
		const itemsCountURL = `${baseUrl}/items_count?${queryParams}`;

		const { response } = await (
			this.api.get( itemsCountURL ) as Promise<{ response: ItemsCountFetchResult }>
		);

		return response;
	}

	async fetchProjectMembers( { projectID }: { projectID: number } ) {
		const membersURL = `${PROJECTS_URL}/${projectID}/members`;

		const { response } = await (
			this.api.get( membersURL ) as Promise<{ response: ProjectMemberJSON[] }>
		);

		return response;
	}

	async fetchFolderTree( projectID: number ): Promise<FolderTreeJson> {
		const baseUrl = this.baseUrlFor( { projectID, folderID: null } );
		const folderTreeUrl = `${baseUrl}/folders_tree`;

		const { response } = await (
			this.api.get( folderTreeUrl ) as Promise<{ response: FolderTreeJson }>
		);

		return response;
	}

	async fetchIncludedFeatures( projectID: number ) {
		const baseUrl = this.baseUrlFor( { projectID, folderID: null } );
		const includedFeaturesUrl = `${baseUrl}/included_features`;

		const { response } = await (
			this.api.get( includedFeaturesUrl ) as Promise<{ response: string[] }>
		);

		return response;
	}

	pinProject( projectID: number ) {
		return this.pinOrUnpinProject( projectID, 'pin' );
	}

	unpinProject( projectID: number ) {
		return this.pinOrUnpinProject( projectID, 'unpin' );
	}

	private async pinOrUnpinProject( projectID: number, action: 'pin' | 'unpin' ) {
		const baseUrl = this.baseUrlFor( { projectID, folderID: null } );

		return ( this.api.post( `${baseUrl}/${action}` ) as Promise<unknown> );
	}

	private baseUrlFor( { projectID, folderID }: { projectID: number, folderID: number | null } ) {
		return folderID
			? `${FOLDERS_URL}/${folderID}`
			: `${PROJECTS_URL}/${projectID}`;
	}

	private itemsCountQueryParamsFor( {
		forFolders, filters
	}: {
		forFolders: boolean, filters?: AssetFilters
	} ) {
		const params = new URLSearchParams( {} );
		const filtersParamName = forFolders ? 'asset_filters' : 'filters';

		if ( filters ) params.append( filtersParamName, filters.toJsonString() );

		return params.toString();
	}
}
