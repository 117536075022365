export type ProjectStatus = 'active' | 'hidden';

export type ProjectJSON = {
	id: number,
	name: string,
	is_suspended: boolean,
	is_pinned: boolean
}

export type ProjectParams = {
	id: number,
	name: string,
	isPinned: boolean,
	isSuspended: boolean
};

export default class Project {
	id: number;
	name: string;
	isPinned: boolean;
	isSuspended: boolean;

	constructor( params: ProjectParams ) {
		this.id = params.id;
		this.name = params.name;
		this.isPinned = params.isPinned;
		this.isSuspended = params.isSuspended;
	}

	static fromJson( json: ProjectJSON ) {
		return new Project( {
			id: json.id,
			name: json.name,
			isPinned: json.is_pinned,
			isSuspended: json.is_suspended
		} );
	}

	static allFromJson( jsons: ProjectJSON[] ) {
		return jsons.map( Project.fromJson );
	}
}
