import _ from 'lodash';
import LabelAssignable, { LabelAssignableJSON } from '@labels/models/labelAssignable';
import OldArchAsset from 'src/entities/asset';
import Asset from 'src/new_arch/modules/projectView/models/asset';
import OldArchFolder from 'src/entities/folder';
import Folder from 'src/new_arch/modules/projectView/models/folder';

import { idsFrom } from '../../lib/modelsUtils';
import Label, { LabelJSON } from './models/label';
import { LabelsUpdates } from './types';

export const allHaveLabel = ( labelAssignables: LabelAssignable[], label: Label ) => (
	labelAssignables.every( labelAssignable => idsFrom( labelAssignable.labels ).includes( label.id ) )
);

export const allHaveTheSameLabels = ( labelAssignables: LabelAssignable[] ) => {
	const labelIds = idsFrom( labelAssignables.first.labels );
	return labelAssignables.every(
		labelAssignable => _.isEqual( idsFrom( labelAssignable.labels ), labelIds )
	);
};

export const allWithoutLabels = ( labelAssignables: LabelAssignable[] ) => (
	labelAssignables.every( labelAssignable => labelAssignable.labels.length === 0 )
);

export const isAssignedToAll = ( label: Label, labelAssignables: LabelAssignable[] ) => (
	labelAssignables.every( labelAssignable => idsFrom( labelAssignable.labels ).includes( label.id ) )
);

export const isAssignedToSome = ( label: Label, labelAssignables: LabelAssignable[] ) => (
	labelAssignables.some( labelAssignable => idsFrom( labelAssignable.labels ).includes( label.id ) )
);

export const sortByOrder = ( labels: Label[] ) => (
	labels.sort( ( aLabel, otherLabel ) => (
		aLabel.order !== undefined && otherLabel.order !== undefined
			? aLabel.order - otherLabel.order
			: aLabel.id - otherLabel.id
	) )
);

export const updateLabelsWithOrder = ( {
	labels, orderedLabelIds
}: { labels: LabelJSON[], orderedLabelIds: number[] } ) => {
	if ( !orderedLabelIds ) return labels;

	return labels.map( label => ( {
		...label,
		order: ( orderedLabelIds.indexOf( label.id ) || 0 ) + 1
	} ) );
};

const isAsset = ( labelAssignable: LabelAssignable ) => (
	labelAssignable instanceof OldArchAsset || labelAssignable instanceof Asset
);

const isFolder = ( labelAssignable: LabelAssignable ) => (
	labelAssignable instanceof OldArchFolder || labelAssignable instanceof Folder
);

export const selectAssets = ( labelAssignables: LabelAssignable[] ) => (
	labelAssignables.filter( isAsset )
);

export const selectFolders = ( labelAssignables: LabelAssignable[] ) => (
	labelAssignables.filter( isFolder )
);

export const updateItemLabels = ( {
	item, updates, orderedLabelsIDs
} : { item: LabelAssignableJSON, updates: LabelsUpdates, orderedLabelsIDs?: number[] } ) => {
	const labelsToRemoveIds = idsFrom( updates.delete );
	const labels = ( item.labels || [] )
		.filter( label => !labelsToRemoveIds.includes( label.id ) )

	updates.add.forEach( ( label ) => {
		if ( idsFrom( labels ).includes( label.id ) ) { return; }
		labels.push( label.toJSON() );
	} );

	const sortedLabels = orderedLabelsIDs ? labels.map( label => ( {
		...label,
		order: orderedLabelsIDs.indexOf( label.id )
	} ) ).sort( ( a, b ) => a.order - b.order ) : labels;

	return ( {
		...item,
		labels: sortedLabels
	} );
};
