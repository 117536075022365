import { get, set, del } from 'idb-keyval';
import {
	PersistedClient,
	Persister
} from '@tanstack/react-query-persist-client'
import newRelic from 'src/newRelic';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
function createIDBPersister( idbValidKey: IDBValidKey = 'reactQuery' ) {
	return {
		persistClient: async ( client: PersistedClient ) => {
			try {
				await set( idbValidKey, client )
			} catch ( e ) {
				newRelic?.noticeError( e as string | Error )
			}
		},
		restoreClient: async () => get<PersistedClient>( idbValidKey ),
		removeClient: async () => {
			await del( idbValidKey )
		}
	} as Persister
}

export default createIDBPersister;
