import { forwardRef } from 'react';
import { ActionIcon, ActionIconProps, MantineSize } from '@mantine/core';
import Icon, { IconName } from '../Icon/Icon';

type ButtonProps = JSX.LibraryManagedAttributes<'button', React.ComponentPropsWithoutRef<'button'>>;
type IconButtonProps = { icon: IconName, size?: MantineSize | number } & ActionIconProps & ButtonProps;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	( { icon, variant = 'transparent', size, ...props }, ref ) => (
		<ActionIcon ref={ref} variant={variant} {...props}>
			<Icon icon={icon} size={size} />
		</ActionIcon>
	)
);

export default IconButton;
