import NullLabel from '@labels/models/nullLabel';
import FilterOption from '../entities/filterOption';
import LabelFilterOption from '../entities/LabelFilterOption';

export default class FilterFactory {
	static assetsFilters( labels ) {
		return [
			{
				label: 'Asset State',
				options: [
					FilterOption.byPriority,
					FilterOption.byApproved,
					FilterOption.byApprovalPending
				]
			},
			{
				label: 'Review Progress',
				options: [
					FilterOption.byComments,
					FilterOption.byUnresolvedComments
				]
			},
			{
				label: 'Label',
				options:
					this._labelsForAssetsFrom( labels )
						.map( label => label && new LabelFilterOption( label ) )
			}
		];
	}

	static assetsFiltersByID( labels ) {
		return {
			[ FilterOption.none.id ]: FilterOption.none,
			[ FilterOption.byPriority.id ]: FilterOption.byPriority,
			[ FilterOption.byApproved.id ]: FilterOption.byApproved,
			[ FilterOption.byApprovalPending.id ]: FilterOption.byApprovalPending,
			[ FilterOption.byComments.id ]: FilterOption.byComments,
			[ FilterOption.byUnresolvedComments.id ]: FilterOption.byUnresolvedComments,
			...this._labelFilterByID( labels )
		};
	}

	static _labelFilterByID( labels ) {
		if ( !labels ) { return undefined; }
		return this._labelsForAssetsFrom( labels ).reduce( ( filtersByID, label ) => {
			if ( !label ) return filtersByID;
			const filterByLabel = new LabelFilterOption( label );

			return { ...filtersByID, [ filterByLabel.id ]: filterByLabel };
		}, {} );
	}

	static _labelsForAssetsFrom( projectLabels ) {
		return [ new NullLabel(), ...projectLabels ];
	}
}
