import {
	createContext, ReactNode, useContext, useEffect, useState
} from 'react';
import { RootStore } from '../stores/RootStore';
import StorageService from '../services/StorageService';
import RootStorePersistor from '../stores/RootStorePersistor';
import IDBStorage from '../services/IDBStorage';

const rootStore: RootStore = new RootStore();
const storage = new StorageService( new IDBStorage() );

const RootStoreContext = createContext<RootStore>( rootStore );

interface RootStoreProviderProps {
	children: ReactNode,
	rootStore?: RootStore,
	fallback?: ReactNode,
	disablePersistance?: boolean
}

const RootStoreProvider = ( {
	children, rootStore: rootStoreParam, fallback = null, disablePersistance = false
}: RootStoreProviderProps ) => {
	const [ isRehydratingStore, setIsRehydratingStore ] = useState( !disablePersistance );

	useEffect(
		() => {
			if ( disablePersistance ) return;
			const persistor = new RootStorePersistor( { rootStore, storage } );
			setIsRehydratingStore( true );
			persistor.hydrate().then( () => setIsRehydratingStore( false ) );
		},
		[ rootStoreParam ]
	);

	return (
		<>
			{!isRehydratingStore ? (
				<RootStoreContext.Provider value={rootStoreParam || rootStore}>
					{children}
				</RootStoreContext.Provider>
			) : fallback}
		</>
	);
};

export const useRootStore = () => useContext( RootStoreContext );

export default RootStoreProvider;
