import Asset from '../asset';
import LabelFilter from './labelFilter';
import StateFilter from './stateFilter';
import { FilterJSON, isLabelFilterJSON } from './types';

export default class FilterOption {
	id!: string;
	isPassedBy!: ( asset: Asset ) => boolean;
	filterAmount!: number;
	toJson!: () => FilterJSON;

	static fromJson( json: FilterJSON ): FilterOption | null {
		if ( isLabelFilterJSON( json ) ) {
			return LabelFilter.fromJson( json );
		}

		return StateFilter.fromJson( json );
	}
}
