import ImmutableEntity from './immutableEntity';
import LabelGroup from './labelGroup';
import { WorkspaceStatusFactory } from './workspaceStatus';
import WorkspaceRole from './workspaceRole';

const FALLBACK_CURRENT_USER_ROLE = WorkspaceRole.collaborator().id;
export const YEARLY_INTERVAL = 'year';
export const MONTHLY_INTERVAL = 'month';

export const MAXIMUM_SUBSCRIPTION_PROJECTS_COUNT = Infinity;
export const MAXIMUM_SUBSCRIPTION_MEMBERS_COUNT = 15;

export default class Workspace extends ImmutableEntity {
	constructor(
		id,
		name,
		logo,
		owner,
		currentRoleId,
		projectsCount,
		subscription,
		membersCount,
		labels = [],
		storageUsedInBytes,
		contactsGroups = [],
		labelGroups = [],
		onboardingWasClosed = false
	) {
		super();

		this.id = id;
		this.name = name;
		this.logo = logo;
		this.owner = owner;
		this.currentRoleId = currentRoleId;
		this.projectsCount = projectsCount;
		this.subscription = subscription;
		this.membersCount = membersCount || 0;
		this.labels = labels;
		this.storageUsedInBytes = storageUsedInBytes;
		this.contactsGroups = contactsGroups;
		this.labelGroups = labelGroups;
		this.onboardingWasClosed = onboardingWasClosed;
	}

	static fromJSON( properties, subscription, membersCount, labels ) {
		return new Workspace(
			properties.id,
			properties.name,
			properties.logo,
			properties.owner,
			properties.current_user_role?.type || FALLBACK_CURRENT_USER_ROLE,
			properties.projects_count || 0,
			subscription,
			membersCount,
			labels,
			properties.storage_used_in_bytes,
			properties.contact_groups,
			properties.label_groups?.map( LabelGroup.fromJSON ),
			properties.onboarding_was_closed
		);
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			logo: this.logo,
			owner: this.owner,
			current_user_role: this.currentRoleId,
			subscription: this.subscription,
			labels: this.labels.map( label => label?.toJSON() ),
			storage_used_in_bytes: this.storageUsedInBytes,
			contact_groups: this.contactsGroups,
			label_groups: this.labelGroups?.map( group => group.toJSON() ),
			onboarding_was_closed: this.onboardingWasClosed
		};
	}

	clone() {
		return new Workspace(
			this.id,
			this.name,
			this.logo,
			this.owner,
			this.currentRoleId,
			this.projectsCount,
			this.subscription,
			this.membersCount,
			this.labels,
			this.storageUsedInBytes,
			this.contactsGroups,
			this.labelGroups,
			this.onboardingWasClosed
		);
	}

	get includedStorage() {
		return this.subscription.includedStorage;
	}

	get storageAvailable() {
		return this.includedStorage - this.storageUsedInBytes;
	}

	get storageAddOnQuantity() {
		return this.subscription.storageAddOnQuantity;
	}

	get hasStorageAddOns() {
		return this.storageAddOnQuantity > 0;
	}

	get storageAddOnIncludedAmount() {
		return this.subscription.storageAddOnIncludedAmount;
	}

	get extraStorage() {
		return this.subscription.extraStorage;
	}

	get includedMembers() {
		return this.subscription.includedMembers;
	}

	get membersAddOnQuantity() {
		return this.subscription.membersAddOnQuantity;
	}

	get hasMembersAddOns() {
		return this.membersAddOnQuantity > 0;
	}

	get extraMembers() {
		return this.subscription.extraMembers;
	}

	get membersAddOnsCost() {
		return this.subscription.membersAddOnsCost;
	}

	get storageAddOnsCost() {
		return this.subscription.storageAddOnsCost;
	}

	get currentUserCanCreateProject() {
		return [ WorkspaceRole.owner().id, WorkspaceRole.admin().id ]
			.includes( this.currentRoleId );
	}

	get currentUserIsOwner() {
		return WorkspaceRole.owner().id === this.currentRoleId;
	}

	get currentUserCanShareGuestLink() {
		return [ WorkspaceRole.owner().id, WorkspaceRole.admin().id ]
			.includes( this.currentRoleId );
	}

	get userCanListAllMembers() {
		return this.currentRoleId !== FALLBACK_CURRENT_USER_ROLE;
	}

	get status() {
		return WorkspaceStatusFactory.statusForWorkspace( this );
	}

	get isActive() {
		return this.status.isActiveStatus;
	}

	get hasSubscription() {
		return !!this.subscription;
	}

	get hasAPaidPlan() {
		return this.subscription.isAPaidSubscription;
	}

	get hasADowngradePending() {
		return !!this.subscription.downgradeAtPeriodEnd;
	}

	get isSubscriptionActive() {
		return this.hasSubscription && this.subscription.active;
	}

	get isSubscriptionMarkedForCancellation() {
		return this.hasSubscription && this.subscription.cancelAtPeriodEnd
			&& !this.hasADowngradePending;
	}

	get subscriptionCanBeCancelled() {
		return this.hasSubscription && this.subscription.canBeCancelled;
	}

	get subscriptionInterval() {
		return this.subscription && this.subscription.billingPeriod;
	}

	get hasYearlySubscriptionInterval() {
		return this.subscriptionInterval === YEARLY_INTERVAL;
	}

	get isNullWorkspace() {
		return false;
	}

	get hasAMonthlyBillingPeriod() {
		return this.subscription?.billingPeriodIsMonthly;
	}

	get subscriptionProjectsCount() {
		return this.subscription && this.subscription.includedProjects;
	}

	get subscriptionMaxProjectCount() {
		return ( this.subscription && this.subscription.includedProjects )
			|| MAXIMUM_SUBSCRIPTION_PROJECTS_COUNT;
	}

	get subscriptionLastPaymentSucceeded() {
		return this.hasSubscription && this.subscription.lastPaymentSucceeded;
	}

	get billingPeriodEndsAt() {
		return this.subscription && this.subscription.billingPeriodEndsAt;
	}

	get daysUntilDowngrade() {
		return this.subscription.daysUntilDowngrade;
	}

	get isOnFreeTrial() {
		return this.subscription.isOnFreeTrial;
	}

	get isOnActiveFreeTrial() {
		return this.isOnFreeTrial && this.isSubscriptionActive;
	}

	get daysLeftOnFreeTrial() {
		return this.subscription.daysLeftOnFreeTrial;
	}

	get showPendingDowngradeBanner() {
		return this.subscription.showPendingDowngradeBanner;
	}

	prorateCostToEndOfBillingPeriod( cost ) {
		return this.subscription.prorateCostToEndOfBillingPeriod( cost );
	}

	get isSuspended() {
		return !this.isSubscriptionActive && this.hasSubscription;
	}

	get isSuspendedForDowngrade() {
		return this.subscription.isSuspendedForDowngrade;
	}

	get isOnExpiredFreeTrial() {
		return this.isSuspended && this.isOnFreeTrial;
	}

	get subscriptionPlanName() {
		return this.subscription && this.subscription.planName;
	}

	get subscriptionPlanCost() {
		return this.subscription && this.subscription.planCost;
	}

	get hasRoomForProjects() {
		return this.projectsCount < this.subscriptionMaxProjectCount;
	}

	get availableProjects() {
		return this.subscriptionMaxProjectCount - this.projectsCount;
	}

	get pricingPlanPrice() {
		return this.subscription.pricingPlanPrice;
	}

	get pricingPlan() {
		return this.subscription.pricingPlan;
	}

	get showWarningIcon() {
		return this.isSuspended;
	}

	userIsOwner( userID ) {
		return this.owner.id === userID;
	}

	setOwner( user ) {
		return this.set( 'owner', user );
	}

	canTransferOwnership( members ) {
		return this.isActive && members.length > 1;
	}

	canInviteMembers( desiredMembersAmount = 1 ) {
		return this._hasRoomForMembers( desiredMembersAmount ) && this.subscription.allowsMemberInvitations;
	}

	_hasRoomForMembers( desiredMembersAmount ) {
		return desiredMembersAmount <= this.remainingSeats;
	}

	get subscriptionMaxMembersCount() {
		return parseInt( ( this.subscription && `${this.subscription.includedMembers}` )
				|| MAXIMUM_SUBSCRIPTION_MEMBERS_COUNT, 10 );
	}

	get remainingSeats() {
		return this.subscriptionMaxMembersCount - this.membersCount;
	}

	get planName() {
		return this.subscription.planName;
	}

	get currentRoleIdToDisplay() {
		return this._roleNameToDisplayByID[ this.currentRoleId ];
	}

	get timeLeftUntilBillingPeriodEnd() {
		return this.subscription.timeLeftUntilBillingPeriodEnd;
	}

	get _roleNameToDisplayByID() {
		return {
			[ WorkspaceRole.admin().id ]: 'Member',
			[ WorkspaceRole.owner().id ]: 'Owner'
		};
	}

	get aviableLabels() {
		return this.labels;
	}

	addAddOnsWith( addOnsSystem ) {
		this.subscription.addAddOnsWith( addOnsSystem );
	}
}
