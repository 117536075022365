import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabelSystem from '@labels/systems/ReduxLabelSystem';

import LabelsGroupsModalPresenter from './LabelsGroupsModalPresenter';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import { getProject } from '../../selectors/projects';
import { getWorkspaceLabelGroups } from '../../selectors/labelGroups';

const useContactsGroupsModalPresenter = ( projectID : number ) => {
	const dispatch = useDispatch();
	const workspaceSystem : WorkspaceSystem = new WorkspaceSystem( dispatch );
	const labelSystem : LabelSystem = new LabelSystem( dispatch );

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const project = useSelector( state => getProject( state, { projectID } ) );

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const labelGroups = useSelector( state => getWorkspaceLabelGroups( state, {
		workspaceID: project.workspaceID
	} ) );

	const createPresenter = () => new LabelsGroupsModalPresenter( {
		project,
		labelGroups,
		labelSystem,
		workspaceSystem
	} );

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'project', value: project } );
	useAutoupdatePresenterProperty( { presenter, name: 'labelGroups', value: labelGroups } );

	return presenter;
};

export default useContactsGroupsModalPresenter;
