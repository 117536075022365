export interface StorageType {
	getItem: ( key: string ) => Promise<string | null>
	setItem: ( key: string, value: string ) => Promise<void>
	removeItem: ( key: string ) => Promise<void>
}

interface Options {
	expirationInDays: number
}

export default class StorageService {
	storage: StorageType;

	constructor( storage: StorageType ) {
		this.storage = storage;
	}

	async getItem( key: string ) {
		const expired = await this.clearItemIfExpired( key );
		return expired ? null : this.storage.getItem( key );
	}

	async setItem( key: string, value: string, options?: Options ) {
		await this.storage.setItem( key, value );
		this.processSetItemOptions( key, options );
	}

	async removeItem( key: string ) {
		await this.storage.removeItem( key );
	}

	private processSetItemOptions( key: string, options?: Options ) {
		if ( options?.expirationInDays ) {
			this.storeExpirationDate( key, options.expirationInDays );
		} else {
			this.removeItem( this.getExpirationDateKey( key ) );
		}
	}

	private storeExpirationDate( keyToExpire: string, expirationTimeInDays: number ) {
		const key = this.getExpirationDateKey( keyToExpire );
		const expirationDate = this.computeExpirationDate( expirationTimeInDays );
		this.storage.setItem( key, expirationDate.toJSON() );
	}

	private computeExpirationDate( expirationTimeInDays: number ) {
		const result = new Date();
		result.setDate( result.getDate() + expirationTimeInDays );
		return result;
	}

	private getExpirationDateKey( key: string ) {
		return `meta-${key}-expiration`;
	}

	private async clearItemIfExpired( key: string ) {
		const expirationDateKey = this.getExpirationDateKey( key );
		const expiration = await this.storage.getItem( expirationDateKey );
		if ( this.isDateExpired( expiration ) ) {
			await this.removeItem( key );
			await this.removeItem( expirationDateKey );
			return true;
		}
		return false;
	}

	private isDateExpired( dateInJSON: string | null ) {
		if ( !dateInJSON ) return false;
		const expirationDate = new Date( dateInJSON );
		return expirationDate <= new Date();
	}
}
