import PropTypes from 'prop-types';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import { connectComponent } from '../../lib/connectComponent';
import {
	getUserCanEditWorkspaceMemberRole,
	getUserCanDeleteWorkspaceMember,
	getWorkspaceMemberOwnsProjects
}
	from '../../selectors/workspaces';
import WorkspaceMemberWithRole from '../../components/WorkspaceMemberWithRole/WorkspaceMemberWithRole';
import WorkspaceMember from '../../entities/workspaceMember';
import { VALID_ASSIGNABLE_ROLES, AVAILABLE_ROLES } from '../../entities/workspaceRole';
import Container from '../Container';
import { getCurrentUserID } from '../../selectors/currentUser';
import { textEndEllipsis } from '../../lib/textTruncate';
import { actionResultIsError } from '../../lib/reduxUtils';
import { isNotAllowedOrNotFound } from '../../errors/common';

export class WorkspaceMemberWithRoleContainer extends Container {
	constructor( props ) {
		super( props );

		this._onEditRoleClicked = this._onEditRoleClicked.bind( this );
		this._onDeleteClicked = this._onDeleteClicked.bind( this );
	}

	updateWorkspaceMemberRole( roleID ) {
		const { workspaceID, member, updateWorkspaceMemberRole } = this.props;
		const { showSuccessMessage, showErrorMessage } = SnackbarSystem.shared();

		const successMessage = { title: 'The user role has been successfully updated.' };
		const errorMessage = { title: 'Oops! something went wrong! Try again.' };

		return updateWorkspaceMemberRole( workspaceID, member.id, roleID )
			.then( ( result ) => {
				if ( actionResultIsError( result ) ) {
					throw result.payload;
				}

				showSuccessMessage( successMessage );
			} ).catch( error => !isNotAllowedOrNotFound( error ) && showErrorMessage( errorMessage ) );
	}

	deleteWorkspaceMember() {
		const { workspaceID, member, deleteWorkspaceMember } = this.props;
		const { showSuccessMessage, showErrorMessage } = SnackbarSystem.shared();

		const memberName = member.user.name;
		const successMessage = {
			title: `${textEndEllipsis( memberName )} Removed Successfully`,
			description: 'This person has been removed from the workspace members.'
		};
		const errorMessage = { title: 'Oops! something went wrong! Try again.' };

		return deleteWorkspaceMember( workspaceID, member.id )
			.then( ( result ) => {
				if ( actionResultIsError( result ) ) {
					throw result.payload;
				}

				showSuccessMessage( successMessage );
			} ).catch( error => !isNotAllowedOrNotFound( error ) && showErrorMessage( errorMessage ) );
	}

	_onEditRoleClicked( roleID ) {
		const { props: { member } } = this;

		if ( member.roleID === roleID ) return;

		// Show confirmation popup only if new role is limited
		if ( roleID === 'limited' ) {
			this.showAsyncActionConfirmation(
				`Edit ${member.user.name} workspace role?`,
				'Yes, confirm',
				'Cancel',
				() => this.updateWorkspaceMemberRole( roleID ),
				'Any projects owned by this person will be transferred to the Workspace owner.'
			);
		} else this.updateWorkspaceMemberRole( roleID );
	}

	_onDeleteClicked() {
		const { props: { member, memberOwnsProjects } } = this;

		const ownerDescription = 'This person will be removed from all projects and its ownership will be transferred to the Workspace Owner.';
		const nonOwnerDescription = 'This person will be removed from all projects associated with this workspace.';
		this.showAsyncActionConfirmation(
			`Remove ${textEndEllipsis( member.user.name )} from this workspace?`,
			'Remove',
			'Cancel',
			() => this.deleteWorkspaceMember(),
			memberOwnsProjects ? ownerDescription : nonOwnerDescription
		);
	}

	render() {
		const {
			props: { member, canEdit, canDelete }
		} = this;

		return (
			<WorkspaceMemberWithRole
				member={member}
				roles={member.isOwner ? AVAILABLE_ROLES : VALID_ASSIGNABLE_ROLES}
				canEdit={canEdit}
				onRoleChange={this._onEditRoleClicked}
				onDeleteClicked={this._onDeleteClicked}
				showDeleteIcon={canDelete}
			/>
		);
	}
}

WorkspaceMemberWithRoleContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	member: PropTypes.instanceOf( WorkspaceMember ),
	canEdit: PropTypes.bool,
	canDelete: PropTypes.bool,
	updateWorkspaceMemberRole: PropTypes.func,
	deleteWorkspaceMember: PropTypes.func,
	memberOwnsProjects: PropTypes.bool,
	currentUserID: PropTypes.number
};

WorkspaceMemberWithRoleContainer.defaultProps = {
	member: null,
	canEdit: true,
	canDelete: false,
	memberOwnsProjects: false,
	updateWorkspaceMemberRole: () => {},
	deleteWorkspaceMember: () => {},
	currentUserID: undefined
};

export default connectComponent( ( state, props ) => ( {
	canEdit: getUserCanEditWorkspaceMemberRole( state, props ),
	canDelete: getUserCanDeleteWorkspaceMember( state, props ),
	currentUserID: getCurrentUserID( state, props ),
	memberOwnsProjects: getWorkspaceMemberOwnsProjects( state, props )
} ), true )( WorkspaceMemberWithRoleContainer );
