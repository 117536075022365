import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { middleware as reduxPackMiddleware } from 'redux-pack';

import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';

import { QueryClient } from '@tanstack/react-query';
import loggerMiddleware from './middlewares/logger';

import Api from '../services/Api';
import createRootReducer from '../reducers/index';
import history from '../navigation/history';

import createMapReducersTransform from './transforms/createMapReducersTransform';
import GuestHashStore from './GuestHashStore';
import createIndexDBStorage from './indexedDBStorage';

export const queryClient = new QueryClient( {
	defaultOptions: {
		queries: {
			cacheTime: 1000 * 60 * 60 * 24 // 24 hours,
		}
	}
} );

export const api = new Api();
export const guestHashStore = new GuestHashStore();

const middlewares = [
	routerMiddleware( history ),
	thunk.withExtraArgument( api ),
	reduxPackMiddleware
];

if ( process.env.NODE_ENV === 'development' && !process.env.REACT_APP_DISABLE_REDUX_LOGGER ) {
	middlewares.push( loggerMiddleware );
}

const persistConfig = {
	key: 'root',
	storage: createIndexDBStorage( 'redux-root-store' ),
	transforms: [
		createMapReducersTransform( {
			whitelist: [
				'tasks',
				'assetRounds',
				'users',
				'projectInvitations',
				'projects',
				'projectsMetrics',
				'projectsMembers',
				'notifications',
				'comments',
				'commentsReplies',
				'activities',
				'workspaceInvitations',
				'workspaces',
				'workspacesMembers',
				'selectedNotificationsFilter',
				'subscriptions',
				'userPreferences',
				'folders',
				'pricingPlans',
				'pricingPlanPrices'
			]
		} ),
		immutableTransform( {
			whitelist: [
				'markupEditorEditingSettings',
				'projectsViewOptions',
				'assetsViewOptions',
				'assetsFilterAndSortOptions',
				'commentsViewFilter',
				'closedBanners'
			]
		} )
	],
	whitelist: [
		'comments', 'commentsReplies', 'notifications',
		'projects', 'projectsMetrics', 'workspaces', 'workspacesMembers', 'tasks', 'users',
		'projectsMembers', 'currentUser', 'markupEditorEditingSettings',
		'activities', 'assetRounds', 'projectsViewOptions',
		'assetsViewOptions', 'projectInvitations', 'workspaceInvitations',
		'selectedNotificationsFilter', 'assetsFilterAndSortOptions',
		'commentsViewFilter', 'subscriptions', 'closedBanners', 'userPreferences', 'folders',
		'pricingPlans', 'pricingPlanPrices'
	]
};

const persistedReducer = persistReducer(
	persistConfig,
	createRootReducer( history )
);

export const store = createStore(
	persistedReducer,
	applyMiddleware( ...middlewares )
);

if ( window.Cypress ) {
	window.store = store;
}

export const persistor = persistStore( store );
