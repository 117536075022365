import moment from 'moment';
import LabelAssignable from '@labels/models/labelAssignable';
import Label, { LabellableType, type LabelJSON } from '@labels/models/label';

import OldArchAsset from '../../../../entities/asset';
import { getFileExtension, mapExtensionsForFileInput } from '../../../../lib/fileUtils';
import AssetRound, { type AssetRoundJSON } from './assetRound';
import { sortExtensions } from '../../../lib/extensionsUtils';
import { ASSETS_URL } from '../../../../config/urls';

type AssetParams = {
	id: number,
	projectID: number,
	folderID: number | null,
	name: string,
	currentRoundID: number,
	currentRound: AssetRound,
	roundCount: number,
	createdAt: Date,
	updatedAt: Date,
	lastActivityAt: Date,
	rushPrioritySetAt: Date | null,
	approvedAt: Date | null,
	labels: Label[],
	hasComments: boolean,
	newCommentsCount: number,
	newlyResolvedCommentsCount: number,
	unresolvedCommentsCount: number,
	isNewForCurrentUser: boolean,
	hasRoundsUnseenByCurrentUser: boolean,
	hasNewNameForCurrentUser: boolean,
	hasNewLabelForCurrentUser: boolean,
	unresolvedMentionsCount: number
};

export type AssetJSON = {
	id: number,
	project_id: number,
	folder_id: number | null,
	name: string,
	version_id: number,
	current_version: AssetRoundJSON,
	asset_versions_count: number,
	created_at: string,
	updated_at: string,
	last_activity_at: string,
	rush_priority_set_at: string | null,
	approved_at: string | null,
	labels: LabelJSON[],
	has_comments: boolean,
	new_comments_count: number,
	newly_approved_comments_count: number,
	unresolved_comments_count: number,
	is_new_for_current_user: boolean,
	has_rounds_unseen_by_current_user: boolean,
	has_new_name_for_current_user: boolean,
	has_new_label_for_current_user: boolean,
	unresolved_mentions_count?: number
};

export const ASSET_IMAGE_EXTENSIONS = [
	'tiff', 'tif', 'jpg', 'jpeg', 'png', 'psd', 'dng', 'crw',
	'cr2', 'nef', 'arw', 'psb', 'gif'
];

export const ASSET_DOCUMENT_EXTENSIONS = [
	'indd', 'ai', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'pdf', 'txt', 'eip'
];

export const ASSET_IMAGE_EXTENSIONS_SORTED = sortExtensions( ASSET_IMAGE_EXTENSIONS );
export const ASSET_DOCUMENT_EXTENSIONS_SORTED = sortExtensions( ASSET_DOCUMENT_EXTENSIONS );

export const ASSET_ALL_EXTENSIONS = [
	...ASSET_IMAGE_EXTENSIONS,
	...ASSET_DOCUMENT_EXTENSIONS
];

export default class Asset implements LabelAssignable {
	id: number;
	projectID: number;
	folderID: number | null;
	name: string;
	currentRoundID: number;
	currentRound: AssetRound;
	roundCount: number;
	createdAt: Date;
	updatedAt: Date;
	lastActivityAt: Date;
	rushPrioritySetAt: Date | null;
	approvedAt: Date | null;
	labels: Label[];
	hasComments: boolean;
	newCommentsCount: number;
	newlyResolvedCommentsCount: number;
	unresolvedCommentsCount: number;
	isNewForCurrentUser: boolean;
	hasRoundsUnseenByCurrentUser: boolean;
	hasNewNameForCurrentUser: boolean;
	hasNewLabelForCurrentUser: boolean;
	unresolvedMentionsCount: number;

	constructor( params: AssetParams ) {
		this.id = params.id;
		this.projectID = params.projectID;
		this.folderID = params.folderID;
		this.name = params.name;
		this.currentRoundID = params.currentRoundID;
		this.currentRound = params.currentRound;
		this.roundCount = params.roundCount;
		this.createdAt = params.createdAt;
		this.updatedAt = params.updatedAt;
		this.lastActivityAt = params.lastActivityAt;
		this.rushPrioritySetAt = params.rushPrioritySetAt;
		this.approvedAt = params.approvedAt;
		this.labels = params.labels;
		this.hasComments = params.hasComments;
		this.newCommentsCount = params.newCommentsCount;
		this.newlyResolvedCommentsCount = params.newlyResolvedCommentsCount;
		this.unresolvedCommentsCount = params.unresolvedCommentsCount;
		this.isNewForCurrentUser = params.isNewForCurrentUser;
		this.hasRoundsUnseenByCurrentUser = params.hasRoundsUnseenByCurrentUser;
		this.hasNewNameForCurrentUser = params.hasNewNameForCurrentUser;
		this.hasNewLabelForCurrentUser = params.hasNewLabelForCurrentUser;
		this.unresolvedMentionsCount = params.unresolvedMentionsCount;
	}

	static fromJson( json: AssetJSON ) {
		return new Asset( {
			id: json.id,
			projectID: json.project_id,
			folderID: json.folder_id,
			name: json.name,
			currentRoundID: json.version_id,
			currentRound: AssetRound.fromJSON( json.current_version ),
			roundCount: json.asset_versions_count,
			createdAt: new Date( json.created_at ),
			updatedAt: new Date( json.updated_at ),
			lastActivityAt: new Date( json.last_activity_at ),
			rushPrioritySetAt: json.rush_priority_set_at ? new Date( json.rush_priority_set_at ) : null,
			approvedAt: json.approved_at ? new Date( json.approved_at ) : null,
			labels: Label.allFromJson( json.labels ),
			hasComments: json.has_comments,
			newCommentsCount: json.new_comments_count,
			newlyResolvedCommentsCount: json.newly_approved_comments_count,
			unresolvedCommentsCount: json.unresolved_comments_count,
			isNewForCurrentUser: json.is_new_for_current_user,
			hasRoundsUnseenByCurrentUser: json.has_rounds_unseen_by_current_user,
			hasNewNameForCurrentUser: json.has_new_name_for_current_user,
			hasNewLabelForCurrentUser: json.has_new_label_for_current_user,
			unresolvedMentionsCount: json.unresolved_mentions_count || 0
		} );
	}

	static allFromJson( jsons: AssetJSON[] ) {
		return jsons.map( Asset.fromJson );
	}

	toJson(): AssetJSON {
		return {
			id: this.id,
			project_id: this.projectID,
			folder_id: this.folderID,
			name: this.name,
			version_id: this.currentRoundID,
			current_version: this.currentRound.toJson(),
			asset_versions_count: this.roundCount,
			created_at: this.createdAt.toISOString(),
			updated_at: this.updatedAt.toISOString(),
			last_activity_at: this.lastActivityAt.toISOString(),
			rush_priority_set_at: this.rushPrioritySetAt?.toISOString() || null,
			approved_at: this.approvedAt?.toISOString() || null,
			labels: this.labels.map( label => label.toJSON() ),
			has_comments: this.hasComments,
			new_comments_count: this.newCommentsCount,
			newly_approved_comments_count: this.newlyResolvedCommentsCount,
			unresolved_comments_count: this.unresolvedCommentsCount,
			is_new_for_current_user: this.isNewForCurrentUser,
			has_rounds_unseen_by_current_user: this.hasRoundsUnseenByCurrentUser,
			has_new_name_for_current_user: this.hasNewNameForCurrentUser,
			has_new_label_for_current_user: this.hasNewLabelForCurrentUser,
			unresolved_mentions_count: this.unresolvedMentionsCount
		}
	}

	get fileExtension() {
		return getFileExtension( this.name );
	}

	get fileURL() {
		return `${ASSETS_URL}/${this.id}/download`;
	}

	get isImage() {
		return ASSET_IMAGE_EXTENSIONS.includes( this.fileExtension );
	}

	get isDocument() {
		return ASSET_DOCUMENT_EXTENSIONS.includes( this.fileExtension );
	}

	get currentRoundNumber() {
		return this.currentRound?.number;
	}

	get thumbnailURL() {
		return this.currentRound?.thumbnailURL;
	}

	get hasRushPriority() {
		return !!this.rushPrioritySetAt;
	}

	get isApproved() {
		return !!this.approvedAt;
	}

	get isProcessing() {
		return this.currentRound?.isProcessing;
	}

	get hasUnresolvedComments() {
		return this.unresolvedCommentsCount > 0;
	}

	get hasUnresolvedMentions() {
		return this.unresolvedMentionsCount > 0;
	}

	get lastActivityToDisplay() {
		return moment( this.lastActivityAt ).format( 'MM/DD, h:mmA' );
	}

	get selectionKey() {
		return `asset-${this.id}`;
	}

	get validExtensionsOfType() {
		return mapExtensionsForFileInput(
			this.isImage
				? ASSET_IMAGE_EXTENSIONS
				: ASSET_DOCUMENT_EXTENSIONS
		);
	}

	get labellableID() { return this.projectID; }

	get labellableType() { return 'Project' as LabellableType; }

	get isEipFile() { return this.fileExtension.toLowerCase() === 'eip'; }

	get hasThumbnail() { return !this.isEipFile; }

	toOldArchAsset() {
		return new OldArchAsset(
			this.id,
			this.projectID,
			this.name,
			this.currentRoundID,
			this.isApproved,
			this.newCommentsCount,
			this.newlyResolvedCommentsCount,
			this.unresolvedCommentsCount,
			this.hasRoundsUnseenByCurrentUser,
			this.isNewForCurrentUser,
			this.hasNewNameForCurrentUser,
			this.hasNewLabelForCurrentUser,
			this.hasRushPriority,
			this.createdAt,
			this.updatedAt,
			this.currentRound,
			this.hasComments,
			this.lastActivityAt,
			this.roundCount,
			this.labels,
			this.folderID,
			this.unresolvedMentionsCount
		);
	}
}
