import { createSelector } from 'reselect';

import Asset from '../entities/asset';

import AssetRound from '../entities/assetRound';
import Pagination from '../structures/pagination';
import { getDirectFoldersCountForOrganizer, getFolderID, parseOrganizer } from './folders';

const getRawAssets = state => state.assets;
const getProjectID = ( _, props ) => props.projectID;

export const getAssetIDFromProps = ( _, props ) => ( props.asset?.id || props.assetID );
export const getSelectedAssetsIDs = ( _, props ) => (
	props.selectedAssets?.map( asset => asset.id ) || props.selectedAssetsIDs
);

export const getRawAssetsPaginationData = state => state.assetsPaginationData;

export const getAssetsPaginationData = createSelector(
	[ getRawAssetsPaginationData ],
	rawAssetsPaginationData => Pagination.fromJSON( rawAssetsPaginationData )
);

const getAssetRounds = createSelector(
	[ state => state.assetRounds ],
	assetRoundsSerialization => assetRoundsSerialization
		.map( AssetRound.fromJSON )
);

export const getAssets = createSelector(
	[ getRawAssets ],
	assetsSerialization => assetsSerialization?.map(
		properties => Asset.fromJSON( properties )
	)
);

export const getSelectedAssets = createSelector(
	[ getAssetIDFromProps, getSelectedAssetsIDs, getAssets ],
	( assetID, selectedAssetsIDs, assets ) => {
		const assetIDs = assetID ? [ assetID ] : selectedAssetsIDs;
		return assets.filter( asset => assetIDs.includes( asset.id ) );
	}
);

export const getAssetsWithCurrentRound = createSelector(
	[
		getAssets,
		getAssetRounds
	],
	( assets, assetsRounds ) => assets.map(
		asset => asset.withCurrentRound(
			assetsRounds.get( asset.currentRoundID )
		)
	)
);

export const getAssetsMapForProject = createSelector(
	[
		getAssetsWithCurrentRound,
		getProjectID,
		getFolderID,
		( _, props ) => props.filterByFolder
	],
	( assets, projectID, folderID, filterByFolder ) => assets
		.filter( ( asset ) => {
			if ( filterByFolder ) return asset.belongsToFolder( { projectID, folderID } );
			return asset.belongsToProject( projectID );
		} )
);

export const getAssetsForProject = createSelector(
	[ getAssetsMapForProject ],
	assetsMap => assetsMap.toArray()
);

export const getAssetIDsForProject = createSelector(
	[ getAssetsForProject ],
	assets => assets.map( asset => asset.id )
);

export const getImagesForProject = createSelector(
	[ getAssetsForProject ],
	assets => assets
		.filter( asset => asset.isImage )
);

export const getDocumentsForProject = createSelector(
	[ getAssetsForProject ],
	assets => assets
		.filter( asset => asset.isDocument )
);

export const getAssetID = ( _, props ) => props.assetID;

export const getAsset = createSelector(
	[ getAssets, getAssetRounds, getAssetID ],
	( assets, assetRounds, assetID ) => {
		const asset = assets.get( assetID );
		return asset && asset.withCurrentRound(
			assetRounds.get( asset.currentRoundID )
		);
	}
);

const getAssetsCount = ( _, props ) => props.assetsCount ?? 0;

const getDirectItemsCountForOrganizer = createSelector(
	[ getAssetsCount, getDirectFoldersCountForOrganizer ],
	( directAssetsCount, directFoldersCount ) => directAssetsCount + directFoldersCount
);

export const getDirectItemsCount = ( state, props ) => (
	getDirectItemsCountForOrganizer( state, parseOrganizer( props ) )
);

const getNextAssetIDFromArray = ( assetIDs, currentAssetID ) => {
	const assetIndex = assetIDs.findIndex( assetID => assetID === currentAssetID );
	if ( assetIDs.length <= 1 || assetIndex === -1 ) { return undefined; }

	const nextAssetID = assetIndex < assetIDs.length - 1
		? assetIDs[ assetIndex + 1 ]
		: assetIDs[ 0 ];

	return nextAssetID;
};

const getAssetIndexFromArray = ( assetIDs, currentAssetID ) => assetIDs.findIndex( id => id === currentAssetID );

const getPreviousAssetIDFromArray = ( assetIDs, currentAssetID ) => {
	const assetIndex = assetIDs.findIndex( assetID => assetID === currentAssetID );
	if ( assetIndex <= 0 ) { return undefined; }

	return assetIDs[ assetIndex - 1 ];
};

export const getNextAssetID = createSelector(
	[
		getAssetIDsForProject,
		getAssetID
	],
	( assetIDs, currentAssetID ) => (
		getNextAssetIDFromArray( assetIDs, currentAssetID )
	)
);

export const getAssetIndex = createSelector(
	[
		getAssetIDsForProject,
		getAssetID
	],
	( assetIDs, currentAssetID ) => ( getAssetIndexFromArray( assetIDs, currentAssetID ) )
);

export const getPreviousAssetID = createSelector(
	[
		getAssetIDsForProject,
		getAssetID
	],
	( assetIDs, currentAssetID ) => (
		getPreviousAssetIDFromArray( assetIDs, currentAssetID )
	)
);

export const getAssetIsApproved = createSelector(
	[ getAsset ],
	asset => asset && asset.isApproved
);

export const getAssetName = createSelector(
	[ getAsset ],
	asset => asset && asset.fileName
);

export const getCurrentRoundIDsForAssetsStillProcessingInProject = createSelector(
	[ getAssetsForProject ],
	assets => assets
		.filter( asset => asset.isProcessing )
		.map( asset => asset.currentRoundID )
);

const getFetchProjectAssetsRequest = state => state.fetchProjectAssetsRequest;
const getFetchFolderAssetsRequest = state => state.fetchFolderAssetsRequest;

const hasMetaAttributes = ( requestState, attributes ) => {
	const meta = requestState?.meta;
	if ( !meta ) return false;

	return Object.keys( attributes ).every(
		attributeName => meta[ attributeName ] === attributes[ attributeName ]
	);
};

export const getFetchAssetsRequest = createSelector(
	[
		getFetchProjectAssetsRequest,
		getFetchFolderAssetsRequest,
		getProjectID,
		getFolderID
	],
	( fetchProjectAssetsRequest, fetchFolderAssetsRequest, projectID, folderID ) => {
		if ( folderID && hasMetaAttributes( fetchFolderAssetsRequest, { projectID, folderID } ) ) {
			return fetchFolderAssetsRequest;
		}

		if ( !folderID && hasMetaAttributes( fetchProjectAssetsRequest, { projectID } ) ) {
			return fetchProjectAssetsRequest;
		}

		return undefined;
	}
);

export const getAssetsOfSameOrganizer = createSelector(
	[
		getAssetsForProject,
		getAsset,
		getProjectID,
		( _, { flatViewEnabled } ) => flatViewEnabled
	],
	( assets, asset, projectID, flatViewEnabled ) => {
		if ( flatViewEnabled ) return assets;

		return asset ? assets.filter(
			a => a.belongsToFolder( { projectID, folderID: asset.folderID } )
		) : [];
	}
);
