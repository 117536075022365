import { useCallback } from 'react';
import classNames from 'classnames';
import FolderTree from '@navigationPanel/components/FolderTree/FolderTree';
import type Project from '@projects/models/project';
import {
	type PinningConfig,
	type OrganizerAttributes,
	type OrganizerClickCallback,
	type RightCaptionRenderer
} from '@navigationPanel/types';
import IconButton from 'src/components/UI/IconButton/IconButton';
import useProjectTreeNode from './useProjectTreeNode';

import './ProjectTreeNode.scss';

type ProjectTreeNodeProps = {
	project: Project,
	initiallyExpanded: boolean,
	initiallyExpandedFolderIDs: number[],
	onExpandedChanged: ( projectID: number, expanded: boolean ) => void,
	onFolderExpandedChanged: ( folderID: number, expanded: boolean ) => void,
	selectedOrganizer?: OrganizerAttributes,
	onOrganizerClick: OrganizerClickCallback,
	pinning: PinningConfig,
	rightCaptionRenderer: RightCaptionRenderer,
	renderFolderIf: ( folderID: number ) => boolean
}

const ProjectTreeNode = ( {
	project,
	initiallyExpanded,
	initiallyExpandedFolderIDs,
	onExpandedChanged,
	onFolderExpandedChanged,
	selectedOrganizer,
	onOrganizerClick,
	pinning,
	rightCaptionRenderer,
	renderFolderIf
}: ProjectTreeNodeProps ) => {
	const {
		expanded,
		expanderIcon,
		selected,
		pinned,
		onExpanderClicked,
		onPinnerClicked,
		onClick,
		selectedFolderID,
		onFolderClick
	} = useProjectTreeNode( {
		project,
		initiallyExpanded,
		onExpandedChanged,
		selectedOrganizer,
		onOrganizerClick,
		pinning
	} );

	const nodeControlClassNames = classNames( 'node-control', {
		expanded, selected, pinned, showPinnerOnHover: pinning === 'available'
	} );

	const rightCaption = rightCaptionRenderer( { projectID: project.id, folderID: null } );
	const folderTreeRightCaptionRenderer = useCallback(
		( folderID: number ) => rightCaptionRenderer( { projectID: project.id, folderID } ),
		[ project.id, rightCaptionRenderer ]
	);

	return (
		<div className="ProjectTreeNode">
			<div
				className={nodeControlClassNames}
				onClick={onClick}
			>
				<div className="expander-and-name">
					<IconButton
						icon={expanderIcon}
						size={16}
						buttonClassName="expander"
						onClick={onExpanderClicked}
						testID={`project-${project.id}-expander`}
					/>
					<span className="name">{project.name}</span>
				</div>
				<div className="right-section">
					{rightCaption && <span className="right-caption">{rightCaption}</span>}
					{pinning !== 'none' && (
						<IconButton
							icon="pin"
							size={14}
							buttonClassName="pinner"
							onClick={onPinnerClicked}
							testID={`project-${project.id}-pinner`}
						/>
					)}
				</div>
			</div>
			{expanded && (
				<FolderTree
					projectID={project.id}
					initiallyExpandedFolderIDs={initiallyExpandedFolderIDs}
					onFolderExpandedChanged={onFolderExpandedChanged}
					selectedFolderID={selectedFolderID}
					onFolderClick={onFolderClick}
					rightCaptionRenderer={folderTreeRightCaptionRenderer}
					renderFolderIf={renderFolderIf}
				/>
			)}
		</div>
	)
}

export default ProjectTreeNode;
