import { QueryClient } from '@tanstack/react-query';
import { LabelsUpdates } from '@labels/types';
import { LabelAssignableJSON } from '@labels/models/labelAssignable';
import { updateItemLabels } from '@labels/utils';
import { QueryFilters, QueryKeys } from './keysAndFilters';
import { QueryDataUpdater, updateQueriesData, updateQueryData } from '../../../queries/updaters';
import { QueryType } from '../../../queries/types';

import { queryClient as globalQueryClient } from '../../../../store/store';

import { AssetRoundJSON } from '../../projectView/models/assetRound';
import { AssetJSON } from '../../projectView/models/asset';

export class AssetDetailQueryDataUpdater {
	static forLabelsUpdate = (
		assetsIds: number[],
		updates: LabelsUpdates,
		orderedLabelsIDs?: number[],
		{ queryClient = globalQueryClient }: { queryClient?: QueryClient } = {}
	) => {
		assetsIds.forEach( ( assetID ) => {
			updateQueryData(
				queryClient,
				QueryKeys.forAssetDetail( assetID ),
				( asset: LabelAssignableJSON ) => updateItemLabels( {
					item: asset, updates, orderedLabelsIDs
				} )
			);
		} );
	}

	static forCreatedAssetRound = (
		assetID: number,
		newRoundJson: AssetRoundJSON,
		{ queryClient = globalQueryClient }: { queryClient?: QueryClient } = {}
	) => {
		const lastActivityAt = Date();

		this.updateAssetDetail(
			assetID,
			asset => ( {
				...asset,
				name: newRoundJson.name,
				current_version: newRoundJson,
				version_id: newRoundJson.id,
				asset_versions_count: asset.asset_versions_count + 1,
				last_activity_at: lastActivityAt,
				updated_at: lastActivityAt
			} ),
			{ queryClient }
		);
	}

	static forUpdatedCurrentRound = (
		assetID: number,
		currentRoundJson: AssetRoundJSON,
		{ queryClient = globalQueryClient }: { queryClient?: QueryClient } = {}
	) => {
		this.updateAssetDetail(
			assetID,
			asset => ( {
				...asset,
				name: currentRoundJson.name,
				current_version: currentRoundJson
			} ),
			{ queryClient }
		);
	}

	static forApprovalStatusChange = (
		assetID: number,
		toApproved: boolean,
		{ queryClient = globalQueryClient }: { queryClient?: QueryClient } = {}
	) => {
		this.updateAssetDetail(
			assetID,
			asset => ( {
				...asset,
				approved_at: toApproved ? Date() : null
			} ),
			{ queryClient }
		);
	}

	static forFetchedRounds = (
		rounds: AssetRoundJSON[],
		{ queryClient = globalQueryClient }: { queryClient?: QueryClient } = {}
	) => {
		rounds.forEach( ( round ) => {
			this.updateAssetDetail(
				round.asset_id,
				asset => (
					asset.version_id === round.id ? ( {
						...asset,
						current_version: round
					} ) : asset
				),
				{ queryClient }
			);
		} );
	}

	private static updateAssetDetail(
		assetID: number,
		updater: QueryDataUpdater<AssetJSON>,
		{ queryClient = globalQueryClient, queryType = 'active' }: { queryClient?: QueryClient, queryType?: QueryType } = {}
	) {
		updateQueriesData(
			queryClient,
			QueryFilters.forAssetDetail( assetID, { queryType } ),
			updater
		);
	}
}
