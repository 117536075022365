import { useCallback } from 'react';
import { Loader } from '@mantine/core';
import useFetchProjectsQuery from '@projects/queries/useFetchProjectsQuery';
import {
	type OrganizerClickCallback,
	type OrganizerAttributes,
	type PinningConfig,
	type RightCaptionRenderer
} from '@navigationPanel/types';
import type Project from '@projects/models/project';
import { type ProjectStatus } from '@projects/models/project';

import { comparatorBy, compose, flip } from 'src/new_arch/lib/comparators';
import ProjectTreeNode from './ProjectTreeNode/ProjectTreeNode';

import './ProjectTree.scss';

type ProjectTreeProps = {
	status: ProjectStatus,
	initiallyExpandedProjectIDs?: number[],
	initiallyExpandedFolderIDs?: number[],
	onProjectExpandedChanged?: ( projectID: number, expanded: boolean ) => void,
	onFolderExpandedChanged?: ( folderID: number, expanded: boolean ) => void,
	selectedOrganizer?: OrganizerAttributes,
	onOrganizerClick?: OrganizerClickCallback,
	pinning: PinningConfig,
	rightCaptionRenderer?: RightCaptionRenderer
	renderFolderIf?: ( folderID: number ) => boolean
}

const notSuspended = ( project: Project ) => !project.isSuspended;
const byName = comparatorBy( 'name', { natural: true } );
const byPinnedAndName = compose<Project>(
	flip( comparatorBy( 'isPinned' ) ),
	byName
);

const ProjectTree = ( {
	status,
	initiallyExpandedProjectIDs = [],
	initiallyExpandedFolderIDs = [],
	onProjectExpandedChanged = () => {},
	onFolderExpandedChanged = () => {},
	selectedOrganizer,
	onOrganizerClick = () => {},
	pinning,
	rightCaptionRenderer = () => null,
	renderFolderIf = () => true
}: ProjectTreeProps ) => {
	const filterAndSortForProjectsForTree = useCallback(
		( projects: Project[] ) => projects
			.filter( notSuspended )
			.sort( status === 'hidden' ? byName : byPinnedAndName ),
		[ status ]
	);
	const { data: projects } = useFetchProjectsQuery( {
		status,
		select: filterAndSortForProjectsForTree
	} );

	return (
		<div className="ProjectTree">
			{projects
				? projects.map( project => (
					<ProjectTreeNode
						key={project.id}
						project={project}
						initiallyExpanded={initiallyExpandedProjectIDs.includes( project.id )}
						initiallyExpandedFolderIDs={initiallyExpandedFolderIDs}
						onExpandedChanged={onProjectExpandedChanged}
						onFolderExpandedChanged={onFolderExpandedChanged}
						selectedOrganizer={selectedOrganizer}
						onOrganizerClick={onOrganizerClick}
						pinning={pinning}
						rightCaptionRenderer={rightCaptionRenderer}
						renderFolderIf={renderFolderIf}
					/>
				) )
				: <Loader variant="dots" size="sm" color="gray" />}
		</div>
	)
}

export default ProjectTree;
