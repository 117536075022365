import { makeAutoObservable } from 'mobx';
import { isMobile } from 'src/lib/isMobile';

type NavigationPanelSection = 'active' | 'hidden';

export type NavigationPanelStoreSerialization = {
	is_open: boolean,
	is_active_section_expanded: boolean,
	is_hidden_section_expanded: boolean,
	expanded_projects: number[],
	expanded_folders: number[]
}

export default class NavigationPanelStore {
	isOpen = true;
	isActiveSectionExpanded = true;
	isHiddenSectionExpanded = false;
	expandedProjectsSet = new Set<number>();
	expandedFoldersSet = new Set<number>();

	constructor() {
		makeAutoObservable( this );
	}

	get expandedProjects() {
		return Array.from( this.expandedProjectsSet );
	}

	get expandedFolders() {
		return Array.from( this.expandedFoldersSet );
	}

	sectionIsExpanded( section: NavigationPanelSection ) {
		return section === 'active' ? this.isActiveSectionExpanded : this.isHiddenSectionExpanded;
	}

	projectIsExpanded( projectID: number ) {
		return this.expandedProjectsSet.has( projectID );
	}

	folderIsExpanded( folderID: number ) {
		return this.expandedFoldersSet.has( folderID );
	}

	togglePanelOpen = () => {
		if ( isMobile ) {
			return;
		}

		this.isOpen = !this.isOpen;
	}

	setSectionIsExpanded = ( section: NavigationPanelSection, isExpanded: boolean ) => {
		if ( section === 'active' ) {
			this.isActiveSectionExpanded = isExpanded;
		} else {
			this.isHiddenSectionExpanded = isExpanded;
		}
	}

	setProjectIsExpanded = ( projectID: number, isExpanded: boolean ) => {
		this.setEntityIsExpanded( projectID, isExpanded, this.expandedProjectsSet );
	}

	setFolderIsExpanded = ( folderID: number, isExpanded: boolean ) => {
		this.setEntityIsExpanded( folderID, isExpanded, this.expandedFoldersSet );
	}

	serialize(): NavigationPanelStoreSerialization {
		return {
			is_open: this.isOpen,
			is_active_section_expanded: this.isActiveSectionExpanded,
			is_hidden_section_expanded: this.isHiddenSectionExpanded,
			expanded_projects: Array.from( this.expandedProjectsSet ),
			expanded_folders: Array.from( this.expandedFoldersSet )
		}
	}

	hydrate( serialization: NavigationPanelStoreSerialization ) {
		this.isOpen = serialization.is_open;
		this.isActiveSectionExpanded = serialization.is_active_section_expanded;
		this.isHiddenSectionExpanded = serialization.is_hidden_section_expanded;
		this.expandedProjectsSet = new Set( serialization.expanded_projects );
		this.expandedFoldersSet = new Set( serialization.expanded_folders );
	}

	clear() {
		this.isOpen = true;
		this.isActiveSectionExpanded = true;
		this.isHiddenSectionExpanded = false;
		this.expandedProjectsSet.clear();
		this.expandedFoldersSet.clear();
	}

	private setEntityIsExpanded( entityId: number, isExpanded: boolean, entitySet: Set<number> ) {
		if ( isExpanded ) entitySet.add( entityId );
		else entitySet.delete( entityId );
	}
}
