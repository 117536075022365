import { idsFrom } from 'src/new_arch/lib/modelsUtils';
import type Asset from '../asset';
import { FilterJSON, isLabelFilterJSON, SelectedLabelID } from './types';
import FilterOption from './filterOption';

const hasNoLabels = ( asset: Asset ) => asset.labels.length === 0;

const matchAllLabels = ( asset: Asset, labelIDs: number[] ) => {
	const assetLabelIDs = idsFrom( asset.labels );
	return labelIDs.every( id => assetLabelIDs.includes( id ) );
}

const matchSomeLabels = ( asset: Asset, labelIDs: number[] ) => (
	asset.labels.some( label => labelIDs.includes( label.id ) )
);

export default class LabelFilter implements FilterOption {
	ids: SelectedLabelID[];
	matchAll: boolean;

	constructor( ids: SelectedLabelID[], matchAll: boolean ) {
		this.ids = ids;
		this.matchAll = matchAll;
	}

	get id() {
		return `label-${this.ids.join( '_' )}-${this.matchAll ? '-all' : ''}`;
	}

	get filterAmount() {
		return this.ids.length;
	}

	get isEmpty() {
		return this.ids.length === 0;
	}

	isPassedBy( asset: Asset ) {
		return this.matchAll
			? this.matchAllLabelsOf( asset )
			: this.matchSomeOfTheLabelsOf( asset );
	}

	toJson() {
		return { label: { ids: this.ids, match_all: this.matchAll } };
	}

	static fromJson( json: FilterJSON ) {
		return isLabelFilterJSON( json )
			? new LabelFilter( json.label.ids, json.label.match_all )
			: null;
	}

	private matchAllLabelsOf( asset: Asset ) {
		return this.isMatchingNoLabel
			? hasNoLabels( asset )
			: matchAllLabels( asset, this.idsWithoutNoLabel );
	}

	private matchSomeOfTheLabelsOf( asset: Asset ) {
		return (
			this.isMatchingNoLabel && hasNoLabels( asset )
		) || matchSomeLabels( asset, this.idsWithoutNoLabel );
	}

	private get isMatchingNoLabel() {
		return this.ids.includes( null );
	}

	private get idsWithoutNoLabel() {
		return this.ids.filter( id => id !== null ) as number[];
	}
}
