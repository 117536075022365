import { get, set, del } from 'idb-keyval'
import { StorageType } from './StorageService';

export default class IDBStorage implements StorageType {
	async getItem( key: string ) {
		return ( await get<string>( key ) ) || null;
	}

	async setItem( key: string, value: string ) {
		await set( key, value );
	}

	async removeItem( key: string ) {
		await del( key );
	}
}
