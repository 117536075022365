import { makeAutoObservable } from 'mobx';
import LabelSystem from '@labels/systems/ReduxLabelSystem';

import WorkspaceSystem from '../../systems/WorkspaceSystem';
import Project from '../../entities/project';

export type LabelGroup = {
	id: number;
	name: string;
	labels: { id: number, name: string, baseColor: string, hoverColor: string }[]
};

interface LabelsGroupsModalPresenterProps {
	project: Project;
	labelGroups: LabelGroup[];
	labelSystem: LabelSystem;
	workspaceSystem: WorkspaceSystem;
}

export default class LabelsGroupsModalPresenter {
	private project: Project;
	private labelSystem: LabelSystem;
	private workspaceSystem: WorkspaceSystem;
	private labelGroups: LabelGroup[];
	private checkedGroups: number[] = [];
	private loading = true;

	constructor( { project, labelGroups, labelSystem, workspaceSystem } : LabelsGroupsModalPresenterProps ) {
		this.project = project;
		this.labelGroups = labelGroups;
		this.labelSystem = labelSystem;
		this.workspaceSystem = workspaceSystem;
		makeAutoObservable( this );
	}

	onMount = async () => {
		await this._fetchLabelGroups( this.workspaceID );
	}

	get workspaceID() : number {
		return this.project.workspaceID;
	}

	get getLabelsGroups() : LabelGroup[] {
		return this.labelGroups;
	}

	get thereAreGroups() : boolean {
		return !!this.labelGroups?.length;
	}

	get userCanManageLabelsGroups() : boolean {
		return this.project.workspace.currentUserCanCreateProject;
	}

	get isLoading() : boolean {
		return this.loading;
	}

	setLoading = ( value: boolean ) : void => {
		this.loading = value;
	}

	toggleGroupCheck = ( { groupID } : { groupID: number } ) : void => {
		if ( this.isGroupChecked( { groupID } ) ) this._uncheckGroup( { groupID } );
		else this._checkGroup( { groupID } );
	}

	isGroupChecked = ( { groupID } : { groupID: number } ) : boolean => (
		this.checkedGroups.includes( groupID )
	);

	uncheckAllGroups = () : void => {
		this.checkedGroups = [];
	}

	addLabelsFromGroups = () : void => {
		this.labelSystem.createFromGroups( { projectID: this.project.id, groupsIDs: this.checkedGroups } );
	}

	_checkGroup = ( { groupID } : { groupID: number } ) : void => {
		this.checkedGroups = [ ...this.checkedGroups, groupID ];
	}

	_uncheckGroup = ( { groupID } : { groupID: number } ) : void => {
		this.checkedGroups = this.checkedGroups.filter( id => id !== groupID );
	}

	_fetchLabelGroups = async ( workspaceID: number ) => this.workspaceSystem.getLabelGroups( workspaceID );
}
