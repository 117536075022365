// Pricing
export const FETCH_PRICING_PLANS = 'FETCH_PRICING_PLANS';

// Workspaces
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const FETCH_WORKSPACES = 'FETCH_WORKSPACES';
export const FETCH_WORKSPACE_INVOICES = 'FETCH_WORKSPACE_INVOICES';
export const FETCH_WORKSPACE_INVOICES_NEXT_PAGE = 'FETCH_WORKSPACE_INVOICES_NEXT_PAGE';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const EDIT_WORKSPACE = 'EDIT_WORKSPACE';
export const FETCH_WORKSPACE_MEMBERS = 'FETCH_WORKSPACE_MEMBERS';
export const FETCH_WORKSPACE_INVITATION = 'FETCH_WORKSPACE_INVITATION';
export const FETCH_WORKSPACE_INVITATIONS = 'FETCH_WORKSPACE_INVITATIONS';
export const FETCH_WORKSPACE_MEMBERS_AND_INVITATIONS = 'FETCH_WORKSPACE_MEMBERS_AND_INVITATIONS';
export const TRANSFER_WORKSPACE_OWNERSHIP = 'TRANSFER_WORKSPACE_OWNERSHIP';
export const FETCH_WORKSPACE_PROJECTS = 'FETCH_WORKSPACE_PROJECTS';
export const INVITE_WORKSPACE_MEMBER = 'INVITE_WORKSPACE_MEMBER';
export const UPDATE_WORKSPACE_MEMBER_ROLE = 'UPDATE_WORKSPACE_MEMBER_ROLE';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const DELETE_WORKSPACE_MEMBER = 'DELETE_WORKSPACE_MEMBER';
export const DELETE_WORKSPACE_INVITATION = 'DELETE_WORKSPACE_INVITATION';
export const FETCH_WORKSPACE_MEMBER_COLLABORATORS = 'FETCH_WORKSPACE_MEMBER_COLLABORATORS';
export const FETCH_WORKSPACE_BILLING_INFORMATION = 'FETCH_WORKSPACE_BILLING_INFORMATION';
export const UPDATE_WORKSPACE_BILLING_INFORMATION = 'UPDATE_WORKSPACE_BILLING_INFORMATION';
export const FETCH_WORKSPACE_PAYMENT_METHOD = 'FETCH_WORKSPACE_PAYMENT_METHOD';
export const UPDATE_WORKSPACE_PAYMENT_METHOD = 'UPDATE_WORKSPACE_PAYMENT_METHOD';
export const FETCH_WORKSPACE_SETTINGS_INFORMATION = 'FETCH_WORKSPACE_SETTINGS_INFORMATION';
export const FETCH_SUBSCRIPTION_RELATED_INFORMATION = 'FETCH_SUBSCRIPTION_RELATED_INFORMATION';
export const REACTIVATE_SUBSCRIPTION = 'REACTIVATE_SUBSCRIPTION';
export const CLOSE_REACHED_PROJECT_LIMIT_WARNING = 'CLOSE_REACHED_PROJECT_LIMIT_WARNING';
export const CLOSE_PENDING_DOWNGRADE_WARNING = 'CLOSE_PENDING_DOWNGRADE_WARNING';
export const CLOSE_SUSPENDED_FOR_DOWNGRADE_WARNING = 'CLOSE_SUSPENDED_FOR_DOWNGRADE_WARNING';
export const CLOSE_EXPIRED_FREE_TRIAL_POPUP = 'CLOSE_EXPIRED_FREE_TRIAL_POPUP';
export const LEAVE_WORKSPACE = 'LEAVE_WORKSPACE';
export const DOWNGRADE_SUBSCRIPTION = 'DOWNGRADE_SUBSCRIPTION';
export const CANCEL_DOWNGRADE_SUBSCRIPTION = 'CANCEL_DOWNGRADE_SUBSCRIPTION';
export const FETCH_CONTACT_GROUPS = 'FETCH_CONTACT_GROUPS';
export const CREATE_CONTACT_GROUP = 'CREATE_CONTACT_GROUP';
export const UPDATE_CONTACT_GROUP = 'UPDATE_CONTACT_GROUP';
export const DELETE_CONTACT_GROUP = 'DELETE_CONTACT_GROUP';
export const FETCH_LABEL_GROUPS = 'FETCH_LABEL_GROUPS';
export const CREATE_LABEL_GROUP = 'CREATE_LABEL_GROUP';
export const UPDATE_LABEL_GROUP = 'UPDATE_LABEL_GROUP';
export const DELETE_LABEL_GROUP = 'DELETE_LABEL_GROUP';

// Projects
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const FETCH_ACTIVE_PROJECTS = 'FETCH_ACTIVE_PROJECTS';
export const FETCH_HIDDEN_PROJECTS = 'FETCH_HIDDEN_PROJECTS';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_SHARED_PROJECT = 'FETCH_SHARED_PROJECT';
export const FETCH_SHARED_FOLDER = 'FETCH_SHARED_FOLDER';
export const FETCH_PROJECT_ASSETS = 'FETCH_PROJECT_ASSETS';
export const RESET_CREATE_PROJECT = 'RESET_CREATE_PROJECT';
export const CHANGE_PROJECT_NAME = 'CHANGE_PROJECT_NAME';
export const DELETE_PROJECTS = 'DELETE_PROJECTS';
export const RESET_DELETE_PROJECTS = 'RESET_DELETE_PROJECTS';
export const ARCHIVE_PROJECTS = 'ARCHIVE_PROJECTS';
export const RESET_ARCHIVE_PROJECTS = 'RESET_ARCHIVE_PROJECTS';
export const UNARCHIVE_PROJECTS = 'UNARCHIVE_PROJECTS';
export const RESET_UNARCHIVE_PROJECTS = 'RESET_UNARCHIVE_PROJECTS';
export const SET_PROJECT_RUSH_PRIORITY = 'SET_PROJECT_RUSH_PRIORITY';
export const UNSET_PROJECT_RUSH_PRIORITY = 'UNSET_PROJECT_RUSH_PRIORITY';
export const MARK_PROJECT_AS_SEEN = 'MARK_PROJECT_AS_SEEN';
export const SET_PROJECTS_VIEW_ITEMS_SIZE = 'SET_PROJECTS_VIEW_ITEMS_SIZE';
export const UPDATE_PROJECT_SETTINGS = 'UPDATE_PROJECT_SETTINGS';
export const GET_PROJECT_SETTINGS = 'GET_PROJECT_SETTINGS';
export const TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP';
export const DOWNLOAD_PROJECT = 'DOWNLOAD_PROJECT';
export const TRANSFER_PROJECT_TO_WORKSPACE = 'TRANSFER_PROJECT_TO_WORKSPACE';
export const LEAVE_PROJECTS = 'LEAVE_PROJECTS';
export const UNFOLD_ITEMS = 'UNFOLD_ITEMS';
export const FETCH_PROJECT_OVERVIEW = 'FETCH_PROJECT_OVERVIEW';
export const UPDATE_GUEST_HASH = 'UPDATE_GUEST_HASH';
export const MUTE_PROJECTS = 'MUTE_PROJECTS';
export const UNMUTE_PROJECTS = 'UNMUTE_PROJECTS';

// Assets
export const FETCH_ASSET = 'FETCH_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const RESET_DELETE_ASSET = 'RESET_DELETE_ASSET';
export const CHANGE_ASSET_FILE_NAME = 'CHANGE_ASSET_FILE_NAME';
export const SET_ASSET_RUSH_PRIORITY = 'SET_ASSET_RUSH_PRIORITY';
export const UNSET_ASSET_RUSH_PRIORITY = 'UNSET_ASSET_RUSH_PRIORITY';
export const MARK_ASSETS_AS_APPROVED = 'MARK_ASSETS_AS_APPROVED';
export const UNMARK_ASSETS_AS_APPROVED = 'UNMARK_ASSETS_AS_APPROVED';
export const SET_ASSETS_VIEW_SIZE = 'SET_ASSETS_VIEW_SIZE';
export const DELETE_ASSETS = 'DELETE_ASSETS';
export const RESET_MARK_ASSETS_AS_APPROVED = 'RESET_MARK_ASSETS_AS_APPROVED';
export const RESET_UNMARK_ASSETS_AS_APPROVED = 'RESET_UNMARK_ASSETS_AS_APPROVED';
export const DOWNLOAD_ASSETS = 'DOWNLOAD_ASSETS';
export const DOWNLOAD_ASSET = 'DOWNLOAD_ASSET';
export const SET_ASSETS_CUSTOM_SORT = 'SET_ASSETS_CUSTOM_SORT';
export const CREATE_ASSET_FROM_DIRECT_UPLOAD = 'CREATE_ASSET_FROM_DIRECT_UPLOAD';
export const CREATE_ROUND_FROM_DIRECT_UPLOAD = 'CREATE_ROUND_FROM_DIRECT_UPLOAD';
export const UPDATE_ASSET_LAST_SEEN = 'UPDATE_ASSET_LAST_SEEN';
export const DELETE_CURRENT_ROUND = 'DELETE_CURRENT_ROUND';
export const SET_USER_IS_COMPARING_ROUNDS = 'SET_USER_IS_COMPARING_ROUNDS';
export const RESET_USER_IS_COMPARING_ROUNDS = 'RESET_USER_IS_COMPARING_ROUNDS';
export const SET_ASSET_DOWNLOAD_RESOLUTION = 'SET_ASSET_DOWNLOAD_RESOLUTION';
export const SAVE_ASSETS_PAGINATION_DATA = 'SAVE_ASSETS_PAGINATION_DATA';
export const CLEAR_ASSETS = 'CLEAR_ASSETS';
export const DELETE_ASSETS_LAST_ROUND = 'DELETE_ASSETS_LAST_ROUND';

// Asset Labels
export const FETCH_PROJECT_LABELS = 'FETCH_PROJECT_LABELS';
export const CREATE_PROJECT_LABEL = 'CREATE_PROJECT_LABEL';
export const CREATE_LABELS_FROM_GROUPS = 'CREATE_LABELS_FROM_GROUPS';
export const EDIT_PROJECT_LABEL = 'EDIT_PROJECT_LABEL';
export const DELETE_PROJECT_LABEL = 'DELETE_PROJECT_LABEL';
export const SET_PROJECT_LABELS_ORDER = 'SET_PROJECT_LABELS_ORDER';
export const SET_WORKSPACE_LABELS_ORDER = 'SET_WORKSPACE_LABELS_ORDER';

// Project Labels
export const FETCH_WORKSPACE_LABELS = 'FETCH_WORKSPACE_LABELS';
export const CREATE_WORKSPACE_LABEL = 'CREATE_WORKSPACE_LABEL';
export const EDIT_WORKSPACE_LABEL = 'EDIT_WORKSPACE_LABEL';
export const DELETE_WORKSPACE_LABEL = 'DELETE_WORKSPACE_LABEL';
export const ADD_LABEL_TO_PROJECTS = 'ADD_LABEL_TO_PROJECTS';
export const REMOVE_LABEL_FROM_PROJECTS = 'REMOVE_LABEL_FROM_PROJECTS';

// Asset Uploads
export const CREATE_FILE_UPLOADS = 'CREATE_FILE_UPLOADS';
export const UPDATE_ASSET_UPLOAD_PROGRESS = 'UPDATE_ASSET_UPLOAD_PROGRESS';
export const UPDATE_ASSET_UPLOAD_STATUS = 'UPDATE_ASSET_UPLOAD_STATUS';
export const CANCEL_ALL_ASSET_UPLOADS = 'CANCEL_ALL_ASSET_UPLOADS';
export const CLEAR_FINISHED_UPLOADS = 'CLEAR_FINISHED_UPLOADS';
export const CHECK_ASSET_FILENAMES_MATCHES = 'CHECK_ASSET_FILENAMES_MATCHES';

// Folder Uploads
export const CREATE_FOLDER_UPLOADS = 'CREATE_FOLDER_UPLOADS';
export const UPDATE_FOLDER_UPLOAD_STATUS = 'UPDATE_FOLDER_UPLOAD_STATUS';

// Comments
export const FETCH_ASSET_COMMENTS = 'FETCH_ASSET_COMMENTS';
export const FETCH_ASSET_ROUND_COMMENTS = 'FETCH_ASSET_ROUND_COMMENTS';
export const CREATE_COMMENT_FOR_ASSET = 'CREATE_COMMENT_FOR_ASSET';
export const RESET_CREATE_COMMENT_FOR_ASSET = 'RESET_CREATE_COMMENT_FOR_ASSET';
export const SET_CURRENT_ACTIVE_MARKUP_COMMENT = 'SET_CURRENT_ACTIVE_MARKUP_COMMENT';
export const CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT = 'CLEAR_CURRENT_ACTIVE_MARKUP_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const UPDATE_MARKUP_COMMENT = 'UPDATE_MARKUP_COMMENT';
export const RESET_UPDATE_MARKUP_COMMENT = 'RESET_UPDATE_MARKUP_COMMENT';
export const START_WRITING_COMMENT = 'START_WRITING_COMMENT';
export const STOP_WRITING_COMMENT = 'STOP_WRITING_COMMENT';
export const MARK_COMMENT_AS_COMPLETED = 'MARK_COMMENT_AS_COMPLETED';
export const UNMARK_COMMENT_AS_COMPLETED = 'UNMARK_COMMENT_AS_COMPLETED';
export const UPDATE_COMMENTS_LAST_SEEN = 'UPDATE_COMMENTS_LAST_SEEN';
export const SET_HOVERED_COMMENT = 'SET_HOVERED_COMMENT';
export const CLEAR_HOVERED_COMMENT = 'CLEAR_HOVERED_COMMENT';
export const FETCH_COMMENTS_LAST_SEEN_BY_USERS = 'FETCH_COMMENTS_LAST_SEEN_BY_USERS';
export const SET_COMMENTS_VIEW_FILTER = 'SET_COMMENTS_VIEW_FILTER';
export const FREEZE_COMMENTS_VIEW_FILTER = 'FREEZE_COMMENTS_VIEW_FILTER';
export const UNFREEZE_COMMENTS_VIEW_FILTER = 'UNFREEZE_COMMENTS_VIEW_FILTER';

// Notifications
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const MARK_NOTIFICATIONS_AS_SEEN = 'MARK_NOTIFICATIONS_AS_SEEN';
export const FETCH_PROJECT_NOTIFICATIONS = 'FETCH_PROJECT_NOTIFICATIONS';
export const SET_NOTIFICATIONS_FILTER = 'SET_NOTIFICATIONS_FILTER';
export const SET_NOTIFICATIONS_PANEL_AS_OPEN = 'SET_NOTIFICATIONS_PANEL_AS_OPEN';
export const SET_NOTIFICATIONS_PANEL_AS_CLOSED = 'SET_NOTIFICATIONS_PANEL_AS_CLOSED';

// Tasks
export const FETCH_PROJECT_TASKS = 'FETCH_PROJECT_TASKS';
export const MARK_PROJECT_TASK_AS_COMPLETED = 'MARK_PROJECT_TASK_AS_COMPLETED';
export const UNMARK_PROJECT_TASK_AS_COMPLETED = 'UNMARK_PROJECT_TASK_AS_COMPLETED';
export const CREATE_TASK = 'CREATE_TASK';
export const RESET_CREATE_TASK = 'RESET_CREATE_TASK';
export const SET_EDITING_TASK = 'SET_EDITING_TASK';
export const CANCEL_TASK_EDIT = 'CANCEL_TASK_EDIT';
export const UPDATE_TASK = 'UPDATE_TASK';

// Authentication
export const LOGOUT = 'LOGOUT';

// Project members
export const FETCH_PROJECT_MEMBERS = 'FETCH_PROJECT_MEMBERS';
export const INVITE_PROJECT_MEMBER = 'INVITE_PROJECT_MEMBER';
export const DELETE_PROJECT_MEMBER = 'DELETE_PROJECT_MEMBER';

// Info Messages
export const SHOW_SUCCESS_SNACKBAR = 'SHOW_SUCCESS_SNACKBAR';
export const SHOW_ERROR_SNACKBAR = 'SHOW_ERROR_SNACKBAR';
export const SHOW_LOADING_SNACKBAR = 'SHOW_LOADING_SNACKBAR';
export const DISMISS_CURRENT_SNACKBAR = 'DISMISS_CURRENT_SNACKBAR';

// Markup Editor
export const START_DRAWING_MARKUP = 'START_DRAWING_MARKUP';
export const ADD_SHAPE_TO_CURRENT_DRAWING_MARKUP = 'ADD_SHAPE_TO_CURRENT_DRAWING_MARKUP';
export const REMOVE_LAST_SHAPE_FROM_CURRENT_DRAWING_MARKUP = 'REMOVE_LAST_SHAPE_FROM_CURRENT_DRAWING_MARKUP';
export const STOP_DRAWING_MARKUP = 'STOP_DRAWING_MARKUP';
export const SET_MARKUP_EDITING_TOOL = 'SET_MARKUP_EDITING_TOOL';
export const SET_MARKUP_EDITING_COLOR = 'SET_MARKUP_EDITING_COLOR';
export const SET_MARKUP_EDITING_SIZE = 'SET_MARKUP_EDITING_SIZE';
export const RESET_MARKUP_EDITING_SETTINGS = 'RESET_MARKUP_EDITING_SETTINGS';
export const SET_MARKUP_EDITOR_ACTIVE_STATUS = 'SET_MARKUP_EDITOR_ACTIVE_STATUS';
export const RESET_MARKUP_EDITOR_ACTIVE_STATUS = 'RESET_MARKUP_EDITOR_ACTIVE_STATUS';

// Users
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SEND_PROFILE_VERIFICATION_EMAIL = 'SEND_PROFILE_VERIFICATION_EMAIL';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';

// User Preferences
export const SET_IS_2FA_ENABLED = 'SET_IS_2FA_ENABLED';

// Notifications Preferences
export const SET_SELECTED_NOTIFICATIONS_PROJECT_ID = 'SET_SELECTED_NOTIFICATIONS_PROJECT_ID';

// Projects View Options
export const SET_PROJECTS_VIEW_SORT = 'SET_PROJECTS_VIEW_SORT';
export const SET_PROJECTS_CUSTOM_SORT = 'SET_PROJECTS_CUSTOM_SORT';
export const SET_PROJECTS_VIEW_FILTER = 'SET_PROJECTS_VIEW_FILTER';

// Assets View Options
export const SET_ASSETS_VIEW_SORT = 'SET_ASSETS_VIEW_SORT';
export const UPDATE_ASSETS_VIEW_FILTERS = 'UPDATE_ASSETS_VIEW_FILTERS';
export const FREEZE_ASSETS_VIEW_OPTIONS = 'FREEZE_ASSETS_VIEW_OPTIONS';

// Popups
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

// Activities
export const FETCH_PROJECT_ACTIVITIES = 'FETCH_PROJECT_ACTIVITIES';

// Asset Versions
export const FETCH_ASSET_ROUNDS = 'FETCH_ASSET_ROUNDS';
export const FETCH_PROJECT_ASSET_ROUNDS = 'FETCH_PROJECT_ASSET_ROUNDS';
export const SET_SELECTED_ASSET_ROUND_ID = 'SET_SELECTED_ASSET_ROUND_ID';
export const CLEAR_SELECTED_ASSET_ROUND_ID = 'CLEAR_SELECTED_ASSET_ROUND_ID';

// Project Invitations
export const FETCH_PROJECT_INVITATION = 'FETCH_PROJECT_INVITATION';
export const FETCH_PROJECT_INVITATIONS = 'FETCH_PROJECT_INVITATIONS';
export const DELETE_PROJECT_INVITATION = 'DELETE_PROJECT_INVITATION';

// Overlay
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';

// Add Ons
export const FETCH_PLAN_ADD_ONS = 'FETCH_PLAN_ADD_ONS';
export const BUY_ADD_ON = 'BUY_ADD_ON';
export const REMOVE_ADD_ON = 'REMOVE_ADD_ON';

// Rotate Mobile Modal
export const SHOW_ROTATE_MOBILE_SCREEN = 'SHOW_ROTATE_MOBILE_SCREEN';
export const HIDE_ROTATE_MOBILE_SCREEN = 'HIDE_ROTATE_MOBILE_SCREEN';

// Subscription Modal
export const SHOW_SUBSCRIPTION_MODAL = 'SHOW_SUBSCRIPTION_MODAL';
export const HIDE_SUBSCRIPTION_MODAL = 'HIDE_SUBSCRIPTION_MODAL';

// Project Settings Modal
export const SHOW_PROJECT_SETTINGS_MODAL = 'SHOW_PROJECT_SETTINGS_MODAL';
export const HIDE_PROJECT_SETTINGS_MODAL = 'HIDE_PROJECT_SETTINGS_MODAL';

// Invite Members Modal
export const SHOW_INVITE_MEMBERS_MODAL = 'SHOW_INVITE_MEMBERS_MODAL';
export const HIDE_INVITE_MEMBERS_MODAL = 'HIDE_INVITE_MEMBERS_MODAL';
// Folders
export const CLEAR_DIRECT_SUBFOLDERS = 'CLEAR_DIRECT_SUBFOLDERS';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const RESET_CREATE_FOLDER = 'RESET_CREATE_FOLDER';
export const FETCH_FOLDER = 'FETCH_FOLDER';
export const FETCH_FOLDERS_FOR_PROJECT = 'FETCH_FOLDERS_FOR_PROJECT';
export const FETCH_FOLDER_ASSETS = 'FETCH_FOLDER_ASSETS';
export const CHANGE_FOLDER_NAME = 'CHANGE_FOLDER_NAME';
export const RESET_CHANGE_FOLDER_NAME = 'RESET_CHANGE_FOLDER_NAME';
export const MOVE_ITEMS_INTO_FOLDER = 'MOVE_ITEMS_INTO_FOLDER';
export const DELETE_FOLDERS = 'DELETE_FOLDERS';
export const FETCH_FOLDERS_FOR_FOLDER = 'FETCH_FOLDERS_FOR_FOLDER';
export const FETCH_FOLDER_ANCESTORS = 'FETCH_FOLDER_ANCESTORS';
export const MARK_FOLDER_NEW_ACTIVITY_AS_SEEN = 'MARK_FOLDER_NEW_ACTIVITY_AS_SEEN';
export const FETCH_FOLDER_OVERVIEW = 'FETCH_FOLDER_OVERVIEW';
export const FETCH_FOLDER_INFORMATION = 'FETCH_FOLDER_INFORMATION';
export const CHANGE_FOLDER_COVER = 'CHANGE_FOLDER_COVER';
