import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import WhiteBox from '../../WhiteBox/WhiteBox';
import PricingSubscriptionSelectionModal
	from '../../../modals/PricingSubscriptionSelectionModal/PricingSubscriptionSelectionModal';
import PlanSettingsPresenter from '../../../presenters/PlanSettings/PlanSettingsPresenter';
import SubscriptionDetailPaidPlanFooter from '../SubscriptionDetailPaidPlanFooter/SubscriptionDetailPaidPlanFooter';
import WorkspaceSubscriptionCheckoutModalContainer from '../../../containers/WorkspaceSubscriptionCheckoutModalContainer/WorkspaceSubscriptionCheckoutModalContainer';
import './SubscriptionDetail.scss';
import ActiveSubscriptionDetail from '../ActiveSubscriptionDetail/ActiveSubscriptionDetail';
import SuspendedSubscriptionDetail from '../SuspendedSubscriptionDetail/SuspendedSubscriptionDetail';
import ActiveFreeTrialSubscriptionDetail from '../ActiveFreeTrialSubscriptionDetail/ActiveFreeTrialSubscriptionDetail';

const SubscriptionDetail = ( {
	presenter
} ) => {
	const [ showingCheckoutModal, setShowingCheckoutModal ] = useState( false );
	const [ pricingPlanPrice, setPricingPlanPrice ] = useState( undefined );

	useEffect( presenter.openSubscriptionModalBasedOnQueryParams, [] );

	const onUpgradePlanButton = () => {
		if ( presenter.isSubscriptionMarkedForCancellation ) {
			return presenter.onReactivateSubscriptionClicked();
		}
		return presenter.openSubscriptionModal();
	};

	const closeCheckoutModal = () => setShowingCheckoutModal( false );

	const onSuccessfulPaymentDialogAccepted = () => {
		presenter.closeSubscriptionModal();
		setShowingCheckoutModal( false );
	};

	const onUpgradeButtonClicked = ( aPricingPlanPrice ) => {
		if ( aPricingPlanPrice.isFree ) {
			presenter.createFreeSubscription( aPricingPlanPrice );
		} else {
			setPricingPlanPrice( aPricingPlanPrice );
			setShowingCheckoutModal( true );
		}
		presenter.closeSubscriptionModal();
	};

	const onDowngradeButtonClicked = () => presenter.onDowngradeClicked();

	const onCancelDowngradeButtonClicked = () => presenter.onCancelDowngradeClicked();

	const renderFooter = () => (
		<div className="footer">
			<SubscriptionDetailPaidPlanFooter
				workspace={presenter.workspace}
				isSubscriptionActive={presenter.isSubscriptionActive}
				onUpgradePlanButton={() => onUpgradePlanButton()}
				showCancelSubscriptionButton={presenter.shouldRenderCancelSubscriptionButton}
				onCancelSubscriptionClick={presenter.onSubscriptionCancelClicked}
			/>
		</div>
	);

	const renderPricingSubscriptionSelectionModal = () => (
		<PricingSubscriptionSelectionModal
			workspaceID={presenter.workspace.id}
			presenter={presenter.pricingSubscriptionPresenter}
			onClose={presenter.closeSubscriptionModal}
			onUpgradeButtonClicked={onUpgradeButtonClicked}
			onDowngradeButtonClicked={onDowngradeButtonClicked}
			onCancelDowngradeButtonClicked={onCancelDowngradeButtonClicked}
		/>
	);

	const renderWorkspaceSubscriptionCheckoutModal = () => (
		<WorkspaceSubscriptionCheckoutModalContainer
			workspaceID={presenter.workspace.id}
			onClose={closeCheckoutModal}
			onSuccessfulDialogAccepted={onSuccessfulPaymentDialogAccepted}
			pricingPlanPrice={pricingPlanPrice}
		/>
	);

	const renderSubscriptionType = () => (
		presenter.isSubscriptionActive
			? <ActiveSubscriptionDetail presenter={presenter} />
			: <SuspendedSubscriptionDetail presenter={presenter} />
	);

	return (
		<div className={classNames( 'SubscriptionDetail', { inactive: !presenter.workspace.isActive } )}>
			<WhiteBox>
				{ presenter.subscriptionModalIsOpen && renderPricingSubscriptionSelectionModal() }
				{ showingCheckoutModal && renderWorkspaceSubscriptionCheckoutModal() }
				<div className="WhiteBoxTitle">
					<h4>Plan Detail</h4>
				</div>
				{
					presenter.isOnActiveFreeTrial
						? <ActiveFreeTrialSubscriptionDetail presenter={presenter} />
						: renderSubscriptionType()
				}
				{ renderFooter() }
			</WhiteBox>
		</div>
	);
};

SubscriptionDetail.propTypes = {
	presenter: PropTypes.instanceOf( PlanSettingsPresenter ).isRequired
};

export default observer( SubscriptionDetail );
