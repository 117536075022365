import type Asset from '../asset';
import { FilterJSON } from './types';
import FilterOption from './filterOption';

type AssetPredicate = ( asset: Asset ) => boolean;

export default class StateFilter implements FilterOption {
	backendId: string;
	predicate: AssetPredicate;

	constructor( {
		backendId, predicate
	}: {
		backendId: string, predicate: AssetPredicate
	} ) {
		this.backendId = backendId;
		this.predicate = predicate;
	}

	get id() {
		return this.backendId;
	}

	get filterAmount() {
		return 1;
	}

	isPassedBy( asset: Asset ) {
		return this.predicate( asset );
	}

	toJson() {
		return { [ this.backendId ]: true }
	}

	static byPriority = new StateFilter( {
		backendId: 'priority',
		predicate: asset => asset.hasRushPriority
	} );

	static byApproved = new StateFilter( {
		backendId: 'approved',
		predicate: asset => asset.isApproved
	} );

	static byNotApproved = new StateFilter( {
		backendId: 'not_approved',
		predicate: asset => !asset.isApproved
	} );

	static byComments = new StateFilter( {
		backendId: 'comments',
		predicate: asset => asset.hasComments
	} );

	static byUnresolvedComments = new StateFilter( {
		backendId: 'unresolved_comments',
		predicate: asset => asset.hasUnresolvedComments
	} );

	static byUnresolvedMentions = new StateFilter( {
		backendId: 'unresolved_mentions',
		predicate: asset => asset.hasUnresolvedMentions
	} );

	static stateFiltersById = {
		priority: StateFilter.byPriority,
		approved: StateFilter.byApproved,
		not_approved: StateFilter.byNotApproved,
		comments: StateFilter.byComments,
		unresolved_comments: StateFilter.byUnresolvedComments
	};

	static fromJson( json: FilterJSON ) {
		const backendId = Object.keys( json )[ 0 ];

		if ( !Object.keys( this.stateFiltersById ).includes( backendId ) ) {
			return null;
		}

		return this.stateFiltersById[ backendId as keyof typeof StateFilter.stateFiltersById ];
	}
}
