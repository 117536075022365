import { PersistQueryClientProvider as TanStackPersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactNode } from 'react';
import { queryClient } from '../../store/store';
import createIDBPersister from '../queries/createIDBPersister';

const persister = createIDBPersister( 'reactQuery' );

const PersistQueryClientProvider = ( { children } : { children: ReactNode } ) => (
	<TanStackPersistQueryClientProvider
		client={queryClient}
		persistOptions={{ persister, buster: 'a4ddf22162b05b8c3186496589f3ce772f1a475c' }}
	>
		{children}
	</TanStackPersistQueryClientProvider>
)

export default PersistQueryClientProvider;
