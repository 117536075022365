import moment from 'moment';
import Label from '@labels/models/label';

import { getFileExtension, mapExtensionsForFileInput } from '../lib/fileUtils';
import ImmutableEntity from './immutableEntity';
import { ASSETS_URL } from '../config/urls';

export const ASSET_IMAGE_EXTENSIONS = [
	'tiff', 'tif', 'jpg', 'jpeg', 'png', 'psd', 'dng', 'crw',
	'cr2', 'nef', 'arw', 'psb', 'gif'
];

export const ASSET_DOCUMENT_EXTENSIONS = [
	'indd', 'ai', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'pdf', 'txt', 'eip'
];

const sortExtensions = extensions => extensions.sort(
	( value, otherValue ) => value.localeCompare( otherValue )
);

export const ASSET_IMAGE_EXTENSIONS_SORTED = sortExtensions( ASSET_IMAGE_EXTENSIONS );

export const ASSET_DOCUMENT_EXTENSIONS_SORTED = sortExtensions( ASSET_DOCUMENT_EXTENSIONS );

export const ASSET_ALL_EXTENSIONS = [
	...ASSET_IMAGE_EXTENSIONS,
	...ASSET_DOCUMENT_EXTENSIONS
];

export default class Asset extends ImmutableEntity {
	constructor(
		id,
		projectID,
		name,
		currentRoundID,
		isApproved,
		newCommentsCount,
		newlyResolvedCommentsCount,
		unresolvedCommentsCount,
		hasRoundsUnseenByCurrentUser,
		isNewForCurrentUser,
		hasNewNameForCurrentUser,
		hasNewLabelForCurrentUser,
		hasRushPriority,
		createdAt,
		updatedAt,
		currentRound,
		hasComments,
		lastActivityAt,
		roundsCount,
		labels,
		folderID,
		unresolvedMentionsCount
	) {
		super();

		this.id = id;
		this.projectID = projectID;
		this.name = name;
		this.currentRoundID = currentRoundID;
		this.isApproved = isApproved;
		this.newCommentsCount = newCommentsCount;
		this.newlyResolvedCommentsCount = newlyResolvedCommentsCount;
		this.unresolvedCommentsCount = unresolvedCommentsCount;
		this.hasRoundsUnseenByCurrentUser = hasRoundsUnseenByCurrentUser;
		this.isNewForCurrentUser = isNewForCurrentUser;
		this.hasNewNameForCurrentUser = hasNewNameForCurrentUser;
		this.hasNewLabelForCurrentUser = hasNewLabelForCurrentUser;
		this.hasRushPriority = hasRushPriority;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.currentRound = currentRound;
		this.hasComments = hasComments;
		this.lastActivityAt = lastActivityAt;
		this.roundsCount = roundsCount;
		this.labels = labels || [];
		this.folderID = folderID;
		this.unresolvedMentionsCount = unresolvedMentionsCount;
	}

	static fromJSON( properties ) {
		return new Asset(
			properties.id,
			properties.project_id,
			properties.name,
			properties.version_id,
			properties.approve === 'confirmed',
			properties.new_comments_count,
			properties.newly_approved_comments_count,
			properties.unresolved_comments_count,
			properties.has_rounds_unseen_by_current_user,
			properties.is_new_for_current_user,
			properties.has_new_name_for_current_user,
			properties.has_new_label_for_current_user,
			properties.rush_priority,
			new Date( properties.created_at ),
			new Date( properties.updated_at ),
			undefined,
			properties.has_comments,
			new Date( properties.last_activity_at ),
			properties.asset_versions_count,
			Label.allFromJson( properties.labels || [] ),
			properties.folder_id,
			properties.unresolved_mentions_count || 0
		);
	}

	toJSON() {
		return {
			id: this.id,
			project_id: this.projectID,
			name: this.name,
			version_id: this.currentRoundID,
			approve: this.isApproved ? 'confirmed' : 'pending',
			new_comments_count: this.newCommentsCount,
			newly_approved_comments_count: this.newlyResolvedCommentsCount,
			unresolved_comments_count: this.unresolvedCommentsCount,
			has_rounds_unseen_by_current_user: this.hasRoundsUnseenByCurrentUser,
			is_new_for_current_user: this.isNewForCurrentUser,
			has_new_name_for_current_user: this.hasNewNameForCurrentUser,
			has_new_label_for_current_user: this.hasNewLabelForCurrentUser,
			rush_priority: this.hasRushPriority,
			created_at: this.createdAt.toISOString(),
			updated_at: this.updatedAt.toISOString(),
			has_comments: this.hasComments,
			last_activity_at: this.lastActivityAt.toISOString(),
			asset_versions_count: this.roundsCount,
			labels: this.labels.map( label => label.toJSON() ),
			folder_id: this.folderID,
			unresolved_mentions_count: this.unresolvedMentionsCount
		};
	}

	get fileURL() { return `${ASSETS_URL}/${this.id}/download`; }

	get fileURLWithRoundNumber() { return `${this.fileURL}?with_round_number=true`; }

	get image() { return this.currentRound?.image; }

	get thumbnailURL() { return this.currentRound && this.currentRound.thumbnailURL; }

	get isProcessing() { return this.currentRound && this.currentRound.isProcessing; }

	get fileExtension() { return this.name && getFileExtension( this.name ); }

	get hasUnresolvedComments() { return this.unresolvedCommentsCount > 0; }

	get isImage() { return ASSET_IMAGE_EXTENSIONS.includes( this.fileExtension ); }

	get isDocument() { return ASSET_DOCUMENT_EXTENSIONS.includes( this.fileExtension ); }

	get isEipFile() { return this.fileExtension.toLowerCase() === 'eip'; }

	get hasThumbnail() { return !this.isEipFile; }

	get validExtensionsOfType() {
		return mapExtensionsForFileInput(
			this.isImage
				? ASSET_IMAGE_EXTENSIONS
				: ASSET_DOCUMENT_EXTENSIONS
		);
	}

	get currentRoundNumber() { return this.currentRound && this.currentRound.number; }

	get hasAnyLabel() { return this.labels.length > 0; }

	get lastActivityToDisplay() {
		return moment( this.lastActivityAt ).format( 'MM/DD, h:mmA' );
	}

	get labellableID() {
		return this.projectID;
	}

	get labellableType() {
		return 'Project';
	}

	get selectionID() {
		return `asset-${this.id}`;
	}

	get canHoldItems() {
		return false;
	}

	get canBeManuallySorted() {
		return true;
	}

	belongsToProject( projectID ) {
		return this.projectID === projectID;
	}

	belongsToFolder( { projectID, folderID } ) {
		return this.belongsToProject( projectID ) && this._belongsToFolder( folderID );
	}

	hasLabel( label ) {
		return !!this.labels.find( aLabel => aLabel.id === label?.id );
	}

	clone() {
		return new Asset(
			this.id,
			this.projectID,
			this.name,
			this.currentRoundID,
			this.isApproved,
			this.newCommentsCount,
			this.newlyResolvedCommentsCount,
			this.unresolvedCommentsCount,
			this.hasRoundsUnseenByCurrentUser,
			this.isNewForCurrentUser,
			this.hasNewNameForCurrentUser,
			this.hasNewLabelForCurrentUser,
			this.hasRushPriority,
			this.createdAt,
			this.updatedAt,
			this.currentRound,
			this.hasComments,
			this.lastActivityAt,
			this.roundsCount,
			this.labels,
			this.folderID,
			this.unresolvedMentionsCount
		);
	}

	approved( approve = true ) {
		return this.set( 'isApproved', approve );
	}

	withName( name ) {
		return this.set( 'name', name );
	}

	withCurrentRound( round ) {
		return this.set( 'currentRound', round );
	}

	setRushPriority() {
		return this.set( 'hasRushPriority', true );
	}

	unsetRushPriority() {
		return this.set( 'hasRushPriority', false );
	}

	decreaseUnresolvedCommentsCount() {
		return this.set( 'unresolvedCommentsCount', Math.max( this.unresolvedCommentsCount - 1, 0 ) );
	}

	increaseUnresolvedCommentsCount() {
		return this.set( 'unresolvedCommentsCount', this.unresolvedCommentsCount + 1 );
	}

	markedAsSeen() {
		return this
			.set( 'hasRoundsUnseenByCurrentUser', false )
			.set( 'isNewForCurrentUser', false )
			.set( 'hasNewNameForCurrentUser', false )
			.set( 'hasNewLabelForCurrentUser', false )
			.set( 'newCommentsCount', 0 )
			.set( 'newlyResolvedCommentsCount', 0 );
	}

	_belongsToFolder( folderID ) {
		// eslint-disable-next-line eqeqeq
		return this.folderID == folderID;
	}
}
