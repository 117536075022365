import { QueryClient } from '@tanstack/react-query';
import { QueryFilters } from './keysAndFilters';

import { queryClient as globalQueryClient } from '../../../../store/store';

type InvalidateOptions = { refetchActiveQueries?: boolean, queryClient?: QueryClient };

export class AssetDetailQueryInvalidator {
	static invalidateAllAssetDetail = (
		{ refetchActiveQueries = false, queryClient = globalQueryClient }: InvalidateOptions = {}
	) => {
		queryClient.invalidateQueries( {
			...QueryFilters.forAssetDetail( undefined, { queryType: 'all' } ),
			refetchType: refetchActiveQueries ? 'active' : 'none'
		} );
	}

	static invalidateAssetsDetail = (
		assetIDs: number[],
		options: InvalidateOptions = {}
	) => {
		assetIDs.forEach( assetID => AssetDetailQueryInvalidator.invalidateAssetDetail( assetID, options ) );
	}

	static invalidateAssetDetail = (
		assetID: number,
		{ refetchActiveQueries = false, queryClient = globalQueryClient }: InvalidateOptions = {}
	) => {
		queryClient.invalidateQueries( {
			...QueryFilters.forAssetDetail( assetID, { queryType: 'all' } ),
			refetchType: refetchActiveQueries ? 'active' : 'none'
		} );
	}
}
