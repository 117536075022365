import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useProjectSystem from '@projects/systems/useProjectSystem';
import { QueryKeys } from '@projects/queries/queryKeys';
import { updateItems, updateQueryData } from 'src/new_arch/queries/updaters';
import { type ProjectJSON, type ProjectStatus } from '@projects/models/project';

const useProjectPinningMutation = ( projectID: number, action: 'pin' | 'unpin' ) => {
	const system = useProjectSystem();
	const queryClient = useQueryClient();
	const activeProjectsKey = QueryKeys.forProjects( { status: 'active' } );
	const hiddenProjectsKey = QueryKeys.forProjects( { status: 'hidden' } );

	const onMutate = useCallback( async () => {
		const applyActionInProjectsWithStatus = async ( status: ProjectStatus ) => {
			const queryKey = status === 'active' ? activeProjectsKey : hiddenProjectsKey;
			await queryClient.cancelQueries( { queryKey } );
			const prevProjects = queryClient.getQueryData( queryKey );
			updateQueryData(
				queryClient,
				queryKey,
				updateItems<ProjectJSON>(
					[ projectID ],
					item => ( { ...item, is_pinned: action === 'pin' } )
				)
			);
			return prevProjects;
		}

		const prevActiveProjects = await applyActionInProjectsWithStatus( 'active' );
		const prevHiddenProjects = await applyActionInProjectsWithStatus( 'hidden' );

		return { prevActiveProjects, prevHiddenProjects };
	}, [ projectID, action ] );

	const onError = useCallback(
		( _error: unknown, _params: void, context?: { prevActiveProjects: unknown, prevHiddenProjects: unknown } ) => {
			queryClient.setQueryData( activeProjectsKey, context?.prevActiveProjects );
			queryClient.setQueryData( hiddenProjectsKey, context?.prevHiddenProjects );
		},
		[]
	);

	return useMutation(
		() => ( action === 'pin'
			? system.pinProject( projectID )
			: system.unpinProject( projectID ) ),
		{ onMutate, onError }
	);
}

export default useProjectPinningMutation;
