import { OrderedMap, Set } from 'immutable';
import { handle } from 'redux-pack';
import { updateLabelsWithOrder } from '@labels/utils';

import {
	makeAsyncActionReducer, handleSuccess, handleStart, orderedMapForPayload
} from '../lib/reduxUtils';
import {
	FETCH_PROJECT_ASSETS, DELETE_ASSET,
	CHANGE_ASSET_FILE_NAME, LOGOUT,
	SET_ASSET_RUSH_PRIORITY, UNSET_ASSET_RUSH_PRIORITY,
	MARK_ASSETS_AS_APPROVED, UNMARK_ASSETS_AS_APPROVED,
	DELETE_ASSETS, CREATE_ASSET_FROM_DIRECT_UPLOAD,
	UPDATE_ASSET_LAST_SEEN, MARK_COMMENT_AS_COMPLETED, UNMARK_COMMENT_AS_COMPLETED,
	CREATE_COMMENT_FOR_ASSET, FETCH_ASSET, DELETE_COMMENT, CREATE_ROUND_FROM_DIRECT_UPLOAD,
	DELETE_PROJECT_LABEL,
	MOVE_ITEMS_INTO_FOLDER, UNFOLD_ITEMS,
	FETCH_FOLDER_ASSETS, SAVE_ASSETS_PAGINATION_DATA,
	EDIT_PROJECT_LABEL, DELETE_CURRENT_ROUND, CLEAR_ASSETS, DELETE_ASSETS_LAST_ROUND,
	SET_PROJECT_LABELS_ORDER
} from '../actions/types';
import { changeAssetsMapApproval } from '../lib/changeAssetsMapApproval';
import { isNotAllowedError, isNotAllowedOrNotFound } from '../errors/common';
import { UPDATER_ACTION_TYPE } from '../new_arch/services/ReduxStoreUpdater';
import { parseAsset, parseAssets } from '../parsers/assets';

export const fetchProjectAssetsRequest = makeAsyncActionReducer( FETCH_PROJECT_ASSETS );
export const fetchFolderAssetsRequest = makeAsyncActionReducer( FETCH_FOLDER_ASSETS );
export const deleteProjectAssetsRequest = makeAsyncActionReducer( DELETE_ASSET );
export const markProjectAssetsAsApprovedRequest = makeAsyncActionReducer( MARK_ASSETS_AS_APPROVED );
export const unmarkProjectAssetsAsApprovedRequest	= makeAsyncActionReducer(
	UNMARK_ASSETS_AS_APPROVED
);

const isRootComment = ( { parent_id: parentID } ) => parentID == null;

const increaseUnresolvedCommentsCount = asset => ( {
	...asset,
	unresolved_comments_count: asset.unresolved_comments_count + 1
} )

const decreaseUnresolvedCommentsCount = asset => ( {
	...asset,
	unresolved_comments_count: Math.max( asset.unresolved_comments_count - 1, 0 )
} )

export const assets = ( state = OrderedMap(), action ) => {
	switch ( action.type ) {
	case FETCH_FOLDER_ASSETS:
	case FETCH_PROJECT_ASSETS:
		return handle( state, action, {
			failure: prevState => (
				action.payload && isNotAllowedError( action.payload )
					? prevState.filter( asset => asset.project_id !== action.meta.projectID )
					: prevState
			),
			success: ( prevState ) => {
				const newAssets = orderedMapForPayload( action.payload.response );
				return ( action.meta.page === 1 ) ? newAssets : prevState.merge( newAssets );
			}
		} );
	case FETCH_ASSET:
		return handle( state, action, {
			failure: ( prevState ) => {
				const { assetID } = action.meta;

				return action.payload && isNotAllowedOrNotFound( action.payload )
					? prevState.delete( assetID )
					: prevState;
			},
			success: ( prevState ) => {
				const { assetID } = action.meta;
				const asset = action.payload;
				const prevAsset = prevState.get( assetID, {} );
				return prevState.set( assetID, { ...prevAsset, ...asset } );
			}
		} );
	case CREATE_ASSET_FROM_DIRECT_UPLOAD:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				if ( !action.payload.matchesFilter ) return prevState;
				return prevState.set(
					action.payload.asset.id,
					{ ...action.payload.asset, last_activity_at: new Date().toISOString() }
				);
			}
		);
	case CREATE_ROUND_FROM_DIRECT_UPLOAD:
		return handleSuccess(
			state,
			action,
			prevState => prevState.updateIfSet(
				action.payload.asset_id,
				asset => ( {
					...asset,
					name: action.payload.name,
					version_id: action.payload.id,
					has_rounds_unseen_by_current_user: false,
					asset_versions_count: asset.asset_versions_count + 1,
					last_activity_at: new Date()
				} )
			)
		);
	case DELETE_CURRENT_ROUND:
		return handleSuccess( state, action, prevState => prevState.updateIfSet(
			action.meta.prevAssetRound.assetID,
			asset => ( {
				...asset,
				version_id: action.meta.prevAssetRound.id
			} ) )
		);
	case DELETE_ASSETS_LAST_ROUND:
		return handleSuccess(
			state,
			action,
			prevState => action.meta.prevAssetRounds.reduce(
				( result, round ) => result.updateIfSet( round.assetID, asset => ( {
					...asset,
					current_version: round,
					version_number: round.number,
					asset_versions_count: asset.asset_versions_count - 1,
					version_id: round.id
				} ) ),
				prevState
			)
		);
	case DELETE_ASSET:
		return handleSuccess(
			state,
			action,
			prevState => prevState.delete( action.payload )
		);
	case MOVE_ITEMS_INTO_FOLDER:
		return handle( state, action, {
			start: ( prevState ) => {
				const { assets: movedAssets } = action.meta;
				const movedAssetsIDs = movedAssets.map( asset => asset.id );
				return prevState.filter( asset => !movedAssetsIDs.includes( asset.id ) );
			},
			success: ( prevState ) => {
				const prevStateList = prevState.toList();
				const { assets: movedAssets, assetIndexes: oldPositions } = action.meta;
				const assetNamesToRollback = action.payload?.meta?.duplicate_names || [];
				const oldPositionsToRollback = [];
				const assetsToRollback = movedAssets.filter( ( a, index ) => {
					if ( assetNamesToRollback.includes( a.name ) ) {
						oldPositionsToRollback.push( oldPositions[ index ] );
						return true;
					}
					return false;
				} );

				return orderedMapForPayload( oldPositionsToRollback.reduce( ( restoredList, oldPosition, index ) => (
					restoredList.insert( oldPosition, assetsToRollback[ index ].toJSON() )
				), prevStateList ) )
			},
			failure: ( prevState ) => {
				const prevStateList = prevState.toList();
				const { assetIndexes: oldPositions } = action.meta;
				return orderedMapForPayload( oldPositions.reduce( ( restoredList, oldPosition, index ) => (
					restoredList.insert( oldPosition, action.meta.assets[ index ].toJSON() )
				), prevStateList ) )
			} } );
	case DELETE_ASSETS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.reduce(
				( result, deletedID ) => result.delete( deletedID ),
				prevState
			)
		);
	case CHANGE_ASSET_FILE_NAME:
		return handle( state, action, {
			start: ( prevState ) => {
				const { assetID, newFileName } = action.meta;
				const asset = prevState.get( assetID );

				return asset
					? prevState.set( assetID, { ...asset, name: newFileName } )
					: prevState;
			},
			failure: ( prevState ) => {
				const { assetID, originalFileName } = action.meta;
				if ( !originalFileName ) { return prevState; }

				const asset = prevState.get( assetID );

				return asset
					? prevState.set( assetID, { ...asset, name: originalFileName } )
					: prevState;
			},
			success: ( prevState ) => {
				const { assetID, newFileName } = action.meta;
				return prevState.get( assetID )
					? prevState.update( assetID, asset => ( { ...asset, name: newFileName } ) )
					: state;
			}
		} );
	case SET_ASSET_RUSH_PRIORITY:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.assetID, asset => ( { ...asset, rush_priority: true } )
			),
			failure: prevState => prevState.update(
				action.meta.assetID, asset => ( { ...asset, rush_priority: false } )
			),
			success: prevState => prevState.update(
				action.meta.assetID, asset => (
					{ ...asset, rush_priority: true, last_activity_at: new Date().toISOString() }
				)
			)
		} );
	case UNSET_ASSET_RUSH_PRIORITY:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.assetID, asset => ( { ...asset, rush_priority: false } )
			),
			failure: prevState => prevState.update(
				action.meta.assetID, asset => ( { ...asset, rush_priority: true } )
			),
			success: prevState => prevState.update(
				action.meta.assetID, asset => (
					{ ...asset, rush_priority: false, last_activity_at: new Date().toISOString() }
				)
			)
		} );
	case MARK_ASSETS_AS_APPROVED:
		return handleSuccess(
			state,
			action,
			prevState => changeAssetsMapApproval( prevState, action.payload, true )
		);
	case UNMARK_ASSETS_AS_APPROVED:
		return handleSuccess(
			state,
			action,
			prevState => changeAssetsMapApproval( prevState, action.payload, false )
		);
	case UPDATE_ASSET_LAST_SEEN:
		return handleSuccess(
			state,
			action,
			prevState => prevState.updateIfSet(
				action.meta.assetID,
				asset => ( {
					...asset,
					has_rounds_unseen_by_current_user: false,
					is_new_for_current_user: false,
					has_new_name_for_current_user: false,
					has_new_label_for_current_user: false,
					new_comments_count: 0,
					newly_approved_comments_count: 0
				} )
			)
		);
	case MARK_COMMENT_AS_COMPLETED:
		return handle( state, action, {
			start: prevState => prevState.update(
				action.meta.assetID,
				decreaseUnresolvedCommentsCount
			),
			failure: prevState => prevState.update(
				action.meta.assetID,
				increaseUnresolvedCommentsCount
			)
		} );
	case UNMARK_COMMENT_AS_COMPLETED:
		return handleSuccess(
			state,
			action,
			prevState => prevState.update(
				action.meta.assetID,
				decreaseUnresolvedCommentsCount
			)
		);
	case DELETE_COMMENT:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { assetID, parentID, wasMarkedAsCompleted } = action.meta;
				const isReply = !!parentID;

				return prevState.update(
					assetID, ( { unresolved_comments_count: unresolvedCommentsCount, ...asset } ) => ( {
						...asset,
						unresolved_comments_count: unresolvedCommentsCount - (
							isReply || wasMarkedAsCompleted
								? 0
								: 1
						)
					} )
				);
			}
		);
	case CREATE_COMMENT_FOR_ASSET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const comment = action.payload;
				const {
					asset_id: assetID
				} = comment;

				return prevState.update(
					assetID, ( { unresolved_comments_count: unresolvedCommentsCount, ...asset } ) => ( {
						...asset,
						has_comments: true,
						unresolved_comments_count: unresolvedCommentsCount + (
							isRootComment( comment )
								? 1
								: 0
						)
					} )
				);
			}
		);
	case DELETE_PROJECT_LABEL:
		return handleSuccess( state, action, ( prevState ) => {
			const { labelID } = action.meta;
			return prevState.map(
				asset => (
					asset.labels.some( aLabel => aLabel.id === labelID )
						? {
							...asset,
							labels: asset.labels.filter( aLabel => aLabel.id !== labelID )
						}
						: asset
				)
			);
		} );
	case EDIT_PROJECT_LABEL:
		return handleSuccess( state, action, ( prevState ) => {
			const { meta: { labelID }, payload: label } = action;
			return prevState.map(
				asset => (
					asset.labels.some( aLabel => aLabel.id === labelID )
						? {
							...asset,
							labels: asset.labels.map( aLabel => (
								aLabel.id === labelID
									? ( {
										...aLabel,
										name: label.name,
										base_color: label.base_color,
										hover_color: label.hover_color
									} ) : aLabel
							) )
						}
						: asset
				)
			);
		} );
	case SET_PROJECT_LABELS_ORDER:
		return handleSuccess( state, action,
			prevState => prevState.map( asset => (
				asset.projectID === action.meta.labellableID
					? ( {
						...asset,
						labels: updateLabelsWithOrder( {
							labels: asset.labels,
							orderedLabelIds: action.payload
						} )
					} ) : asset
			) ) );
	case UNFOLD_ITEMS:
		return handleSuccess( state, action, prevState => (
			prevState.map( asset => (
				action.payload.notDuplicatedAssetIDs.includes( asset.id )
					? { ...asset, folder_id: undefined }
					: asset
			) ) )
		);
	case CLEAR_ASSETS:
		return OrderedMap();
	case LOGOUT:
		return handleStart( state, action, () => OrderedMap() );
	case UPDATER_ACTION_TYPE.assets: {
		const { projectID, replaceAll } = action.meta;
		const parsedAssets = parseAssets(
			action.payload.map( asset => ( { ...asset } ) ),
			projectID
		);
		return (
			replaceAll ? state.filter( asset => asset.projectID !== projectID ) : state
		).merge( orderedMapForPayload( parsedAssets ) );
	}
	case UPDATER_ACTION_TYPE.asset: {
		return state.updateIfSet( action.meta.assetID, action.payload );
	}
	case UPDATER_ACTION_TYPE.createOrUpdateAsset: {
		const { projectID } = action.meta;
		return state.set( action.payload.id, parseAsset( { ...action.payload }, projectID ) );
	}
	case UPDATER_ACTION_TYPE.assetsWith: {
		return state.map( asset => (
			action.meta.assetIDs.includes( asset.id )
				? action.payload( asset )
				: asset
		) );
	}
	default:
		return state;
	}
};

export const projectsFetchingAssets = ( state = new Set(), action ) => {
	switch ( action.type ) {
	case FETCH_PROJECT_ASSETS:
		return handle( state, action, {
			start: prevState => (
				prevState.add( action.meta.projectID )
			),
			finish: prevState => (
				prevState.delete( action.meta.projectID )
			)
		} );
	default:
		return state;
	}
};

const updateTotalCount = ( { prevState, amount } ) => ( {
	...prevState,
	total_count: prevState.total_count + amount
} );

const subtractFromTotalCount = ( { prevState, subtrahend } ) => (
	updateTotalCount( { prevState, amount: -subtrahend } )
);

const addToTotalCount = ( { prevState, addend } ) => (
	updateTotalCount( { prevState, amount: addend } )
);

const addNotMovedAssetsToTotalCount = ( prevState, { payload } ) => {
	const notMovedAssetNames = payload?.meta?.duplicate_names || [];
	return addToTotalCount( { prevState, addend: notMovedAssetNames.length } );
};

export const assetsPaginationData = ( state = {}, action ) => {
	switch ( action.type ) {
	case SAVE_ASSETS_PAGINATION_DATA:
		return action.payload;
	case DELETE_ASSET:
		return handle(
			state,
			action,
			{
				start: prevState => subtractFromTotalCount( { prevState, subtrahend: 1 } ),
				failure: prevState => addToTotalCount( { prevState, addend: 1 } )
			}
		);
	case UNFOLD_ITEMS:
		return handleSuccess(
			state,
			action,
			prevState => subtractFromTotalCount( { prevState, subtrahend: action.meta.assetIDs.length } )
		);
	case MOVE_ITEMS_INTO_FOLDER:
		return handle(
			state,
			action,
			{
				start: prevState => subtractFromTotalCount( { prevState, subtrahend: action.meta.assets.length } ),
				success: prevState => addNotMovedAssetsToTotalCount( prevState, action ),
				failure: prevState => addToTotalCount( { prevState, addend: action.meta.assets.length } )
			}
		);
	case DELETE_ASSETS:
		return handleSuccess(
			state,
			action,
			prevState => subtractFromTotalCount( { prevState, subtrahend: action.payload.length } )
		);
	case CREATE_ASSET_FROM_DIRECT_UPLOAD: {
		const isANewAsset = successfullAction => (
			successfullAction.payload.asset.asset_versions_count === 1
		);
		return handleSuccess(
			state,
			action,
			prevState => (
				isANewAsset( action ) && action.payload.matchesFilter
					? addToTotalCount( { prevState, addend: 1 } )
					: prevState
			)
		);
	}
	default:
		return state;
	}
};
