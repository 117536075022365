import {
	naturalCompareAsc,
	compareAsc, compareDesc,
	compareEntitiesByTwoCriterias,
	naturalOptionalStringCompareAsc
} from '../lib/comparators';

class SortOption {
	constructor( id, name, comparator, description ) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.comparator = comparator;
		this.disabled = false;
	}

	get label() { return this.name; }

	get value() { return this.id; }

	get isDisabled() { return this.disabled; }

	get isCustomSort() {
		return this._isTheSameAs( SortOption.byUser );
	}

	disable() {
		this.disabled = true;
	}

	enable() {
		this.disabled = false;
	}

	_isTheSameAs( anotherSortOption ) {
		return this.id === anotherSortOption.id;
	}
}

SortOption.byName = new SortOption(
	'by_name',
	'Project Name',
	( p1, p2 ) => naturalCompareAsc( p1.name, p2.name )
);

SortOption.bySize = new SortOption(
	'by_size',
	'Size',
	( p1, p2 ) => compareAsc( p1.storageUsedInBytes, p2.storageUsedInBytes )
);

SortOption.byDateAdded = new SortOption(
	'by_date_added',
	'Date Created',
	( p1, p2 ) => compareAsc( p1.createdAt, p2.createdAt )
);

SortOption.byRushPriority = new SortOption(
	'rush_priority',
	'Priority',
	( p1, p2 ) => compareEntitiesByTwoCriterias(
		p1, p2,
		'hasRushPriority', 'lastActivityAt',
		compareAsc, compareDesc
	)
);

SortOption.byUnresolvedCommentsCount = new SortOption(
	'unresolved_comments_count',
	'Unresolved Comments',
	( p1, p2 ) => compareEntitiesByTwoCriterias(
		p1, p2,
		'unresolvedCommentsCount', 'name',
		compareAsc, compareAsc
	)
);

SortOption.byFileName = new SortOption(
	'filename',
	'Name',
	( a1, a2 ) => naturalCompareAsc( a1.name, a2.name )
);

SortOption.byApproved = new SortOption(
	'approved_at',
	'Approved',
	( a1, a2 ) => compareEntitiesByTwoCriterias(
		a1, a2,
		'isApproved', 'name',
		compareAsc, compareAsc
	)
);

SortOption.byDateUploaded = new SortOption(
	'created_at',
	'Date Uploaded',
	( a1, a2 ) => compareDesc( a1.createdAt, a2.createdAt )
);

SortOption.byUser = new SortOption(
	'custom',
	'Manual',
	() => false
);

SortOption.byLastActivity = new SortOption(
	'last_activity_at',
	'New Activity',
	( a1, a2 ) => compareAsc( a1.lastActivityAt, a2.lastActivityAt )
);

SortOption.byClientName = new SortOption(
	'by_client_name',
	'Client Name',
	( p1, p2 ) => compareEntitiesByTwoCriterias(
		p1, p2,
		'clientName', 'name',
		naturalOptionalStringCompareAsc, naturalCompareAsc
	)
);

SortOption.byRoundsCount = new SortOption(
	'asset_versions_count',
	'Round Number',
	( a1, a2 ) => compareAsc( a1.roundsCount, a2.roundsCount )
);

SortOption.byFileType = new SortOption(
	'extension',
	'File Type',
	( a1, a2 ) => compareEntitiesByTwoCriterias(
		a1, a2,
		'fileExtension', 'name',
		naturalOptionalStringCompareAsc, naturalCompareAsc
	)
);

SortOption.optionsByID = {
	[ SortOption.byName.id ]: SortOption.byName,
	[ SortOption.byFileName.id ]: SortOption.byFileName,
	[ SortOption.byDateUploaded.id ]: SortOption.byDateUploaded,
	[ SortOption.byDateAdded.id ]: SortOption.byDateAdded,
	[ SortOption.byApproved.id ]: SortOption.byApproved,
	[ SortOption.byRushPriority.id ]: SortOption.byRushPriority,
	[ SortOption.byUnresolvedCommentsCount.id ]: SortOption.byUnresolvedCommentsCount,
	[ SortOption.byUser.id ]: SortOption.byUser,
	[ SortOption.byLastActivity.id ]: SortOption.byLastActivity,
	[ SortOption.byClientName.id ]: SortOption.byClientName,
	[ SortOption.byRoundsCount.id ]: SortOption.byRoundsCount,
	[ SortOption.bySize.id ]: SortOption.bySize,
	[ SortOption.byFileType.id ]: SortOption.byFileType
};

SortOption.projectsOptions = [
	SortOption.byName,
	SortOption.byDateAdded,
	SortOption.byLastActivity,
	SortOption.byClientName,
	{
		label: 'Project State',
		options: [
			SortOption.byRushPriority,
			SortOption.byUnresolvedCommentsCount
		]
	},
	{
		label: 'Custom Order',
		options: [
			SortOption.byUser
		]
	}
];

SortOption.imagesOptions = [
	{
		label: 'Assets & Folders',
		options: [
			SortOption.byFileName,
			SortOption.byDateUploaded,
			SortOption.byLastActivity,
			SortOption.byUnresolvedCommentsCount
		]
	},
	{
		label: 'Assets',
		options: [
			SortOption.byRoundsCount,
			SortOption.byFileType,
			SortOption.byApproved,
			SortOption.byRushPriority,
			SortOption.byUser
		]
	}
];

SortOption.documentsOptions = [
	{
		label: 'Assets & Folders',
		options: [
			SortOption.byFileName,
			SortOption.byDateUploaded
		]
	},
	{
		label: 'Assets',
		options: [
			SortOption.byFileType,
			SortOption.byRushPriority
		]
	}
];

SortOption.projectsTabOptions = [
	SortOption.byName,
	SortOption.byDateAdded,
	SortOption.bySize
];

export default SortOption;
