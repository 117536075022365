import { createSelector } from 'reselect';
import NullLabel from '@labels/models/nullLabel';

import Folder, { OrganizerType } from '../entities/folder';
import { getLabels } from './labels';
import OrganizerOverview from '../entities/organizerOverview';
import { compareAsc } from '../lib/comparators';

const getRawFolders = state => state.folders;
const getRawFolderAncestors = state => state.folderAncestors;
const getRawFolderOverviews = state => state.folderOverviews;

export const getFolders = createSelector(
	[ getRawFolders, getRawFolderAncestors ],
	( foldersJSON, ancestors ) => foldersJSON?.map(
		folderJSON => Folder.fromJSON( {
			...folderJSON,
			ancestors: ancestors.get( folderJSON.id )
		} )
	)
);

export const getFolderID = ( _, props ) => props.folderID;

const noLabelID = 'no_label';

export const getFolderOverview = createSelector(
	[ getFolderID, getRawFolderOverviews, getLabels ],
	( folderID, rawFolderOverviews, labels ) => {
		const rawOverview = rawFolderOverviews.get( folderID );
		if ( !rawOverview ) return undefined;

		let folderOverview;
		folderOverview = Object.keys( rawOverview ).filter( labelID => labelID !== noLabelID )
			.map( ( labelID ) => {
				labelID = parseInt( labelID, 10 );
				const label = labels.get( labelID );
				const assetCount = rawOverview[ labelID ];

				return [ label, assetCount ]
			} )

		if ( rawOverview[ noLabelID ] ) {
			const noLabelOverview = [ new NullLabel(), rawOverview[ noLabelID ] ];
			folderOverview = [ ...folderOverview, noLabelOverview ];
		}

		folderOverview.sort( ( a, b ) => compareAsc( b[ 1 ], a[ 1 ] ) );

		return new OrganizerOverview( folderOverview );
	}
);

const getOrganizerType = ( _, props ) => props.organizerType;

const getOrganizerID = ( _, props ) => props.organizerID;

export const getFoldersForOrganizer = createSelector(
	[ getOrganizerID, getOrganizerType, getFolders ],
	( organizerID, organizerType, folders ) => folders
		?.filter( folder => folder.belongsToOrganizer( { organizerID, organizerType } ) )
		.toArray()
);

export const parseOrganizer = ( props ) => {
	const organizerID = props.folderID ?? props.projectID;
	const organizerType = props.folderID ? OrganizerType.folder : OrganizerType.project;
	return { ...props, organizerID, organizerType };
};

export const getDirectFoldersCountForOrganizer = createSelector(
	[ getFoldersForOrganizer ],
	folders => folders.length ?? 0
);

export const getFolder = createSelector(
	[ getFolders, getFolderID ],
	( folders, folderID ) => folders.get( folderID )
);

export const getFolderName = createSelector(
	[ getFolder ],
	folder => folder?.name
);

export const getAssetFolder = ( state, props ) => (
	getFolder( state, { folderID: props.asset.folderID } )
);

export const getFolderNamesForOrganizer = createSelector(
	[ getFoldersForOrganizer ],
	folders => folders.map( folder => folder.name )
);
