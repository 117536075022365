/* eslint-disable max-classes-per-file */
import { type QueryFilters as TanstackQueryFilters } from '@tanstack/react-query';
import { QueryType } from '../../../queries/types';

export class QueryKeys {
	static forAssetDetail( id: number ) {
		return [ 'assets', 'detail', id ];
	}

	static forAssetRounds( assetID: number ) {
		return [ 'asset', assetID, 'rounds' ];
	}
}

export class QueryFilters {
	static forAssetDetail(
		id?: number,
		{ queryType = 'all' }: { queryType?: QueryType } = {}
	): TanstackQueryFilters {
		return {
			type: queryType,
			predicate: ( { queryKey: key } ) => (
				key[ 0 ] === 'assets'
				&& key[ 1 ] === 'detail'
				&& ( !id || key[ 2 ] === id )
			)
		};
	}
}
